import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
import EmptyLayout from "@/views/Pages/EmptyLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Example pages
// import UserProfile from "@/views/Examples/UserProfile.vue";
import Integrations from "@/views/Examples/Integrations/Integrations.vue";
// Tag Management
// Item Management
import StepsList from "@/views/Examples/Events/StepsList.vue"
import ListItemPage from "@/views/Examples/ItemManagement/ListItemPage.vue";
import ApiTests from "@/views/Examples/ItemManagement/ApiTests.vue";
import SharedTests from "@/views/Examples/ItemManagement/SharedTests.vue";
import SauceLabsTests from "@/views/Examples/ItemManagement/SauceLabsTests.vue";
import AWSTests from "@/views/Examples/ItemManagement/AWSTests.vue";
import listPoject from "@/views/Examples/Events/listPoject.vue";
import sessionListEvents from "@/views/Examples/Events/sessionListEvents.vue";
import detailsSession from "@/views/Examples/Events/detailsSession.vue";
import apiCall from "@/views/Examples/Events/apiCall.vue";
import projectsuiteFile from "@/views/Examples/Events/fileSuiteTestProject.vue";
import detailsSessionSteps from '@/views/Examples/Events/detailsSessionSteps.vue'
import detailsMobileSession from "@/views/Examples/Events/detailsMobileSession.vue";
import ListProjectPage from "@/views/Examples/ItemManagement/ListProjectPage.vue";
import ViewItemPage from "@/views/Examples/ItemManagement/ViewItemPage.vue";
import ViewItemApiPage from "@/views/Examples/ItemManagement/ViewItemApiPage.vue";
import Shared from "@/views/Examples/ItemManagement/Shared.vue";
import generateTest from "@/views/Examples/ItemManagement/GenerateTest.vue";
import virtualizeApi from "@/views/Examples/Events/virtualizeApi.vue";
import chatWring from "@/views/Examples/ChatWring/chatWring.vue"
import triggers from "@/views/Examples/Integrations/Triggers.vue";
import sheduel from "@/views/Examples/Integrations/Sheduels.vue";
import clintegrations from "@/views/Examples/Integrations/ClIntegrations.vue";

// Suite Item Management
import SuiteOverview from "@/views/Examples/ItemManagement/SuitePages/SuiteOverview.vue";
import SuiteListItemPage from "@/views/Examples/ItemManagement/SuitePages/SuiteListItemPage.vue";
import SuiteViewItemPage from "@/views/Examples/ItemManagement/SuitePages/SuiteViewItemPage.vue";
import Subscription from "@/views/Subscription/Subscription.vue";

//Profile Page
import ProfilePage from "@/views/ProfilePage/ProfilePage.vue";

// Subscription page
// const Subscription = () => import("@/views/Subscription/Subscription.vue");

// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Buttons.vue");
const Cards = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");

// Forms pages
const FormElements = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps pages

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");

const EmailConfirmation = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/EmailConfirmation.vue");

const TnS = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Tns.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue");
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue");

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import accessLiveEevent from "@/middleware/accessLiveEevent";

// let componentsMenu = {
//   path: "/components",
//   component: DashboardLayout,
//   redirect: "/components/buttons",
//   name: "Components",
//   children: [
//     {
//       path: "buttons",
//       name: "Buttons",
//       component: Buttons,
//     },
//     {
//       path: "cards",
//       name: "Cards",
//       component: Cards,
//     },
//     {
//       path: "grid-system",
//       name: "Grid System",
//       component: GridSystem,
//     },
//     {
//       path: "notifications",
//       name: "Notifications",
//       component: Notifications,
//     },
//     {
//       path: "icons",
//       name: "Icons",
//       component: Icons,
//     },
//     {
//       path: "typography",
//       name: "Typography",
//       component: Typography,
//     },
//   ],
// };
let examplesMenu = {
  path: "/details",
  component: DashboardLayout,
  name: "Details",
  children: [
    {
      path: "integrations",
      name: "Integrations",
      components: { default: Integrations },
      meta: { middleware: auth },
    },
    {
      path: "triggers",
      name: "triggers",
      components: {default : triggers},
      meta: {middleware : auth},
    },
    {
      path: "sheduels",
      name: "sheduels",
      components : {default : sheduel},
      meta: {middleware : auth},
    },
    {
      path: "clintegr",
      name: "clintegr",
      components : {default : clintegrations},
      meta: {middleware : auth},
    },
    {
      path: "chatwring",
      name: "ChatWring",
      components: { default: chatWring },
      meta: { middleware: auth },
    },
    // {
    //   path: "test-explorer/list-tests",
    //   name: "List Items",
    //   components: { default: ListItemPage },
    //   meta: { middleware: auth },
    // },
    {
      path: "project-explorer/list-projects",
      name: "List Poject",
      components: { default: listPoject },
      meta: { middleware: auth, middleware: accessLiveEevent },
    },
    // {
    //   path: "project-explorer/virtualize-api-projects",
    //   name: "List Poject",
    //   components: { default: virtualizeApi },
    //   meta: { middleware: auth, middleware: accessLiveEevent },
    // },
    {
      path: "project-explorer/view-project/:id",
      name: "List Sessions",
      components: { default: sessionListEvents },
      meta: { middleware: auth , middleware: accessLiveEevent},

    },
    {
      path: "project-explorer/view-project/:id/:name/:testSuiteId/:session_id/:run_name/:start_time/:recording_duration",
      name: "Details Session",
      components: { default: detailsSession },
      meta: { middleware: auth , middleware: accessLiveEevent},
    },
    {
      path: "project-explorer/view-project/:projectId/:nameProject/:session_id/:start_time/:testSuiteId/:id/:recording_duration/:run_name/:page_name",
      name: "Steps List",
      components: { default: StepsList },
      meta: { middleware: auth, middleware: accessLiveEevent},
    },
    {
      path: "project-explorer/view-project/:id/:name/:session_id",
      name: "Api call Session",
      components: { default: apiCall },
      meta: { middleware: auth , middleware: accessLiveEevent},
    },
    {
      path: "project-explorer/view-project/:id/project-test-suite-files/:name/:testSuitRunId",
      name: "project test suite files",
      components: { default: projectsuiteFile },
      meta: { middleware: auth , middleware: accessLiveEevent},
    },
    {
      path: "project-explorer/view-project/:id/:session_id/:run_name",
      name: "Details Session Steps",
      components: { default: detailsSessionSteps },
      meta: { middleware: auth , middleware: accessLiveEevent},
    },
    {
      path: "project-explorer/view-project/:id/mobile/:session_id",
      name: "Details of mobile session",
      components: { default: detailsMobileSession },
      meta: { middleware: auth , middleware: accessLiveEevent},
    },
    {
      path: "test-explorer/list-projects",
      name: "List Projects",
      components: { default: ListProjectPage },
      meta: { middleware: auth },
    },

    {
      path: "test-explorer/view-test/:id",
      name: "View Item",
      components: { default: ViewItemPage },
      meta: { middleware: auth },
    },
    {
      path: "test-explorer/view-test-api/:id/:name",
      name: "View Item Api",
      components: { default: ViewItemApiPage },
      meta: { middleware: auth },
    },
    {
      path: "test-explorer/generate-test",
      name: "Generate Test",
      components: { default: generateTest },
      meta: { middleware: auth },
    },
    {
      path: "test-explorer/suite-overview/:id",
      name: "Suite Overview",
      components: { default: SuiteOverview },
      meta: { middleware: auth },
    },
    {
      path: "test-explorer/suite-list-tests/:id",
      name: "Suite List",
      components: { default: SuiteListItemPage },
      meta: { middleware: auth },
    },
    {
      path: "test-explorer/suite-view-tests/:id",
      name: "Suite View Item",
      components: { default: SuiteViewItemPage },
      meta: { middleware: auth },
    },
    {
      path: "subscription",
      name: "Subscription",
      components: { default: Subscription },
      meta: { middleware: auth },
    },
  ],
};
// let formsMenu = {
//   path: "/forms",
//   component: DashboardLayout,
//   redirect: "/forms/elements",
//   name: "Forms",
//   children: [
//     {
//       path: "elements",
//       name: "Form elements",
//       component: FormElements,
//     },
//     {
//       path: "components",
//       name: "Form components",
//       component: FormComponents,
//     },
//     {
//       path: "validation",
//       name: "Form validation",
//       component: FormValidation,
//     },
//   ],
// };
// let tablesMenu = {
//   path: "/tables",
//   component: DashboardLayout,
//   redirect: "/table/regular",
//   name: "Tables menu",
//   children: [
//     {
//       path: "regular",
//       name: "Tables",
//       component: RegularTables,
//     },
//     {
//       path: "sortable",
//       name: "Sortable",
//       component: SortableTables,
//     },
//     {
//       path: "paginated",
//       name: "Paginated Tables",
//       component: PaginatedTables,
//     },
//   ],
// };
let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};
const notFoundPages = {
  path: "/*",
  component: NotFound,
  name: NotFound,
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    }
  ],
};

const customPages = {
  path: "/",
  component: EmptyLayout,
  name: "Empty pages",
  children: [
    {
      path: "/shared/:id",
      name: "Shared",
      component: Shared,
      meta: { middleware: guest },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "Dashboard layout",
    children: [
      {
        path: "/details/list-tests",
        name: "List Items",
        component: ListItemPage,
        meta: { middleware: auth },
      },
      {
        path: "/details/api-tests",
        name: "Api Tests",
        component: ApiTests,
        meta: { middleware: auth },
      },
      {
        path: "/details/shared-tests",
        name: "Shared Tests",
        component: SharedTests,
        meta: { middleware: auth },
      },
      {
        path: "/details/sauce-tests",
        name: "Sauce Labs Tests",
        component: SauceLabsTests,
        meta: { middleware: auth },
      },
      {
        path: "/details/aws-tests",
        name: "AWS Tests",
        component: AWSTests,
        meta: { middleware: auth },
      },

      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { middleware: auth },
      },

      {
        path: "charts",
        name: "Charts",
        component: Charts,
      },
    ],
  },
  {
    path: "/verify",
    redirect: (to) => {
      return { path: "/dashboard", query: null };
    },
  },
  examplesMenu,
  authPages,
  customPages,
  notFoundPages,
];

export default routes;
