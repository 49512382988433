<template>
  <div class="enhancer">
    <div class="mid--page pb-4 no-border adpt-mid--page enhancer-content">
      <div class="mp--l">
        <div class="btn-group align-items-center">
          <el-button
            @click="showEnhancer('setup')"
            class="tab--btn secondary adpt--btn"
            id="btnShowSetup"
            :class="{ active: showSetup }"
          >
            {{ $t("test_enhancer.setup") }}
          </el-button>
          <el-button
            @click="showEnhancer('runs')"
            class="tab--btn secondary adpt--btn"
            id="btnShowRuns"
            :class="{ active: showRuns }"
          >
            {{ $t("test_enhancer.runs") }}
          </el-button>
        </div>
        <!-- Current page switcher -->

        <div v-if="showRuns" class="breadcrumb--run ml-2">
          <span @click="goBackRunList">
            <i class="el-icon-d-arrow-right mx-1 cb--divider"></i>
            <p>{{ $t("test_enhancer.runs_list") }}</p>
          </span>
          <span
            v-if="showEpisodeRun || showStepsEpisode"
            @click="goBackEpisodeRun"
          >
            <i class="el-icon-d-arrow-right mx-1 cb--divider"></i>
            <p>Episode</p>
          </span>
          <span v-if="showStepsEpisode">
            <i class="el-icon-d-arrow-right mx-1 cb--divider"></i>
            <p>Steps</p>
          </span>
        </div>
      </div>
      <div v-if="showSetup" class="mp--r adpt--settings">
        <span style="margin: 0 10px 0 10px">Steps : {{ total }}</span>
        <span>Show</span>
        <el-select
          v-model="pagination.perPage"
          placeholder="Per page"
          class="small--select"
          @input="savePagination"
        >
          <el-option
            v-for="(item, id) in pagination.perPageOptions"
            :key="id"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div v-else-if="showStepsEpisode" class="mp--r adpt--page-details">
        <span class="pag--details mr-3"
          >{{ totalRunSteps ? from + 1 : 1 }} {{ $t("test_enhancer.to") }}
          {{ to > totalRunSteps ? totalRunSteps : to }}
          {{ $t("test_enhancer.of") }} {{ totalRunSteps }}
          {{ $t("test_enhancer.entries") }}</span
        >
        <el-select
          v-model="runStepsPagination.perPage"
          placeholder="Per page"
          class="small--select"
          @change="saveRunStepsPagination"
        >
          <el-option
            v-for="(item, id) in runStepsPagination.perPageOptions"
            :key="id"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div v-else class="mp--r">
        <span class="pag--details mr-3"
          >{{ totalRun ? runFrom + 1 : 1 }} {{ $t("test_enhancer.to") }}
          {{ runTo > totalRun ? totalRun : runTo }}
          {{ $t("test_enhancer.of") }} {{ totalRun }}
          {{ $t("test_enhancer.entries") }}</span
        >
        <el-select
          v-model="runPagination.perPage"
          placeholder="Per page"
          class="small--select"
          @change="saveRunPagination"
        >
          <el-option
            v-for="(item, id) in runPagination.perPageOptions"
            :key="id"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- top page -->

    <template>
      <template v-if="showSetup">
        <data-list
          :items="toShow"
          :loading="toShowLoading"
          :customSizes="true"
          :emptyText="$t('test_enhancer.preparing_test_enhancer')"
        >
          <template #content>
            <template>
              <div
                class="tblb--row mb-2"
                v-if="useTgAnalyze && isEmptyObject(RLJson)"
              >
                <div class="tblb--inner px-3 py-3">
                  {{ $t("test_enhancer.preparing_test_enhancer") }}
                </div>
              </div>
              <template v-else>
                <div v-if="toShow.length > 0" v-loading="loading">
                  <div
                    class="tblb--row mb-2 custom-header"
                    style="
                      display: grid;
                      grid-template-columns: 150px 1fr 1fr 1fr 140px;
                      width: 100%;
                      background: #1f213e;
                      color: white;
                      text-align: center !important;
                    "
                  >
                    <div class="custom-header-names">
                      <span>Step</span>
                    </div>
                    <div
                      style="text-align: left; padding-left: 27%"
                      class="custom-header-names"
                    >
                      <span>Screenshot</span>
                    </div>
                    <div class="custom-header-names">
                      <span>Description</span>
                    </div>
                    <div class="custom-header-names">
                      <span>Action</span>
                    </div>
                    <div class="custom-header-names">
                      <span></span>
                    </div>
                  </div>
                  <div class="table-body-scroll">
                    <div
                    v-for="(elt, id) in toShow"
                    :key="elt.id"
                    class="tblb--row mb-2"
                  >
                    <div
                      class="tblb--inner"
                      style="
                        display: grid;
                        grid-template-columns: 150px 1fr 1fr 1fr 140px;
                        text-align: center !important;
                      "
                    >
                      <div style="max-width: 100%" class="elt-row">
                        <span class="test-run-name">{{
                          forStepNumber(id)
                        }}</span>
                      </div>
                      <div
                        style="
                          max-width: 250px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        class="elt-row"
                      >
                        <div class="trsl--img">
                          <img
                            v-if="elt.page_screenshot"
                            @click="screenshotViewModal(elt, id)"
                            :src="`data:image/png;base64,${elt.page_screenshot}`"
                          />
                        </div>
                      </div>

                      <div style="max-width: 100%" class="elt-row">
                        <span>{{ elt.description }}</span>
                      </div>
                      <div style="max-width: 100%" class="elt-row">
                        <span class="test-time-cont" style="max-width: 100%">
                          {{ elt.action }}
                        </span>
                      </div>
                      <div
                        style="overflow: visible"
                        class="elt-row flex--elt justify-center"
                        v-if="
                          (isSharing === 'true' &&
                            sharedJSON.scopes.includes('shared-edit')) ||
                          isSharing === null
                        "
                      >
                        <el-tooltip content="Edit" placement="top">
                          <el-button
                            type="primary"
                            icon="el-icon-edit"
                            @click.stop="forStepsModal(elt, id, 'edit')"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>

                        <el-tooltip content="Add" placement="top">
                          <el-button
                            type="success"
                            icon="el-icon-plus"
                            @click.stop="forStepsModal(elt, id, 'add')"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>

                        <el-tooltip content="Delete" placement="top">
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            @click.stop="deleteEnhancerRow(elt, id)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                      </div>
                      <div
                        class="elt-row flex--elt"
                        style="width: 20%"
                        v-else
                      ></div>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="tblb--row mb-2" v-else>
                  <div class="tblb--inner px-3 py-3">No Data ...</div>
                </div>
              </template>
            </template>
          </template>
        </data-list>

        <el-pagination
          background
          layout="prev, pager, next"
          class="pagination-no-border mt-4"
          :total="total"
          :page-size="pagination.perPage"
          :page-sizes="pagination.perPageOptions"
          :current-page.sync="pagination.currentPage"
          @current-change="handleCurrentChangeNextPage"
          @size-change="handleSizeChange"
        />
      </template>
      <template v-if="showRuns">
        <template v-if="showRunsList">
          <data-list
            :loading="loadingAll"
            :items="runToShow"
            emptyText="No runs yet, click on the top right play button to start a run"
          >
            <template #content>
              <div style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;text-align: center !important;" class="custom-header-rows">
              <div class="elt-row max-width">
                <h3>DATE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>FAILURES</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>EPISODES</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>Exploration</h3>
              </div>
              <div class="elt-row max-width" style="white-space: nowrap;padding-left: 0 !important;">
                <h3>STATUS</h3>
              </div>
              <div class="elt-row max-width" >
              </div>
              </div>
              <div class="tblb--row mb-2" v-if="loadingAll">
                <div class="tblb--inner" style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;text-align: center !important;">
                  <img
                    src="/img/loading.gif"
                    alt="loading..."
                    style="height: 64px; width: 64px"
                  />
                </div>
              </div>
              <template v-else>
                <template v-if="runToShow.length > 0">
                  <div
                    v-for="elt in runToShow"
                    :key="elt.id"
                    class="tblb--row mb-2"
                  >
                    <div v-if="elt.status != 'IN PROGRESS'" class="tblb--inner" style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;text-align: center !important;">
                      <div class="elt-row max-width">
                        <span
                          class="test-time-cont"
                          style="
                            max-width: 100%;
                            font-size: 0.8rem;
                            padding: 0.4rem;
                            font-weight: 700;
                          "
                        >
                          <i class="far fa-clock mr-1"></i>
                          {{ formatDate(elt) }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.total_bugs }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.current_episodes ? elt.current_episodes : 0 }}
                          /{{ elt.max_episodes }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.agent_type }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          <span
                            class="test-time-cont"
                            style="max-width: 100%"
                            :class="
                              elt.status == 'FINISHED'
                                ? ' test-time-v-color'
                                : 'test-time-v-color-red'
                            "
                          >
                            {{ elt.status }}
                          </span>
                        </span>
                      </div>
                      <div class="elt-row max-width" style="display:flex;gap:5px;justify-content: center;">
                        <el-tooltip content="Detail run" placement="top">
                          <el-button
                            type="primary"
                            icon="el-icon-document"
                            @click.stop="openDetailRun(elt.id)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="Variables" placement="top">
                          <el-button
                            type="success"
                            icon="el-icon-magic-stick"
                            @click.stop="openVariable(elt)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="Delete" placement="top">
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            @click.stop="deleteEnhancerRun(elt)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip
                          content="Log iterations"
                          placement="top"
                          v-if="
                            elt.total_failures > 0 ||
                            elt.status === 'INTERRUPTED'
                          "
                        >
                          <el-button
                            type="info"
                            icon="el-icon-warning-outline"
                            @click.stop="showIterations(elt)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                      </div>
                    </div>
                    <div
                      v-else
                      style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 1fr 1fr;text-align: center !important;"
                      class="tblb--inner"
                      element-loading-spinner="el-icon-loading"
                    >
                      <div class="elt-row max-width">
                        <span
                          class="test-time-cont"
                          style="max-width: 100%; font-size: .8rem; padding: .4rem, font-weight: 700"
                        >
                          <i class="far fa-clock mr-1"></i>
                          {{ formatDate(elt) }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.total_bugs }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.current_episodes ? elt.current_episodes : 0 }}
                          /{{ elt.max_episodes }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          {{ elt.agent_type }}
                        </span>
                      </div>
                      <div class="elt-row max-width">
                        <span class="elt--value">
                          <span class="test-time-cont" style="max-width: 100%">
                            {{ elt.status }} </span
                          ><br />
                          <el-button
                            type="primary"
                            loading
                            class="test-time-cont"
                            style="max-width: 100%; font-size: .8rem; padding: .4rem, font-weight: 700"
                          >
                            {{
                              Math.round(
                                !elt.current_episodes
                                  ? 0
                                  : ((elt.current_episodes - 1) * 100) /
                                      elt.max_episodes
                              )
                            }}%
                          </el-button>
                        </span>
                      </div>
                      <div class="elt-row max-width" style="display:flex;gap:5px;align-items:center;">
                        <el-tooltip content="Detail run" placement="top">
                          <el-button
                            type="primary"
                            icon="el-icon-document"
                            @click.stop="openDetailRun(elt.id)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="Variables" placement="top">
                          <el-button
                            type="success"
                            icon="el-icon-magic-stick"
                            @click.stop="openVariable(elt)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="stop run" placement="top">
                          <el-button
                            type="danger"
                            icon="el-icon-warning-outline"
                            @click.stop="endTestEnhancerRun(elt)"
                            circle
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="tblb--row mb-2" v-else>
                  <div class="tblb--inner px-3 py-3">No Data ...</div>
                </div>
              </template>
            </template>
          </data-list>

          <el-pagination
            background
            layout="prev, pager, next"
            class="pagination-no-border mt-4"
            :total="totalRun"
            :page-size="runPagination.perPage"
            :page-sizes="runPagination.perPageOptions"
            :current-page.sync="runPagination.currentPage"
            @current-change="handleRunCurrentChange"
            @size-change="handleRunSizeChange"
          />
        </template>
        <template v-if="showEpisodeRun">
          <data-list
            :loading="loading3"
            :items="detailRuns"
            :customSizes="true"
            emptyText="No data"
          >
            <template #content>
              <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 200px;text-align: center;" class="custom-header-rows">
              <div class="elt-row max-width">
                <h3>EPISODE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>DURATION</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>FAILED STEPS</h3>
              </div>
              <div class="elt-row max-width" >
              </div>
              </div>
              <template>
                <div class="tblb--row mb-2" v-if="loading">
                  <div class="tblb--inner">
                    <img
                      src="/img/loading.gif"
                      alt="loading..."
                      style="height: 64px; width: 64px"
                    />
                  </div>
                </div>
                <template v-else>
                  <template v-if="detailRuns && detailRuns.length > 0">
                    <div
                      v-for="(elt, id) in detailRuns"
                      :key="id"
                      class="tblb--row mb-2"
                    >
                      <div class="tblb--inner" style="display: grid;grid-template-columns: 1fr 1fr 1fr 200px;text-align: center;">
                        <div class="elt-row max-width">
                          <!-- <el-button style="margin-right: 10px" :type="elt.total_bugs === 0 ? 'success' : 'danger'" :icon="elt.total_bugs === 0
                            ? 'el-icon-check'
                            : 'el-icon-close'
                            " circle plain size="small"></el-button> -->
                          EpisodeNum {{ elt.episode_number + 1 }}
                        </div>
                        <div class="elt-row max-width">
                          {{ secondsToRealTime(elt) }}
                        </div>
                        <div class="elt-row max-width">
                          {{ elt.total_bugs }}
                        </div>
                        <div
                          class="elt-row max-width"
                          style="
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                          "
                        >
                          <el-tooltip content="Steps detail" placement="top">
                            <el-button
                              style="max-width: 45px"
                              type="primary"
                              @click.stop="vueSteps(elt)"
                              circle
                              plain
                              size="small"
                            ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="file-search-02">
<path id="Icon" d="M11.4 21.6003H5.39999C4.0745 21.6003 2.99999 20.5258 3 19.2003L3.00009 4.80038C3.0001 3.4749 4.07462 2.40039 5.40009 2.40039H16.2004C17.5258 2.40039 18.6004 3.47491 18.6004 4.80039V9.60039M19.8 19.8004L21 21.0004M7.20037 7.20039H14.4004M7.20037 10.8004H14.4004M7.20037 14.4004H10.8004M20.4 17.4004C20.4 19.0572 19.0569 20.4004 17.4 20.4004C15.7431 20.4004 14.4 19.0572 14.4 17.4004C14.4 15.7435 15.7431 14.4004 17.4 14.4004C19.0569 14.4004 20.4 15.7435 20.4 17.4004Z" stroke="#1F213E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg></el-button>
                          </el-tooltip>
                          <el-tooltip content="Episode logs" placement="top">
                            <el-button
                              style="max-width: 45px"
                              type="warning"
                              @click.stop="handleShowEpisodeLog(elt)"
                              circle
                              plain
                              size="small"
                            ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 12L12 16.5M12 8.66455V8.625M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" stroke="#1F213E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="tblb--row mb-2" v-else>
                    <div class="tblb--inner px-3 py-3">No Data ...</div>
                  </div>
                </template>
              </template>
            </template>
          </data-list>
        </template>
        <template v-if="showStepsEpisode">
          <div style="background-color: #e6e6e6" class="step-header">
            <h5 style="color: #1f213e" class="modal-title">
              EpisodeNum {{ defaultSteps }}
            </h5>
          </div>
          <div class="bpsd_ctb">
            <div class="ctb--body mb-3" v-if="loadingSteps">
              <div class="ctbb--row mb-2">
                <img
                  src="/img/loading.gif"
                  alt="loading..."
                  style="height: 64px; width: 64px"
                />
              </div>
            </div>
            <div class="ctb--body mb-3" v-else>
              <template v-if="runEpisodeToShow.length > 0">
                <div
                  v-for="elt in runEpisodeToShow"
                  :key="elt.id"
                  class="ctbb--row mb-2"
                >
                  <div class="ctbb--inner">
                    <div
                      class="elt--banner"
                      v-if="elt.page_screenshotb64"
                      v-on:mouseover="overScreenshot(elt.page_screenshotb64)"
                      v-on:mouseleave="leaveScreenshot()"
                    >
                      <img
                        :src="`data:image/png;base64,${elt.page_screenshotb64}`"
                        alt="screenshot of current step"
                        class="elt--banner_img"
                      />
                      <div class="elt--banner_overlay"></div>
                      <el-alert
                        class="no--close elt--banner_overlay_status"
                        show-icon
                        :title="elt.found_bug === 0 ? 'passed' : 'failed'"
                        :type="
                          elt.found_bug === 0
                            ? 'success'
                            : elt.status != 0
                            ? 'error'
                            : 'danger'
                        "
                      ></el-alert>
                    </div>
                    <div class="elt--banner" v-else>
                      <img
                        src="/img/loading.gif"
                        alt="loading..."
                        style="height: 100px; width: 100px"
                        v-if="elt.status !== 'processing'"
                      />
                      <div class="elt--banner_overlay"></div>
                      <el-alert
                        class="no--close elt--banner_overlay_status"
                        show-icon
                        :title="elt.found_bug === 0 ? 'passed' : 'failed'"
                        :type="
                          elt.found_bug === 0
                            ? 'success'
                            : elt.status != 0
                            ? 'error'
                            : 'danger'
                        "
                      ></el-alert>
                    </div>
                    <div class="elt--content--step">
                      <el-tag type="info" class="step--number">{{
                        elt.step_number + 1
                      }}</el-tag>
                      <span class="test-run-name">
                        {{
                          elt.action +
                          (elt.extra_info["hypothesis"] ? " (HYPOTHESIS)" : "")
                        }}
                      </span>
                    </div>
                    <div class="elt--content">
                      <span class="test-desc my-1">
                        {{ elt.description }}
                      </span>

                      <span class="run-tags-cont">
                        <span
                          style="
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          class="run-tags"
                        >
                          {{ elt.url }}
                        </span>
                      </span>
                      <div>
                        <span class="elt--value">
                          {{ elt.found_bug }}
                          <em>
                            <small>
                              <u> Total Bug </u>
                            </small>
                          </em>
                        </span>
                        <span
                          class="test-time-cont"
                          style="
                            max-width: 100%;
                            font-size: 0.7rem;
                            padding: 0.2rem;
                            font-weight: 700;
                            float: right;
                          "
                          v-if="elt.found_bug > 0"
                          @click="showIterations(elt)"
                        >
                          View log
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="tblb--row mb-2" v-else>
                <div class="tblb--inner px-3 py-3">No Data ...</div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              class="pagination-no-border mt-4"
              :total="totalRunSteps"
              :page-size="runStepsPagination.perPage"
              :page-sizes="runStepsPagination.perPageOptions"
              :current-page.sync="runStepsPagination.currentPage"
              @current-change="handleRunStepsCurrentChange"
              @size-change="handleRunStepsSizeChange"
            />
          </div>
          <div class="over--screenshot" v-show="showOverScreenshot">
            <div>
              <img
                :src="`data:image/png;base64,${activeScreenshot}`"
                alt="screenshot of current step"
                class="elt--banner_img"
              />
            </div>
          </div>
        </template>
      </template> </template
    ><!-- page content -->

    <template>
      <div class="test-step-modal">
        <modal :show.sync="showTestStepModal" @close="closeTestStepModal">
          <template slot="header">
            <h5 class="modal-title">Test Step</h5>
          </template>
          <div  class="mod--content">
            <el-form class="small-spaces">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="URL" required>
                    <el-input
                      class="big-input"
                      v-model="editedTestStep.current_url"
                      placeholder="Enter variable URL"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Element Path" required style="margin-bottom: 0;"></el-form-item>
                  <!-- <div
                    style="height: 100%; border: 0"
                    class="ultra--ipt--xpath mt--3"
                    @click="focusElementPath('add')" @mouseleave="focusElementPath()"
                  > -->
                  <!-- <div class="ultra--ipt_content--xphat pl-2" ref="data-rendered-xpath"></div> -->
                    <!-- <el-select
                      ref="data-rendered-xpath"
                      class="select-danger custom-placeholder"
                      placeholder="Actions"
                      @change="handleUltraIptXpath"
                      v-model="ultraIptXpath"
                    >
                      <el-option
                        v-for="elt in listElPath"
                        :key="elt.id"
                        :value="elt"
                      >
                      </el-option>
                    </el-select> -->
                    <!-- <div class="ultra--ipt_content--xphat pl-2" ref="data-rendered-xpath"></div> -->

                     <div @click="focusElementPath('add')" @mouseleave="focusElementPath()" class="ultra--ipt--content" style="padding:0;">
                    <el-tooltip :content="ultraIptXpathSet" placement="top">
                        <div class="ultra--ipt_content--xphat pl-2" ref="data-rendered-xpath" style="display:none"></div>
                      </el-tooltip>
                      <input class="xPath-input"  id="input_xpath_ultra" type="text" placeholder="" @input="handleUltraIptXpath"
                        @change="handleUltraIptXpath" @keyup.backspace="handleDeleteLastDataValueXpath"
                        v-model="ultraIptXpath" list="list-ElPath" /> 
                     </div> 
                    <datalist id="list-ElPath">
                      <option v-for="elt in listElPath" :key="elt.id" :value="elt" />
                    </datalist> 
                     <!-- <div class="btn--xptah" style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 50px;
                        max-height: 70px;
                      ">
                      <el-tooltip content="Clear value" placement="top">
                        <el-button @click="handleClearDataValue('path')" icon="el-icon-brush" class="ic--btn mr-2 py-2">
                        </el-button>
                      </el-tooltip>
                    </div> -->
                  <!-- </div> -->
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Action">
                    <el-select
                      class="select-danger"
                      placeholder="Set the action"
                      v-model="editedTestStep.action"
                      @change="remoteMethod"
                    >
                      <el-option
                        v-for="(item, id) in stepActionOptions"
                        class="select-danger"
                        :value="item.value"
                        :label="item.label"
                        :key="id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Selector Method" required>
                    <el-select
                      class="select-primary"
                      placeholder="Set the Selector Method"
                      v-model="editedTestStep.element.selector_method"
                    >
                      <el-option
                        v-for="(item, id) in stepMethodOptions"
                        class="select-primary"
                        :value="item.value"
                        :label="item.label"
                        :key="id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Explore">
                    <el-select
                      class="select-primary"
                      v-model="editedTestStep.required"
                    >
                      <el-option
                        v-for="(item, id) in stepMutateOptions"
                        class="select-primary"
                        :value="item.value"
                        :label="item.label"
                        :key="id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="Data"></el-form-item>
              <div class="ultra--ipt mt--3" style="gap: 5px">
                <div
                  class="ultra--ipt_content pl-2"
                  ref="data-rendered"
                  style="display: none"
                ></div>
                <input
                  style="max-height: 90px"
                  type="text"
                  class="ultra--ipt_ipt px-2"
                  @input="handleUltraIpt"
                  @change="handleUltraIpt"
                  @keyup.backspace="handleDeleteLastDataValue"
                  v-model="ultraIpt"
                  list="all-variables"
                />
                <datalist id="all-variables">
                  <option
                    v-for="elt in qlyVariables"
                    :key="elt.name"
                    :value="elt.name"
                    :label="
                      elt.data && elt.data.trim().length !== 0
                        ? elt.data
                        : elt.init_value
                    "
                  />
                </datalist>
                <!-- <el-tooltip content="Remove last element" placement="top">
                  <el-button
                    @click="handleDeleteLastDataValue()"
                    :disabled="ultraIpt.length > 0"
                    icon="el-icon-back"
                    class="ic--btn mr-2 py-2"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip content="Clear value" placement="top">
                  <el-button
                    @click="handleClearDataValue()"
                    icon="el-icon-brush"
                    class="ic--btn mr-2 py-2"
                  >
                  </el-button>
                </el-tooltip> -->
              </div>
              <div class="edit-accordion-btns mt-4 flex--box">
                <div>
                  <button
                    class="cancel_button"
                    @click="showTestStepModal = false"
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    style="margin-bottom: 0"
                    v-if="editedIndex > -1"
                    class="add_button"
                    :disabled="!canAddEnhancer"
                    @click="modifyEnhancerRow('edit')"
                  >
                    Save
                  </button>
                  <button
                    v-else
                    style="margin-bottom: 0"
                    class="add_button"
                    :disabled="!canAddEnhancer"
                    @click="modifyEnhancerRow('add')"
                  >
                    Save
                  </button>
                </div>
              </div>
            </el-form>
          </div>
        </modal>
      </div>
      <!-- Add/Edit Test Steps -->
      <div class="testEnhancer-run-modal-container-variable">
        <modal
        id="var-modal"
        :class="qlyVariables.length > 0 ? '' : 'variable-modal'"
          :show.sync="showVariablesModal"
          v-loading="loadingVariableList"
        >
          <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("test_enhancer.Setup_dynamic_variables") }}
            </h5>
          </template>
          <div class="tblb--row mb-2" v-if="loadingVariable">
            <div class="tblb--inner">
              <img
                src="/img/loading.gif"
                alt="loading..."
                style="height: 64px; width: 64px"
              />
            </div>
          </div>
          <div v-else>
            <data-list
              :items="qlyVariables"
              :customSizes="true"
              :loading="loadingVariable"
              :emptyText="$t('test_enhancer.no_variable_data')"
              v-if="qlyVariables.length > 0"
            >
              <template #content>
                <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;text-align: center;" class="custom-header-rows">
              <div class="elt-row max-width">
                <h3>VARIABLE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>TYPE</h3>
              </div>
              <!-- <div class="elt-row max-width" >
                <h3>DATA</h3>
              </div> -->
              <div class="elt-row max-width" >
                <h3>SOURCE</h3>
              </div>
              <div class="elt-row max-width" style="white-space: nowrap;padding-left: 0 !important;">
                <h3>ACTIVE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>ACTIONS</h3>
              </div>
              
              </div>
                <div class="var-table-modal">
                  <div
                  v-for="(elt, index) in qlyVariables"
                  :key="index"
                  class="tblb--row mb-2"
                >
                  <div class="tblb--inner" style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;text-align: center;">
                    <div class="elt-row max-width">
                      <span class="test-run-name">{{ elt.name }}</span>
                    </div>
                    <div class="elt-row max-width">
                      <span class="test-time-cont">{{ elt.type }}</span>
                    </div>
                    <!-- <div class="elt-row max-width">
                      <el-tooltip content="shows all data" placement="top">
                        <span
                          :id="`var_tag_data_dynamic_${index}`"
                          class="var--tag"
                          style="max-width: 100%; clear: both"
                          @click="varTagchek(`var_tag_data_dynamic_${index}`)"
                        >
                          {{ elt.init_value }}
                        </span>
                      </el-tooltip>
                    </div> -->
                    <div class="elt-row max-width">
                      <el-row v-if="elt.source === 'datafile'">
                        <el-tag key="datafile" size="small"> datafile </el-tag>
                      </el-row>
                      <el-row v-else-if="elt.source === 'salesforce'">
                        <el-tag key="salesforce" type="warning" size="small">
                          salesforce
                        </el-tag>
                      </el-row>
                      <el-row v-else>
                        <el-tag key="testcase" class="custom-alert-tag-syn" size="small">
                          synthetic
                        </el-tag>
                      </el-row>
                    </div>
                    <div class="elt-row max-width">
                      <el-row v-if="elt.status === 'active'">
                        <el-tag key="testcase" type="success" size="small">
                          Active
                        </el-tag>
                      </el-row>
                      <el-row v-else>
                        <el-tag key="salesforce" type="danger" size="small">
                          Inactive
                        </el-tag>
                      </el-row>
                    </div>
                    <div
                      class="elt-row max-width"
                      style="display: flex;gap: 5px"
                    >
                      <el-tooltip content="Edit" placement="top">
                        <el-button
                          style="margin-left: 10px"
                          type="primary"
                          icon="el-icon-edit"
                          @click.stop="handleEditVariable(elt)"
                          circle
                          plain
                          size="small"
                          :disabled="elt.source !== 'synthetic'"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="Change Source" placement="top">
                        <el-button
                          type="success"
                          icon="el-icon-sort"
                          @click.stop="changeSourceVariable(elt)"
                          circle
                          plain
                          size="small"
                          :disabled="
                            elt.source == 'datafile'
                              ? false
                              : inDatafile(elt.name)
                          "
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="Delete" placement="top">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          @click.stop="handleDeleteVariable(elt)"
                          circle
                          plain
                          size="small"
                          :disabled="elt.source !== 'synthetic'"
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                </div>
              </template>
            </data-list>
            <div class="no-items-container"><h3>No Variables</h3></div>
          </div>
          <template slot="footer">
            <button class="add_button" @click="addVariableModal">
              Add Variable
            </button>
          </template>
        </modal>
      </div>
      <!-- [OK] show user variables -->
      <div class="testEnhancer-run-modal-container">
        <modal class="small-modal" :show.sync="showRunStepsModal">
          <template slot="header">
            <h5>{{ $t("test_enhancer.Run_the_simulator") }}</h5>
          </template>
          <div class="mp--l">
            <div style="gap:0" class="btn-group align-items-center custom-run-ex-buttons">
              <el-button
                @click="showRunSetting('Recommended')"
                class="tab--btn secondary left-btn"
                :class="{ active: RunStepRecommended }"
              >
                Simple
              </el-button>
              <el-button
                @click="showRunSetting('Advanced')"
                class="tab--btn secondary right-btn"
                :class="{ active: RunStepAdvanced }"
              >
                Advanced
              </el-button>
            </div>
          </div>
          <div class="testEnhancer-inputs testEnhancer-run-modal">
            <h3 v-if="RunStepAdvanced === true" class="text-algorithm">Algorithm</h3>
            <el-radio-group v-model="stepsAlgorithmValue" v-if="RunStepAdvanced === true" class="container-advance-expander">
              <el-radio
                :span="12"
                v-for="option in stepRunAlgorithm"
                :key="option.label"
                :label="option.value"
                
              >
                <h4>{{ option.label }}</h4>
                <p>{{ option.description }}</p>
              </el-radio>
            </el-radio-group>
            <div class=" mt-4" v-if="RunStepRecommended === true">
              <p>
                The Expander run will explore your test and will find new paths,
                auto-verifies and uncovered elements.
              </p>
              <p>
                The more the episodes, the higher the probability of catching a
                bug.
              </p>
            </div>
            <label class="model-label" for="run_name"> Execution Cloud </label>
            <div style="flex-direction: row" class="is-flex">
              <el-radio
                style="min-width: 150px"
                v-model="runServer"
                class="mr-2"
                label="wringr"
                border
                >Wringr</el-radio
              >
              <el-tooltip
                :disabled="sourceLabsIsIntegrate"
                :content="`${
                  sourceLabsIsIntegrate === false
                    ? 'No Sauce Labs integration was found'
                    : ''
                } `"
                placement="top"
              >
                <span>
                  <el-radio
                    style="min-width: 150px"
                    :disabled="!sourceLabsIsIntegrate"
                    v-model="runServer"
                    label="source"
                    border
                    >Sauce Labs</el-radio
                  >
                </span>
              </el-tooltip>
              <el-tooltip
                :disabled="awsIsIntegrate"
                :content="`${
                  awsIsIntegrate === false ? 'No AWS integration was found' : ''
                } `"
                placement="top"
              >
                <el-radio
                  style="min-width: 150px; color: #1f213e"
                  :disabled="!awsIsIntegrate"
                  v-model="runServer"
                  class="ml-1"
                  label="aws"
                  border
                  >AWS</el-radio
                ></el-tooltip
              >
            </div>
            <label
              v-show="runServer == 'source'"
              class="model-label"
              for="Sauce_Labs_Integration"
            >
              Sauce Labs Integration
            </label>
            <el-select
              v-show="runServer == 'source'"
              v-model="integrationSaurceLabsIndex"
              placeholder="Select Sauce labs"
            >
              <el-option
                v-for="(item, id) in integrationSaurceLabs"
                :key="id"
                :label="getLabelSauceLabs(item)"
                :value="id"
              >
              </el-option>
            </el-select>

            <label
              v-show="runServer == 'aws'"
              class="model-label"
              for="aws_Integration"
            >
              AWS Integration
            </label>
            <el-select
              v-show="runServer == 'aws'"
              v-model="integrationAwsIndex"
              placeholder="Select AWS"
            >
              <el-option
                v-for="(item, id) in integrationAws"
                :key="id"
                :label="getLabelAws(item)"
                :value="id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="fl--elt_container mt-1">
            <div class=" no-border fl--elt_container">
              <div class="fl--col mr-4">
                <label class="text-generate" for="maxEpisodesValue">Generate max number of tests</label>
                <!-- <input
                  type="text"
                  id="maxEpisodesValue"
                  name="maxEpisodesValue"
                  placeholder="Enter Maximum number of tests to Generate"
                  v-model="maxEpisodesValue"
                /> -->
                <el-input-number
                
                  class="custom-el-input-number"
                  style="width: 100px !important"
                  :controls="false"
                  v-model="maxEpisodesValue"
                  id="maxEpisodesValue"
                  name="maxEpisodesValue"
                  placeholder="Max"
                  :min="1"
                ></el-input-number>
              </div>
            </div>
            <div style="gap: 5px;display: flex;">
              <button
                @click="startTestEnhancerRun"
                class="add_button"
              >
                {{ $t("test_enhancer.run") }}
              </button>
              <button
                @click="showRunStepsModal = false"
                class="cancel_button"
              >
                {{ $t("test_enhancer.cancel") }}
              </button>
            </div>
          </div>
        </modal>
      </div>
      <!-- steps run -->
      <div class="testEnhancer-run-modal-container">
        <modal :show.sync="variablesModal">
          <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel">
              Simulation Variable
            </h5>
          </template>
          <data-list
            :items="currentVariables"
            :customSizes="true"
            :loading="false"
            emptyText="This run has no variables"
          >
            <template #content>
              <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;text-align: center;" class="custom-header-rows">
              <div class="elt-row max-width">
                <h3>VARIABLE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>DATA</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>TYPE</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>INITIAL VALUE</h3>
              </div>
              </div>
              <div
                v-for="(elt, index) in currentVariables"
                :key="index"
                class="tblb--row mb-2"
              >
                <div class="tblb--inner" style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;text-align: center;">
                  <div class="elt-row max-width">
                    <span class="test-run-name">{{ elt[0] }}</span>
                  </div>
                  <div class="elt-row max-width">
                    <el-tooltip content="shows all data" placement="top">
                      <span
                        :id="`var_tag_data_${index}`"
                        class="var--tag"
                        style="max-width: 100%"
                        @click="varTagchek(`var_tag_data_${index}`)"
                      >
                        {{ elt[1].data }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="elt-row max-width">
                    <span class="test-time-cont">{{ elt[1].type }}</span>
                  </div>
                  <div class="elt-row max-width">
                    <el-tooltip content="shows all value" placement="top">
                      <span
                        :id="`var_tag_init_value_${index}`"
                        class="var--tag"
                        @click="varTagchek(`var_tag_init_value_${index}`)"
                      >
                        {{ elt[1].init_value }}
                      </span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </data-list>
        </modal>
      </div>
      <!-- run variable -->

      <modal
        class="small-modal"
        :show.sync="showAddVariableModal"
        v-loading="loadingVariableCreation"
      >
        <template slot="header">
          <h5 class="modal-title">
            {{
              modalType == "add" ? "Add Variable for Launch" : "Edit Variable"
            }}
          </h5>
        </template>
        <div class="mod--content">
          <el-form class="small-spaces">
            <el-row style="display: flex; flex-direction: column" :gutter="20">
              <el-col
                class="el-form-item-tall"
                :span="editedVariable.type ? 8 : 12"
              >
                <el-form-item label="Variable">
                  <el-input
                    class="input-width-tall"
                    type="text"
                    placeholder="Enter variable"
                    v-model="editedVariable.name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                class="el-form-item-tall"
                :span="editedVariable.type ? 8 : 12"
              >
                <el-form-item label="Type">
                  <el-select
                    class="select-danger input-width-tall"
                    placeholder="Variable type"
                    v-model="editedVariable.type"
                    @change="showIsActive()"
                  >
                    <el-option
                      v-for="item in typeSelectOptions"
                      class="select-danger"
                      :value="item.value"
                      :label="item.label"
                      :key="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                class="el-form-item-tall"
                :span="8"
                v-if="
                  editedVariable.type === 'float' ||
                  editedVariable.type === 'integer' ||
                  editedVariable.type === 'set'
                "
              >
                <el-form-item label="Data">
                  <el-input
                    class="input-width-tall"
                    type="text"
                    placeholder="value1, value2, value3, …"
                    v-model="editedVariable.data"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                class="el-form-item-tall"
                :span="8"
                v-else-if="
                  editedVariable.type === 'address' ||
                  editedVariable.type === 'credit_card' ||
                  editedVariable.type === 'currency' ||
                  editedVariable.type === 'date' ||
                  editedVariable.type === 'email' ||
                  editedVariable.type === 'firstName' ||
                  editedVariable.type === 'lastName' ||
                  editedVariable.type === 'fullName' ||
                  editedVariable.type === 'username' ||
                  editedVariable.type === 'password' ||
                  editedVariable.type === 'text' ||
                  editedVariable.type === 'xpath' ||
                  editedVariable.type === 'css' ||
                  editedVariable.type === 'phoneNumber'
                "
              >
                <el-form-item label="value">
                  <el-input
                    class="input-width-tall"
                    type="text"
                    placeholder="value"
                    v-model="editedVariable.data"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  v-if="editedVariable.source !== 'datafile'"
                  label="Is active"
                >
                  <el-switch
                    v-model="editedVariable.status"
                    active-color="#1F213E"
                    inactive-color="#DBDBDB"
                    active-value="active"
                    inactive-value="disabled-nonactive"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <template slot="footer">
          <div style="display: flex; gap: 5px" class="flex--box">
            <div>
              <button class="cancel_button" @click="cleanModelVariable">
                Cancel
              </button>
            </div>
            <div>
              <button
                style="margin-bottom: 0"
                class="add_button"
                @click="addVariable"
              >
                Save
              </button>
            </div>
          </div>
        </template> </modal
      ><!-- [OK] add variables -->
      <modal :show.sync="showModalPreviewFiles">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("test_enhancer.Preview_file") }} {{ PreviewFileData.name }}
          </h5>
        </template>
        <div class="add-test-modal">
          <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-dark-new"
            :emptyText="$t('test_enhancer.no_variable')"
            ref="varData"
            :data="PreviewFileData.value"
            :border="true"
            stripe
          >
            <el-table-column
              :label="$t('test_enhancer.name')"
              min-width="85px"
              max-width="250px"
              sortable
              align="left"
            >
              <template slot-scope="props">
                {{ props.row[0] }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('test_enhancer.varaiable_value')"
              min-width="150px"
              sortable
              align="left"
            >
              <template slot-scope="props">
                {{ props.row[1] }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template slot="footer">
          <base-button
            type="secondary"
            @click="showModalPreviewFiles = false"
            >{{ $t("test_enhancer.cancel") }}</base-button
          >
        </template> </modal
      ><!-- preview file variables -->
      <modal class="small-modal" :show.sync="showModalVariableFiles">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("test_enhancer.Data_file_upload") }}
          </h5>
        </template>
        <div>
          <div class="add-test-modal" v-show="modalTypeDatafile === ''">
            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-dark-new"
              :emptyText="$t('test_enhancer.no_file_variable')"
              ref="varData"
              :data="getListFile()"
              :border="true"
              stripe
            >
              <el-table-column
                :label="$t('test_enhancer.name')"
                min-width="150px"
                max-width="250px"
                sortable
                align="left"
              >
                <template slot-scope="props">
                  {{ props.row["file_name"] }}
                </template>
              </el-table-column>
              <el-table-column
                label="CREATED"
                min-width="85px"
                sortable
                align="left"
              >
                <template slot-scope="props">
                  {{ formatDate(props.row) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('test_enhancer.actions')"
                min-width="100px"
                align="center"
              >
                <div slot-scope="{ row }" class="table-actions d-flex">
                  <el-tooltip content="Delete" placement="top">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      @click.stop="deleteFile(row)"
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="View file" placement="top">
                    <el-button
                      type="success"
                      icon="el-icon-view"
                      @click.stop="vueFile(row)"
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="Download file" placement="top">
                    <el-button
                      type="info"
                      icon="el-icon-download"
                      @click.stop="downloadFile(row)"
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div class="add-var-modal" v-show="modalTypeDatafile === 'addfile'">
            <div class="mt-2">
              <div class="three-input">
                <div class="tic">
                  <h4 class="fbb mt-1">add variables with a file</h4>
                  <div class="upload-craglist upload-file-div-wrapper">
                    <div
                      class="drop-down-container"
                      @dragover="dragover"
                      @dragleave="dragleave"
                      @drop="drop"
                      ref="dropDownContainer"
                    >
                      <input
                        type="file"
                        multiple="multiple"
                        name="fields[assetsFieldHandle][]"
                        id="swal-test-files"
                        class="w-px h-px opacity-0 overflow-hidden absolute fileInput"
                        @change="onFileChange"
                        ref="file"
                        accept=".csv,.xls, .xlsx, .xlsm, .xlsb, .odf"
                      />

                      <label
                        for="assetsFieldHandle"
                        class="block cursor-pointer"
                      >
                        <div>
                          Upload the variables file (accepted file type:
                          .csv,.xls,.xlsx, .xlsm, .xlsb, .odf, .ods, .odt)
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="craglist-files" v-if="file.length > 0">
                    <ul>
                      <li class="mt-1">
                        {{ file }}
                        <i
                          class="ni ni-fat-remove rmv-btn"
                          @click="removedFile"
                        ></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div
            v-if="showModalVariableFiles"
            style="display: flex; gap: 5px"
            v-show="modalTypeDatafile === ''"
            class="flex--box"
          >
            <div>
              <button class="add_button" @click="addFileVariableModal">
                {{ $t("test_enhancer.add_file") }}
              </button>
            </div>
            <div>
              <button
                class="cancel_button"
                @click="showModalVariableFiles = false"
              >
                {{ $t("test_enhancer.cancel") }}
              </button>
            </div>
          </div>
          <div v-show="modalTypeDatafile === 'addfile'" class="flex--box">
            <div>
              <button class="cancel_button" @click="modalTypeDatafile = ''">
                {{ $t("test_enhancer.cancel") }}
              </button>
            </div>
            <div>
              <button
                class="add_button"
                :disabled="!canAddFile"
                @click="addFileVariable"
              >
                Add
              </button>
            </div>
          </div>
        </template> </modal
      ><!-- display the user's variables file -->
      <modal :show.sync="showModalVariableSalesforce">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            Import Salesforce Data
          </h5>
        </template>
        <div>
          <div class="add-test-modal">
            <div class="add-var-modal">
              <div class="mt-2">
                <div class="mod--content">
                  <div v-if="loadingSalesforce">
                    <div class="tblb--inner">
                      <img
                        src="/img/loading.gif"
                        alt="loading..."
                        style="height: 64px; width: 64px"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <el-form class="small-spaces">
                      <el-form-item label="Variable imported on salesforce">
                        <el-row :gutter="20">
                          <el-col :span="20">
                            <el-select
                              v-model="salesforceActiveVariable"
                              placeholder="Select type "
                              @input="
                                salesforceVueVar(salesforceActiveVariable)
                              "
                            >
                              <el-option
                                v-for="(item, id) in getVariables('salesforce')"
                                :key="id"
                                :label="item[1].name"
                                :value="item"
                              >
                                <span style="float: left">{{
                                  item[1]["name"]
                                }}</span>
                                <span
                                  style="
                                    float: right;
                                    color: #606266;
                                    font-size: 13px;
                                  "
                                >
                                  {{ item[1]["type"] }}
                                </span>
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="4">
                            <el-button
                              type="primary"
                              style="width: 100%"
                              icon="el-icon-plus"
                              @click="AddCulm"
                            >
                              Add
                            </el-button>
                          </el-col>
                        </el-row>
                      </el-form-item> </el-form
                    ><br />
                    <el-form
                      class="small-spaces"
                      v-if="qlyVariables.length > 0"
                    >
                      <div
                        v-for="(item, id) in launch_salesforce_variable"
                        :key="id"
                      >
                        <el-row :gutter="20">
                          <el-col
                            :span="
                              launch_salesforce_variable.length > 1 ? 9 : 10
                            "
                          >
                            <el-form-item label="Variable">
                              <el-select
                                class="select-danger"
                                placeholder=" Select variable name"
                                v-model="launch_salesforce_variable[id].name"
                                @input="getAllVariablesSeptSalesforce()"
                                :rules="['Required']"
                              >
                                <el-option
                                  v-for="item in selectNameVar"
                                  class="select-danger"
                                  :value="item"
                                  :label="item"
                                  :key="item"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="
                              launch_salesforce_variable.length > 1 ? 9 : 10
                            "
                          >
                            <el-form-item label="Value">
                              <el-select
                                v-model="launch_salesforce_variable[id].value"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                :reserve-keyword="false"
                                placeholder="Choose the values "
                              >
                                <el-option
                                  v-for="(item, id) in getVariables(
                                    'salesforce_var'
                                  )"
                                  :key="id"
                                  :label="item"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="
                              launch_salesforce_variable.length > 1 ? 3 : 4
                            "
                          >
                            <el-form-item style="width: 100%" label="Value">
                              <el-checkbox
                                style="width: 100%"
                                v-model="checked3[id]"
                                size="large"
                                label="Use all"
                                @change="useAllSuggestionSalesforceVariable(id)"
                              />
                            </el-form-item>
                          </el-col>
                          <el-col
                            v-show="launch_salesforce_variable.length > 1"
                            :span="3"
                          >
                            <el-form-item label="Delete">
                              <el-button
                                type="danger"
                                icon="el-icon-delete"
                                @click="RemovedCulm(id)"
                              >
                              </el-button>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- <el-autocomplete 
                          class="inline-input" 
                          v-model="launch_salesforce_variable[0].value"
                          :fetch-suggestions="getVariables('salesforce_var')"
                          placeholder="Entrez quelque chose"
                          @select="handleSelect"
                        >
                          <el-checkbox v-model="checked3[id]" size="large" slot="prepend" @change="useAllSuggestionSalesforceVariable(id)" />
                        </el-autocomplete> -->
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="flex--box">
            <div>
              <button
                class="cancel_button"
                @click="showModalVariableSalesforce = false"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                class="add_button"
                :disabled="!canAddVariableSalesforce"
                @click="addSalesforceVariable"
              >
                Save
              </button>
            </div>
          </div>
        </template> </modal
      ><!-- display the user's salesforce file -->
      <modal :show.sync="iterationModal">
        <template slot="header">
          <h4 class="text-base" id="exampleModalLabel">Step logs</h4>
        </template>
        <div class="console-modal" v-if="currentElt">
          <div class="script-heading">
            <h2>Message</h2>
            <i
              class="ni ni-single-copy-04"
              @click="copy(currentElt.message, 'Message')"
            ></i>
          </div>
          <div class="script p-4">
            <template
              v-if="currentElt.message && currentElt.message.length > 0"
            >
              <p class="text-white text-xs">
                {{ currentElt.message, }}
              </p>
            </template>
            <template v-else>
              <p class="text-white text-xs">No bugs found</p>
            </template>
          </div>
        </div> </modal
      ><!-- iterations details -->
      <modal class="small-modal" :show.sync="showEpisodeLog" v-if="currentRun">
        <template slot="header">
          <h4 class="text-base" id="exampleModalLabel">
            EpisodeNum {{ currentRun.episode_number + 1 ?? 0 }} logs
          </h4>
        </template>
        <div class="console-modal">
          <div class="script p-4">
            <template
              v-if="
                currentRun.steps_messages &&
                currentRun.steps_messages.length > 0
              "
            >
              <p
                v-for="item in currentRun.steps_messages"
                :key="item.id"
                class="text-white text-xs"
                style="overflow: hidden; text-overflow: ellipsis"
              >
                {{ item }}
              </p>
            </template>
            <template v-else>
              <p class="text-white text-xs">No bugs found</p>
            </template>
          </div>
        </div> </modal
      ><!-- Run log details -->
      <!-- Screenshot view Modal -->
      <div class="video-modal-container">
        <modal :show.sync="showScreenshotView" id="ssModal" v-loading="loading">
          <template slot="header">
            <div class=" flex-between-center">
              <h5 class="modal-title">Screen</h5>
              <div style="gap:10px;margin-right:15px" class="flex">
                <div class="flex btns-gap">
              <el-tooltip content="Save" placement="top">
                <el-button
                  @click.stop="editStepScreenshotView()"
                  class="ic--btn custom--btn-dropdown test-buttons-triple ni-download"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip content="Reset" placement="top">
                <el-button
                  @click.stop="handleSimItemsReset()"
                  class="ic--btn custom--btn-dropdown test-buttons-triple ni-refresh"
                ></el-button>
              </el-tooltip>
            </div>
            <div class="flex switch-explore-container">
              <h3>Explore</h3>
            <el-switch
                v-model="editedTestStep.required"
                active-color="#1F213E"
                inactive-color="#DBDBDB"
                :active-value="false"
                :inactive-value="true"
                class="explore-switch"
              ></el-switch>
            </div>
              </div>
            </div>
            
            <!-- <div class="ss-actions">
              <el-tooltip content="Previous step" placement="top">
                <el-button
                  @click="handleScreenshotSwitch('prev')"
                  icon="el-icon-arrow-left"
                  class="ic--btn mr-2 py-2"
                  :disabled="
                    editedTestStep.index == 1 && pagination.currentPage == 1
                  "
                >
                </el-button>
              </el-tooltip>
              <el-tooltip content="Next step" placement="top">
                <el-button
                  @click="handleScreenshotSwitch('next')"
                  icon="el-icon-arrow-right"
                  class="ic--btn mr-2 py-2"
                  :disabled="
                    toShow.length > 1 &&
                    editedTestStep.index == toShow[toShow.length - 1].index &&
                    pagination.currentPage == pagination.totalPages
                  "
                >
                </el-button>
              </el-tooltip>
              <el-switch
                v-model="editedTestStep.required"
                inactive-text="Explore"
                :active-value="false"
                :inactive-value="true"
                class="explore-switch ml-2 mr-2"
              ></el-switch>
              <div style="display:flex;gap:5px"> <el-tooltip content="Save" placement="top">
                <el-button
                  icon
                  @click.stop="editStepScreenshotView()"
                  class="ic--btn mr-2 py-2"
                >
                  <i class="far fa-save"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="Reset" placement="top">
                <el-button
                  icon="el-icon-refresh-left"
                  @click.stop="handleSimItemsReset()"
                  class="ic--btn mr-2 py-2"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="Advanced" placement="top">
                <el-button
                  :icon="
                    isAdvancedScreenshot ? 'el-icon-s-unfold' : 'el-icon-s-fold'
                  "
                  @click="isAdvancedScreenshot = !isAdvancedScreenshot"
                  class="ic--btn mr-2 py-2"
                ></el-button>
              </el-tooltip></div>
             
            </div> -->
          </template>
          <div class="modal-body-container-screen">
            
            <el-row class="ss-container" ref="ss_container" align="middle">
            <div class="img-container-expander">
              <img
              :src="testStepBigSS"
              ref="ss_img"
              alt="Preview Image"
              class="p--img"
              @load="setSSDimLoad"
              style="pointer-events: none"
            />
            <sim-item
              :rect="editedTestStep.element"
              :originalDim="originalDim"
              :canvasDim="canvasDim"
              mode="main"
            >
            </sim-item>
            <div v-if="!editedTestStep.required ">
              <sim-item
                v-for="(item, id) in simItemsWithoutDisabled"
                :key="id"
                :rect="item.rect"
                :originalDim="originalDim"
                :canvasDim="canvasDim"
                @click="handleDeleteSimItem(item, $event)"
                :mode="item.active ?? true ? 'similar' : 'similar-disabled'"
              >
              </sim-item>
            </div>
            </div>
            <div class="screen-btns-pag">
              <el-tooltip content="Previous step" placement="top">
                <el-button
                  @click="handleScreenshotSwitch('prev')"
                  
                  class="ic--btn mr-2 py-2 button-pag-word"
                  :disabled="
                    editedTestStep.index == 1 && pagination.currentPage == 1
                  "
                >
                <span class="el-icon-arrow-left"></span>
                Previous
                </el-button>
              </el-tooltip>
              <el-tooltip content="Next step" placement="top">
                <el-button
                  @click="handleScreenshotSwitch('next')"
                  class="ic--btn mr-2 py-2 button-pag-word"
                  :disabled="
                    toShow.length > 1 &&
                    editedTestStep.index == toShow[toShow.length - 1].index &&
                    pagination.currentPage == pagination.totalPages
                  "
                >
                Next
                <span class="el-icon-arrow-right"></span>
                </el-button>
              </el-tooltip>
            </div>
          </el-row>
          <div class="ss-actions">
            <!-- <div class="flex btns-gap">
              <el-tooltip content="Save" placement="top">
                <el-button
                  @click.stop="editStepScreenshotView()"
                  class="ic--btn custom--btn-dropdown test-buttons-triple ni-download"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip content="Reset" placement="top">
                <el-button
                  @click.stop="handleSimItemsReset()"
                  class="ic--btn custom--btn-dropdown test-buttons-triple ni-refresh"
                ></el-button>
              </el-tooltip>
            </div>
            <div class="flex switch-explore-container">
              <h3>Explore</h3>
            <el-switch
                v-model="editedTestStep.required"
                active-color="#1F213E"
                inactive-color="#DBDBDB"
                :active-value="false"
                :inactive-value="true"
                class="explore-switch"
              ></el-switch>
            </div> -->

              <el-form style="padding-right:10px" class="small-spaces explore-form-cont" label-position="left">
              <el-form-item label="Action">
                <el-select
                  class="select-danger"
                  style="height: 35px"
                  placeholder="Set the action"
                  v-model="editedTestStep.action"
                  @change="remoteMethod"
                >
                  <el-option
                    v-for="(item, id) in stepActionOptions"
                    class="select-danger"
                    :value="item.value"
                    :label="item.label"
                    :key="id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Selector Method">
                <el-select
                ref="ssPathItem"
                  class="select-primary"
                  style="height: 35px"
                  placeholder="Set the Selector Method"
                  v-model="editedTestStep.element.selector_method"
                >
                  <el-option
                    v-for="(item, id) in stepMethodOptions"
                    class="select-primary"
                    :value="item.value"
                    :label="item.label"
                    :key="id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="Element Path">
                <el-input class="input-video-session" placeholder="Xpath" v-model="editedTestStep.element.selector_value">
                </el-input>
                <!-- <tagged-input
                  ref="ssPathItem"
                  :inputValue="editedTestStep.element.selector_value"
                  :optionsList="listElPath"
                  mode="selector"
                >
                </tagged-input> -->
              </el-form-item>
              <el-form-item label="Data">
                <el-select
                ref="ssDataItem"
                  class="select-primary"
                  style="height: 35px"
                  placeholder="Set the Selector Method"
                  v-model="editedTestStep.value"
                >
                  <el-option
                    v-for="(item, id) in qlyVariables"
                    class="select-primary"
                    :value="item.init_value"
                    :label="item.name"
                    :key="id"
                  >
                  </el-option>
                  
                </el-select>
                
              
              </el-form-item>
                <!-- <tagged-input
                  ref="ssDataItem"
                  :inputValue="editedTestStep.value"
                  :optionsList="qlyVariables"
                  mode="data"
                >
                </tagged-input> -->
              <el-form-item v-if="!editedTestStep.required && showScreenshotView">
                <data-list
                  :items="simItemsOnlyActive"
                  :customSizes="true"
                  :loading="false"
                  emptyText="No similar elements found..."
                  class="ss-list">
                 
                   <template #content>
                    <div style="display:grid;grid-template-columns: 1fr 1fr;text-align: center;color:white" class="custom-header-rows">
                    <div>SIMILAR ELEMENT</div>
                    <div>ACTIONS</div>
                  </div>
                    <div style="max-height: 88px;overflow-y: scroll;">
                      <div
                      v-for="(elt, index) in simItemsOnlyActive"
                      :key="index"
                      class="tblb--row mb-2"
                    >
                      <div class="tblb--inner" style="display:grid;grid-template-columns: 1fr 1fr;text-align: center;">
                        <div class="elt-row max-width" >
                          <span class="test-run-name">{{
                            elt.text ? elt.text : elt.xpath
                          }}</span>
                        </div>
                        <div class="elt-row flex--box max-width">
                          <el-tooltip content="Delete" placement="top">
                            <el-button
                              type="danger"
                              icon="el-icon-delete"
                              @click="handleDeleteSimItem(elt, $event)"
                              circle
                              plain
                              size="small"
                            ></el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                    </div>
                  </template> 
                </data-list>
              </el-form-item>
            </el-form>
            </div>
          </div>


          <el-menu style="position:absolute;top:-30px;right:40px;" :collapse="!isAdvancedScreenshot">
            
          </el-menu>
        </modal>
      </div> </template
    ><!-- Regions Modals -->
  </div>
</template>

<script>
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
} from "element-ui";
import service from "@/store/services/items-service";
import axios from "axios";
import "sweetalert2/dist/sweetalert2.css";
import DataList from "@/components/Custom/DataList.vue";
import moment from "moment";
import SimItem from "@/components/Custom/SimItem.vue";
import TaggedInput from "@/components/Custom/TaggedInput.vue";

export default {
  name: "TestEnhancer",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    DataList,
    SimItem,
    TaggedInput,
  },
  props: {
    RLJson: {
      type: Object,
    },
    id: {
      type: String,
    },
    get: {
      type: Function,
    },
    loadingVar: {
      type: Boolean,
      default: false,
      description: "Loading variable state",
    },
    loadingSalesforce: {
      type: Boolean,
      default: false,
      description: "Loading salesforce variable",
    },
  },
  data() {
    return {
      toShowLoading: true,
      datafileVariable: {},
      editedVariableIndex: -1,
      loadingVariableCreation: false,
      loadingVariableList: false,
      oldVariable: undefined,
      editedVariable: {
        name: undefined,
        type: undefined,
        data: undefined,
        init_value: undefined,
        isFixed: undefined,
        source: undefined,
        status: undefined,
      },
      newVariable: {
        name: undefined,
        type: undefined,
        data: undefined,
        init_value: undefined,
        isFixed: undefined,
        source: undefined,
        status: undefined,
      },
      typeSelectOptions: [
        { value: "address", label: "Address" },
        { value: "credit_card", label: "Credit card" },
        { value: "currency", label: "Currency" },
        { value: "date", label: "Date" },
        { value: "email", label: "Email" },
        { value: "firstName", label: "First name" },
        { value: "float", label: "Float" },
        { value: "integer", label: "Integer" },
        { value: "lastName", label: "Last name" },
        { value: "username", label: "User name" },
        { value: "fullName", label: "Full name" },
        { value: "text", label: "Text" },
        { value: "password", label: "Password" },
        { value: "css", label: "Css" },
        { value: "xpath", label: "Xpath" },
        { value: "phoneNumber", label: "Phone number" },
        { value: "set", label: "Set" },
      ],
      currentEpisode: undefined,
      currentRun: undefined,
      showEpisodeLog: false,

      // All the variable below need to be verify
      RLtestcase: {
        sequence: {},
        variables: {},
      },
      checked3: [false],
      checkedTrue: true,
      salesforceMethodType: null,
      activeHover: null,
      checkedFalse: false,
      ultraIpt: "",
      isSharing: "false",
      sharedJSONn: undefined,
      ultraIptSet: "",
      stepsAlgorithmValue: "hi",
      datafileVariables: false,
      maxEpisodesValue: "5",
      showRunStepsModal: false,
      stepNumberForTable: [],
      allRuns: [],
      currentVariables: undefined,
      useTgAnalyze: false,
      te_id: "",
      rl_json: "",
      resultsData: false,
      bugCountStatus: null,
      totalSteps: null,
      log: console.log,
      showVariablesModal: false,
      showModalVariableFiles: false,
      showAddVariableModal: false,
      showAddFileVariableModal: false,
      showEditVariableModal: false,
      showPreviewFileModal: false,
      showModalVariableSalesforce: false,
      launch_variable: "",
      launch_salesforce_variable: [{ name: "", value: [] }],
      launch_type: "",
      launch_init_value: null,
      launch_is_active: false,
      launch_source: "",
      launch_data: "",
      launch_salesforce_data: [],
      file: "",
      fileUpload: "",
      launch_oldVariable: "",
      modalType: "",
      modalTypeDatafile: "",
      showTestStepModal: false,
      step_url: "",
      step_action: "CLICK",
      step_selector_method: "xpath",
      step_element_path: "",
      step_data: "",
      step_mutate: true,
      forModalBtn: "",
      forStepRow: "",
      editedIndex: -1,
      showSetup: true,
      showRuns: false,
      showEpisodeRun: false,
      currentElt: undefined,
      failluresModal: false,
      variablesModal: false,
      iterationModal: false,
      nameFileUse: "",
      stepActionOptions: [
        { value: "CLICK", label: "Click" },
        { value: "ENTER", label: "Enter" },
        { value: "SELECT", label: "Select" },
        { value: "VERIFY_EQUALS", label: "Verify_Equals" },
        { value: "VERIFY_DIFF", label: "Verify_Diff" },
        { value: "VERIFY_CONTAINS", label: "Verify_Contains" },
        { value: "SAVE_VALUE", label: "SAVE" },
        { value: "VERIFY_PAGE_CONTAINS", label: "Verify_Page_Contains" },
      ],
      stepMethodOptions: [
        { value: "xpath", label: "XPath" },
        { value: "css", label: "CSS" },
      ],
      stepMutateOptions: [
        { value: true, label: "No" },
        { value: false, label: "Yes" },
      ],
      stepRunAlgorithm: [
        {
          value: this.$t("test_enhancer.randomize"),
          label: this.$t("test_enhancer.randomize"),
          description: this.$t("test_enhancer.randomize_description"),
        },
        {
          value: this.$t("test_enhancer.sequential"),
          label: this.$t("test_enhancer.sequential"),
          description: this.$t("test_enhancer.sequential_description"),
        },
        {
          value: this.$t("test_enhancer.exhaustive"),
          label: this.$t("test_enhancer.exhaustive"),
          description: this.$t("test_enhancer.exhaustive_description"),
        },
        {
          value: this.$t("test_enhancer.permutation"),
          label: this.$t("test_enhancer.permutation"),
          description: this.$t("test_enhancer.permutation_description"),
        },
        // {
        //   value: this.$t("test_enhancer.exploration"),
        //   label: this.$t("test_enhancer.exploration"),
        //   description: this.$t("test_enhancer.exploration_description"),
        // },
      ],
      query: "",
      selectedRows: [],
      sort: "created_at",
      total: 0,
      totalRun: 0,
      row_id: undefined,
      filteredTotal: [],
      filteredResults: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        totalPages: 0,
        perPageOptions: [5, 10, 25, 50],
      },
      runPagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      runStepsPagination: {
        perPage: 12,
        currentPage: 1,
        perPageOptions: [6, 12, 24, 48],
      },
      toShow: [],
      loading: false,
      loadingAll: false,
      to: 0,
      from: 0,
      runToShow: [],
      runTo: 0,
      runFrom: 0,
      dynamicvarHeader: [
        {
          label: "Variable",
          key: "_variable",
          width: "17%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "Type", key: "", width: "10%", sortable: false },
        { label: "Data", key: "", width: "30%", sortable: false },
        { label: "Source", key: "", width: "13%", sortable: false },
        { label: "Active", key: "", width: "10%", sortable: false },
        { label: "Actions", key: "", width: "20%", sortable: false },
      ],
      varHeader: [
        {
          label: "Variable",
          key: "_variable",
          width: "15%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        { label: "Data", key: "", width: "40%", sortable: false },
        { label: "Type", key: "", width: "15%", sortable: false },
        { label: "Initial Value", key: "", width: "30%", sortable: false },
      ],
      tHeader: [
        {
          label: "screenshot".toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.step").toUpperCase(),
          key: "_id",
          width: "5%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: this.$t("test_enhancer.description").toUpperCase(),
          key: "",
          width: "38%",
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.actions").toUpperCase(),
          key: "",
          width: "15%",
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.operations").toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
      ],
      tHeader2: [
        {
          label: this.$t("test_enhancer.date_run").toUpperCase(),
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.failures").toUpperCase(),
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.episodes").toUpperCase(),
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.agent_type").toUpperCase(),
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.status").toUpperCase(),
          sortable: false,
        },
        {
          label: this.$t("test_enhancer.actions").toUpperCase(),
          sortable: false,
        },
      ],
      tHeader3: [
        {
          label: "Episode",
          key: "_id",
          width: "13%",
          sortable: false,
        },
        { label: "Duration", key: "", width: "20%", sortable: false },
        { label: "Failed steps", key: "", width: "20%", sortable: false },
        { label: "", key: "", width: "15%", sortable: false },
      ],
      loading3: false,
      allVariables: [],
      editedTestStep: {
        action: "",
        current_url: "",
        value: "",
        required: false,
        screenshot: null,
        description: "",
        element: {
          selector_value: "xpath",
          selector_method: "",
        },
        index: "",
      },
      newTestStep: {
        action: "",
        current_url: "",
        value: "",
        required: false,
        screenshot: null,
        description: "",
        element: {
          selector_value: "",
          selector_method: "",
        },
        index: "",
      },
      RLCurrentVariables: {},
      listFile: null,
      varSalesforce: {},
      selectVarSF: {},
      newRunId: null,
      detailRuns: [],
      idVueRun: null,
      idVueSteps: null,
      showModalSteps: false,
      loadingSteps: false,
      EpisodeSteps: [],
      defaultSteps: "",
      showRunsList: false,
      showStepsEpisode: false,
      totalRunSteps: 0,
      runEpisodeToShow: [],
      loadElPath: [],
      listElPath: [],
      optionElPath: [],
      loadingElPath: false,
      loadingVariable: false,
      showOverScreenshot: false,
      activeScreenshot: "",
      showModalPreviewFiles: false,
      salesforceActiveVariable: "",
      PreviewFileData: {
        name: "",
        value: [],
      },
      selectNameVar: [],
      page_num: 1,
      qlyVariables: [],
      ultraIptXpath: "",
      ultraIptXpathSet: "",
      resultProject: [],
      selectProjectKey: "",
      RunStepRecommended: true,
      RunStepAdvanced: false,
      canvas: null, // screenshot for test step
      testStepBigSS: "",
      originalDim: {
        width: 1919,
        height: 1006,
        yOffset: 0,
      },
      canvasDim: {
        width: 0,
        height: 0,
      },
      showScreenshotView: false,
      simItems: [],
      simItemsHeader: [
        { label: "Similar Element", key: "", width: "80%", sortable: false },
        { label: "Actions", key: "", sortable: false },
      ],
      isAdvancedScreenshot: false,
      ro: null,
      runServer: "wring",
      integrationData: [],
      integrationSaurceLabs: [],
      sourceLabsIsIntegrate: false,
      integrationSaurceLabsIndex: 0,
      appType: "web",
      selectPlatform: ["Windows 11"],
      selectVersion: "Latest",
      selectBrowser: [],
      awsIsIntegrate: false,
      integrationAwsIndex: [],
      integrationAws: [],
    };
  },
  async created() {
    //#region pagination
    this.pagination.perPage = sessionStorage.getItem("testEnhancerPageNbr")
      ? parseInt(sessionStorage.getItem("testEnhancerPageNbr"))
      : 10;
    this.runPagination.perPage =
      sessionStorage.getItem("testEnhancerRunPageNbr") !== null
        ? parseInt(sessionStorage.getItem("testEnhancerRunPageNbr"))
        : 10;
    this.runStepsPagination.perPage =
      sessionStorage.getItem("testEnhancerRunStepsPageNbr") !== null
        ? parseInt(sessionStorage.getItem("testEnhancerRunStepsPageNbr"))
        : 12;
    //#endregion

    console.log({ id: this.id });

    this.items = await this.$store.getters["items/item"];
    this.items !== undefined
      ? (this.useTgAnalyze = this.items.runOptions?.use_tg_analyze ?? undefined)
      : undefined;

    const walToken = localStorage.getItem("vue-authenticate.access_token");

    this.isSharing = localStorage.getItem("vue-authenticate.is_sharing");
    this.sharedJSON = Object.assign({}, this.parseJwt(walToken));
    !(this.useTgAnalyze && this.isEmptyObject(this.RLJson)) &&
      (await Promise.all([this.getResults(), this.getAllResults()]));

    await Promise.all([
      this.getAllVariablesSeptSalesforce(),
      this.initWebsocketStreaming(),
      this.getAllVariables(),
      this.setListFile(),
      this.getProject(),
    ]);
    this.populateIntegrationsTable();
  },
  methods: {
    //#region helpers
    getLabelSauceLabs(item) {
      let tl = item.unique_integration_identifier.split(",");
      if (tl[1] && item.sauceLabs_it_name && item.sauceLabs_platforms_type)
        return `${item.sauceLabs_it_name} ( ${
          item.sauceLabs_platforms_type
        } ) ${item.sauceLabs_username} ${this.formatDateSauceLbs(Date(tl[1]))}`;
      return item.sauceLabs_username;
    },
    getLabelAws(item) {
      let tl = item.unique_integration_identifier.split(",");

      return `${item.integration_details.aws_it_name} ( ${
        item.integration_details.aws_platforms_type
      } ) ${this.formatDateSauceLbs(Date(tl[1]))}`;
    },
    async populateIntegrationsTable() {
      this.integrationSaurceLabs = [];
      this.integrationAws = [];

      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/integrations/user", {
          params: {
            request_type: "all",
          },
        })
        .then((response) => {
          if (
            response.message != "Request failed with status code 404" ||
            response.message === "Request failed with status code 500"
          ) {
            this.integrationData = response.data.user_integrations;
            this.integrationData.map((item) => {
              if (item.integration_name == "sauceLabs") {
                this.sourceLabsIsIntegrate = true;
                this.integrationSaurceLabs.push(item);
              }
              if (item.integration_name == "aws") {
                this.awsIsIntegrate = true;
                this.integrationAws.push(item);
              }
            });
          } else {
            this.integrationData = [];
            this.sourceLabsIsIntegrate = false;
            this.integrationSaurceLabs = [];
            this.integrationAws = [];
          }
        })
        .finally(() => {
          if (this.integrationData && this.integrationData.length > 0) {
            this.integrationData[0];
          }
        });
    },
    formatDateSauceLbs(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    focusElementPath(val) {
      if (val === "add") {
        const el = document.getElementsByClassName("ultra--ipt--xpath")[0];
        el.style.height = "70px";
        const input = document.getElementById("input_xpath_ultra");
        const end = input.value.length;
        input.setSelectionRange(end, end);
        input.focus();
      } else {
        const el = document.getElementsByClassName("ultra--ipt--xpath")[0];
        el.style.height = "50px";
        const input = document.getElementById("input_xpath_ultra");
        const end = input.value.length;
        input.setSelectionRange(end, end);
        input.blur();
      }
    },
    varTagchek(id) {
      let el = document.getElementById(id);
      el.classList.toggle("var--tag");
    },
    showRunSetting(val) {
      this.RunStepAdvanced = false;
      this.RunStepRecommended = false;
      if (val === "Advanced") {
        this.RunStepAdvanced = true;
      }
      if (val === "Recommended") {
        this.RunStepRecommended = true;
      }
    },
    showDynamicVariable() {
      console.log(Object.entries({ ...this.qlyVariables }));
      return Object.entries({ ...this.qlyVariables });
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        this.$refs.dropDownContainer.parentElement.classList.add("file-hover");
      }
    },
    dragleave() {
      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if (event.srcElement.files.length) {
        let files = event.target.files || event.dataTransfer.files;
        if (!files.length) return;
        this.createFile(files[0]);
      }

      this.$refs.dropDownContainer.parentElement.classList.remove("file-hover");
    },
    inDatafile(name) {
      const activeDatafile = Object.entries(this.datafileVariable).map(
        (item) => {
          return item[0];
        }
      );
      if (activeDatafile.includes(name)) return false;
      return true;
    },
    removedFile() {
      this.file = "";
      this.fileUpload = null;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    handleShowEpisodeLog(elt) {
      this.currentRun = Object.assign({}, elt);
      this.showEpisodeLog = true;
    },
    useAllSuggestionSalesforceVariable(id) {
      if (this.checked3[id] === true) {
        this.launch_salesforce_variable[id].value =
          this.getVariables("salesforce_var");
      } else {
        this.launch_salesforce_variable[id].value = [];
      }
    },
    AddCulm() {
      if (this.launch_salesforce_variable.length < this.qlyVariables.length) {
        this.launch_salesforce_variable.push({ name: "", value: [] });
      }
    },
    RemovedCulm(id) {
      if (this.launch_salesforce_variable.length > 1) {
        if (id == 0) {
          this.launch_salesforce_variable.shift();
        } else {
          this.launch_salesforce_variable.splice(id, id);
        }
      }
    },
    setAlgorithm(elt) {
      this.stepsAlgorithmValue = elt.value;
      console.log(this.stepsAlgorithmValue);
    },
    overScreenshot(val) {
      this.showOverScreenshot = true;
      this.activeScreenshot = val;
    },
    leaveScreenshot() {
      this.showOverScreenshot = false;
      this.activeScreenshot = "";
    },
    remoteMethod() {
      if (this.editedIndex < 0) {
        if (this.editedTestStep.action.includes("VERIFY")) {
          let Xpath = [];
          this.filteredResults.map((item) => {
            if (item.extra_info && item.extra_info.verify_suggestions) {
              return item.extra_info.verify_suggestions.map((item) => {
                Xpath.push(item.xpath);
              });
            }
          });
          this.listElPath = Xpath;
        } else {
          this.listElPath = this.filteredResults.map((item) => {
            return item.element.selector_value;
          });
          // this.listElPath = this.loadElPath.filter((item, pos) => {
          //   return this.loadElPath.indexOf(item) == pos;
          // });
        }
      } else {
        this.listElPath = [];
      }
    },
    secondsToRealTime(a) {
      let start = Date.parse(a.created_at);
      let end = null;
      if (a.updated_at && a.status === "FINISHED") {
        end = Date.parse(a.updated_at);
      } else {
        end = Date.parse(new Date().toGMTString() + a.created_at.substr(-6));
      }
      let sec = end - start;
      if (sec == 0) return "0s";
      return this.hhmmss(sec / 1000);
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const isVariable =
          this.qlyVariables.findIndex((v) => v.name === this.ultraIpt.trim()) >=
          0;
        const elt = isVariable
          ? document.createElement("span")
          : document.createElement("i");
        elt.classList.add("ml-1");
        isVariable
          ? elt.classList.add("u--tag")
          : elt.classList.add("u--tag--i");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("i");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
        }
        this.$refs["data-rendered"].appendChild(elt);
      }
    },
    handleUltraIptXpath() {
      const eltDataXpath = (
        this.$refs["data-rendered-xpath"].innerHTML + this.ultraIptXpath
      )
        .replace(/<span class="ml-1 u--tag">/g, "${")
        .replace(/<\/span>/g, "}");
      if (eltDataXpath.includes("${") && eltDataXpath.includes("}")) {
        this.ultraIptXpathSet = eltDataXpath
          .replace(/\${/g, '<span class="ml-1 u--tag">')
          .replace(/}/g, "</span>");
      } else {
        this.ultraIptXpathSet = eltDataXpath;
      }
      this.$refs["data-rendered-xpath"].innerHTML = this.ultraIptXpathSet;
      // this.ultraIptXpath = "";
    },
    handleDeleteLastDataValueXpath() {
      let getStr = this.$refs["data-rendered-xpath"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "${")
        .replace(/<\/span>/g, "}");
      const spaceValue = getStr.slice(0, -1);
      if (spaceValue.length === 0) this.ultraIptXpath = " ";
      if (spaceValue.includes("${") && spaceValue.includes("}")) {
        this.ultraIptXpathSet = spaceValue
          .replace(/\${/g, '<span class="ml-1 u--tag">')
          .replace(/}/g, "</span>");
      } else {
        this.ultraIptXpathSet = spaceValue;
      }
      this.$refs["data-rendered-xpath"].innerHTML = this.ultraIptXpathSet;
    },
    handleClearDataValue(val) {
      if (val === "path") {
        this.$refs["data-rendered-xpath"].innerHTML = "";
        this.ultraIptXpath = "";
      } else {
        this.ultraIpt = "";
        this.$refs["data-rendered"].innerHTML = "";
      }
    },
    toggleFileVariableModal() {
      this.showModalVariableFiles = true;
    },
    toggleVariableModal() {
      this.showVariablesModal = true;
    },
    showRunSteps() {
      this.showRunStepsModal = true;
    },
    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<\/span>/g, ",")
        .replace(/<i class="ml-1 u--tag--i">/g, "")
        .replace(/<i class="ml-1">/g, "")
        .replace(/<\/i>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());

      let _rem = getStr.splice(-1);

      if (this.ultraIpt.length <= 0) {
        this.$refs["data-rendered"].innerHTML = "";
        getStr.map((item) => {
          const isVariable =
            this.qlyVariables.findIndex((v) => v.name === item.trim()) >= 0;
          const elt = isVariable
            ? document.createElement("span")
            : document.createElement("i");
          elt.classList.add("ml-1");
          isVariable
            ? elt.classList.add("u--tag")
            : elt.classList.add("u--tag--i");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered"].appendChild(elt);
        });
      }
    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    showEnhancer(val) {
      if (val === "runs") {
        this.showSetup = false;
        this.showRuns = true;
        this.showRunsList = true;
        this.showEpisodeRun = false;
        this.showStepsEpisode = false;

        this.getAllResults();
      } else {
        this.showRuns = false;
        this.showSetup = true;
      }
    },
    openVariable(elt) {
      this.currentVariables = Object.entries({ ...elt.variable_json });
      this.variablesModal = true;
    },
    showIterations(elt) {
      this.currentElt = Object.assign({}, elt.extra_info);
      this.iterationModal = true;
    },
    handleCurrentChange(val) {
      this.page_num = val;
      this.pagination.currentPage = val;
      this.from = (val - 1) * this.pagination.perPage;
      this.to = parseInt(this.from) + parseInt(this.pagination.perPage);
      this.toShow = [...this.filteredResults].filter((item) => {
        if (
          !item.extra_info ||
          (item.extra_info && item.extra_info.hidden != true)
        )
          return item;
      }); // Because the backend is not returning all the data when pagniation
    },
    handleSizeChange(val) {
      this.pagination.perPage = val;
      this.handleCurrentChangeNextPage(this.pagination.currentPage);
    },
    handleRunCurrentChange(val) {
      this.runPagination.currentPage = val;
      this.runFrom = (val - 1) * this.runPagination.perPage;
      this.runTo =
        parseInt(this.runFrom) + parseInt(this.runPagination.perPage);
      this.runToShow = [...this.allRuns].filter((item, val) => {
        console.log(item);
        return this.runFrom <= val && val < this.runTo;
      });
    },
    handleRunSizeChange(val) {
      this.runPagination.perPage = val;
      this.handleRunCurrentChange(this.runPagination.currentPage);
    },
    handleRunStepsSizeChange(val) {
      this.runStepsPagination.perPage = val;
      this.handleRunStepsCurrentChange(this.runStepsPagination.currentPage);
    },
    savePagination() {
      sessionStorage.setItem("testEnhancerPageNbr", this.pagination.perPage);
      this.getResults();
    },
    saveRunPagination() {
      sessionStorage.setItem(
        "testEnhancerRunPageNbr",
        this.runPagination.perPage
      );
    },
    saveRunStepsPagination() {
      sessionStorage.setItem(
        "testEnhancerRunStepsPageNbr",
        this.runStepsPagination.perPage
      );
      this.handleRunStepsCurrentChange(this.runStepsPagination.currentPage);
    },
    forStepNumber(row) {
      return row + this.from + 1;
    },
    initializeState() {
      if (this.id) {
        this.loading = this.RLtestcase.sequence.length > 1;
        this.handleCurrentChange(this.pagination.currentPage);
        this.handleRunCurrentChange(this.runPagination.currentPage);
      }
    },
    formatDate(val) {
      if (val.created_at) return moment(val.created_at).format("MMM Do YYYY");
      else if (val.saved_at) return moment(val.saved_at).format("MMM Do YYYY");
    },
    downloadTestFile(id) {
      axios
        .get(`/interceptor/rl/v1/report/${this.items.requestId}`, {
          responseType: "blob",
          run_id: id,
        })
        .then((res) => {
          window.open(URL.createObjectURL(res.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
      this.$notify({
        type: "success",
        message: "Downloading file now!",
      });
    },
    cleanModelVariable() {
      this.showAddVariableModal = false;
      this.launch_variable = "";
      this.launch_type = "";
      this.launch_init_value = null;
      this.launch_is_active = false;
      this.launch_source = "";
      this.launch_data = "";
    },
    showIsActive() {
      if (
        this.launch_type.includes("username") ||
        this.launch_type.includes("password") ||
        this.launch_type.includes("email")
      ) {
        this.launch_is_active = false;
        return false;
      }
      return true;
    },
    async handleDeleteVariable(elt) {
      this.editedVariableIndex = this.qlyVariables.findIndex(
        (v) => v.name === elt.name
      );
      this.loadingVariableList = true;

      const _query = `var_name=${elt.name}`;
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/variables/${this.id}?${_query}`;
      const res = await axios.delete(uri);

      if (res.status === 200) {
        this.qlyVariables = [...this.qlyVariables].filter(
          (_, id) => id !== this.editedVariableIndex
        );
        this.$notify({
          type: "success",
          message: `successfully deleted ${elt.name} variable`,
        });
      } else {
        this.$notify({
          type: "danger",
          message: `Unable to delete ${elt.name} variable, check network`,
        });
      }

      this.loadingVariableList = false;
    },
    handleEditVariable(elt) {
      this.oldVariable = Object.assign({}, elt);
      this.editedVariable = Object.assign({}, elt);
      this.editedVariableIndex = this.qlyVariables.findIndex(
        (v) => v.name === elt.name
      );
      this.showAddVariableModal = true;
      this.modalType = "edit";
    },
    addVariableModal() {
      (this.editedVariable = {
        name: undefined,
        type: "email",
        data: undefined,
        init_value: undefined,
        isFixed: undefined,
        source: undefined,
        status: undefined,
      }),
        (this.launch_is_active = false);
      this.showVariablesModal = false;
      this.showAddVariableModal = true;
      this.modalType = "add";
    },
    addFileVariableModal() {
      this.showModalVariableFiles = false;
      this.showModalVariableFiles = true;
      this.modalTypeDatafile = "addfile";
    },
    forStepsModal(row, id, action) {
      this.ultraIpt = "";
      this.ultraIptXpath = "";
      this.editedIndex = action === "edit" ? id : -1;
      this.row_id = id;
      this.listElPath = [];
      if (this.editedIndex < 0) {
        this.$refs["data-rendered-xpath"].innerHTML = "";
        this.editedTestStep = {
          ...this.editedTestStep,
          action: "CLICK",
          current_url: row.current_url,
          element: {
            selector_method: "xpath",
            selector_value: "",
          },
          index: row.index + 1,
          required: false,
        };
        this.listElPath = this.filteredResults.map((item) => {
          return item.element.selector_value;
        });
        // this.listElPath = this.loadElPath.filter((item, pos) => {
        //   return this.loadElPath.indexOf(item) == pos;
        // });
      } else {
        this.ultraIptXpathSet = row.element.selector_value
          .replace(/\${/g, '<span class="ml-1 u--tag">')
          .replace(/}/g, "</span>");
        this.$refs["data-rendered-xpath"].innerHTML = this.ultraIptXpathSet;
        if (row.value != null) {
          this.listElPath = [];
          this.ultraIptSet = row.value
            .replace(" ", '<i class="ml-1"> </i>')
            .replace(/\${/g, '<span class="ml-1 u--tag">')
            .replace(/}/g, "</span>");
          this.$refs["data-rendered"].innerHTML = this.ultraIptSet;
        }
        this.editedTestStep = Object.assign({}, row);
      }
      this.showTestStepModal = true;
    },
    screenshotViewModal(row, id) {
      this.row_id = id;
      this.ultraIptXpathSet = row.element.selector_value
        .replace(/\${/g, '<span class="ml-1 u--tag">')
        .replace(/}/g, "</span>");
      this.$refs["data-rendered-xpath"].innerHTML = this.ultraIptXpathSet;
      if (row.value != null) {
        this.listElPath = [];
        this.ultraIptSet = row.value
          .replace(" ", '<i class="ml-1"> </i>')
          .replace(/\${/g, '<span class="ml-1 u--tag">')
          .replace(/}/g, "</span>");
        this.$refs["data-rendered"].innerHTML = this.ultraIptSet;
      }
      this.editedTestStep = Object.assign({}, row);

      this.testStepBigSS = `data:image/png;base64,${row.page_screenshot}`;
      try {
        this.originalDim = {
          width: row.extra_info.browser_properties.viewportWidth,
          height: row.extra_info.browser_properties.viewportHeight,
          yOffset: row.extra_info.browser_properties.yOffset,
        };
      } catch (error) {
        console.error("Cannot set originalDim!");
      }

      if (!row.extra_info) {
        row.extra_info = {};
      }

      if (
        row.extra_info.similar_elements &&
        row.extra_info.similar_elements.length > 0
      ) {
        this.simItems = row.extra_info.similar_elements;
      } else if (row.extra_info.options && row.extra_info.options.length > 0) {
        this.simItems = row.extra_info.options;
      } else {
        this.simItems = [];
      }

      this.showScreenshotView = true;
    },
    setSSDimLoad() {
      const imgStyle = getComputedStyle(this.$refs.ss_img);
      this.canvasDim = {
        width: parseFloat(imgStyle.width),
        height: parseFloat(imgStyle.height),
      };
    },
    async handleScreenshotSwitch(type) {
      let index = this.toShow.findIndex(
        (el) => el.index === this.editedTestStep.index
      );
      if (type == "prev") {
        index--;
      } else if (type == "next") {
        index++;
      }
      // TODO: Handle Pagination
      console.log("helllo", index);
      if (0 <= index && index < this.toShow.length) {
        this.screenshotViewModal(this.toShow[index]);
      }
      if (index == -1) {
        this.pagination.currentPage = this.pagination.currentPage - 1;
        await this.handleCurrentChangeNextPage(this.pagination.currentPage);
        await this.screenshotViewModal(this.toShow[0]);
      }
      if (index >= this.toShow.length) {
        this.pagination.currentPage = this.pagination.currentPage + 1;
        await this.handleCurrentChangeNextPage(this.pagination.currentPage);
        await this.screenshotViewModal(this.toShow[0]);
      }
    },
    handleDeleteSimItem(item, event) {
      let idx = this.simItems.findIndex((elem) => elem.xpath === item.xpath);
      this.simItems[idx].active = !(this.simItems[idx].active ?? true); // toggle deletion
      this.simItems = [...this.simItems];
    },
    handleSimItemsReset() {
      this.simItems = [...this.simItems].map((elem) => {
        elem.active = true;
        return elem;
      });
    },
    toggleSalesforceVariableModal() {
      this.salesforceActiveVariable = "";
      this.salesforceMethodeType = false;
      this.showModalVariableSalesforce = true;
      this.varSalesforce = {};
    },
    getVariables(type) {
      if (type === "salesforce" && this.items !== undefined) {
        return Object.entries({ ...this.items.salesforcevar }) ?? [];
      } else if (type === "salesforce_var") {
        const filteredVarSalesforce = Object.entries({
          ...this.varSalesforce,
        }).map((item) => {
          return item[1].data;
        });
        let uniqueArr = [...new Set(filteredVarSalesforce)];
        return uniqueArr;
      } else {
        let results = [];
        let valideDatafile = [];
        let datafile = Object.entries({ ...this.RLdatafile }).filter((item) => {
          if (item[1].status == "active") {
            valideDatafile.push(item[0]);
            return item;
          }
        });
        let testcase = Object.entries({ ...this.RLtestcase.variables }).filter(
          (item) => {
            if (valideDatafile.includes(item[0])) {
            } else {
              return item;
            }
          }
        );
        return results.concat(testcase, datafile) ?? [];
      }
    },
    getAllVariablesSeptSalesforce() {
      this.selectNameVar = [];
      let nameVar = this.qlyVariables.map((item) => {
        return item.name;
      });
      let nameVarSal = this.launch_salesforce_variable.map((item) => {
        return item.name;
      });
      this.selectNameVar = nameVar.filter((item) => {
        if (!nameVarSal.includes(item)) {
          return item;
        }
      });
    },
    ifInDatafile(row) {
      let datafile = Object.entries({ ...this.RLdatafile });
      for (let i = 0; i < datafile.length; i++) {
        if (datafile[i][0] == row[0]) {
          return "datafile";
        }
      }
    },
    closeTestStepModal() {
      this.editedIndex = -1;
      this.ultraIpt = "";
      this.$refs["data-rendered"].innerHTML = "";
      this.editedTestStep = Object.assign({}, this.newTestStep);
      this.loading = false;
    },
    onFileChange(e) {
      console.log(e);
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createFile(files[0]);
    },
    createFile(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file = file.name;
        this.fileUpload = file;
      };
    },
    addFileVariable() {
      let formData = new FormData();
      let file = this.fileUpload;
      let requestId = this.items.requestId;
      formData.append("datafile", file);
      formData.append("request_id", requestId);
      formData.append("action", "upload");
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/datafile",
          formData,
          { headers: headers }
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              swal.fire("Check the structure of your file ");
            } else if (response.status === 200) {
              this.setListFile();
              this.getAllVariables();
              this.showAddFileVariableModal = false;
              this.showModalVariableFiles = false;
              this.file = "";
              this.fileUpload = null;
              this.modalTypeDatafile = "";
              this.$notify({
                type: "success",
                message: "file " + file.name + " added successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire("Check the structure of your file ");
          } else {
            swal.fire(JSON.stringify("Error Adding file"));
          }
        });
    },
    getListFile() {
      return this.listFile;
    },
    vueFile(row) {
      let formData = new FormData();
      let requestId = this.items.requestId;
      let fileId = row.file_id;
      formData.append("request_id", requestId);
      formData.append("file_id", fileId);
      formData.append("action", "download");
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/getdatafile",
          formData
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            } else if (response.status === 200) {
              this.PreviewFile(response.data, row.file_name);
            } else {
              this.$notify({
                type: "danger",
                message: "an error occurred while retrieving the file ",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify("an error occurred while deleting the file  ")
            );
          } else {
            swal.fire(JSON.stringify("Error deleted file"));
          }
        });
    },
    deleteFile(row) {
      let formData = new FormData();
      let requestId = this.items.requestId;
      let fileId = row.file_id;
      let fileName = row.file_name;
      formData.append("request_id", requestId);
      formData.append("file_id", fileId);
      formData.append("action", "delete");
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/datafile", formData)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            } else if (response.status === 200) {
              this.setListFile();
              this.get();
              this.getResults();
              this.showModalVariableFiles = false;
              this.$notify({
                type: "success",
                message: "Datafile " + fileName + " was successfully deleted.",
              });
            } else {
              this.$notify({
                type: "danger",
                message: "an error occurred while deleting the file",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify("an error occurred while deleting the file  ")
            );
          } else {
            swal.fire(JSON.stringify("Error deleted file"));
          }
        });
    },
    downloadFile(row) {
      let formData = new FormData();
      let requestId = this.items.requestId;
      let fileId = row.file_id;
      formData.append("request_id", requestId);
      formData.append("file_id", fileId);
      formData.append("action", "download");
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/getdatafile",
          formData
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            } else if (response.status === 200) {
              let fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              );
              let fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", row.file_name);
              document.body.appendChild(fileLink);
              fileLink.click();
            } else {
              this.$notify({
                type: "danger",
                message: "an error occurred while download the file ",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire(
              JSON.stringify("an error occurred while download the file  ")
            );
          } else {
            swal.fire(
              JSON.stringify("an error occurred while download the file")
            );
          }
        });
    },
    PreviewFile(file, name) {
      const CSVToJSON = (csv) => {
        const lines = csv.split("\n");
        const keys = lines[0].split(",");
        return lines.slice(1).map((line) => {
          return line.split(",").reduce((acc, cur, i) => {
            const toAdd = {};
            toAdd[keys[i]] = cur;
            return { ...acc, ...toAdd };
          }, {});
        });
      };
      let tableVar = {};
      CSVToJSON(file).map((item) => {
        Object.entries({ ...item }).map((value) => {
          tableVar[value[0]] = tableVar[value[0]]
            ? tableVar[value[0]] + "," + value[1]
            : value[1];
        });
      });
      this.showModalVariableFiles = false;
      this.showModalPreviewFiles = true;
      this.PreviewFileData.value = Object.entries({ ...tableVar });
      this.PreviewFileData.name = name;
    },
    tableRowClassName({ row, rowIndex }) {
      if (!this.RLCurrentVariables[row[0]])
        if (this.RLCurrentVariables[row[0]] === "datafile") return "itali-row";
    },
    addSalesforceVariable() {
      let data = { ...this.launch_salesforce_variable };
      let requestId = this.items.requestId;
      let formData = {
        variable: data,
        request_id: requestId,
        action: "upload",
      };
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/datafile",
          formData,
          { headers: headers }
        )
        .then((response) => {
          if (response) {
            if (
              response.message.includes("Request failed with status code 500")
            ) {
              this.$notify({
                type: "danger",
                message: "Request failed ",
              });
            } else if (response.status === 200) {
              this.$notify({
                type: "success",
                message: "added successfully",
              });
            } else {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            this.$notify({
              type: "danger",
              message: "an error has occurred",
            });
          } else {
            this.$notify({
              type: "danger",
              message: "an error has occurred",
            });
          }
        });
      this.get();
      this.getResults();
      this.showModalVariableSalesforce = false;
      this.showVariablesModal = true;
      this.showAddVariableModal = false;
      this.salesforceActiveVariable = "";
    },
    getSalesforceVar(row) {
      this.loadingSalesforce = true;
      this.varSalesforce = {};
      let formData = new FormData();
      formData.append("name", row[1].name);
      formData.append("type", row[1].type);
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/salesforce",
          formData
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "Request failed",
              });
              this.loadingSalesforce = false;
            } else if (response.status === 200) {
              this.varSalesforce = response.data.results;
              this.loadingSalesforce = false;
            } else {
              this.$notify({
                type: "danger",
                message: "an error occurred",
              });
              this.loadingSalesforce = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire(JSON.stringify("an error occurred"));
          } else {
            swal.fire(JSON.stringify("an error occurred"));
          }
          this.loadingSalesforce = false;
        });
    },
    salesforceVueVar(row) {
      this.getSalesforceVar(row);
      this.salesforceActiveVariable = row[1].type;
      this.salesforceMethodType = true;
    },
    goBackRunList() {
      this.showEpisodeRun = false;
      this.showRunsList = true;
      this.showStepsEpisode = false;
    },
    goBackEpisodeRun() {
      this.showStepsEpisode = false;
      this.showEpisodeRun = true;
    },
    //#endregion

    //#region async
    async deleteEnhancerRun(elt) {
      const confirmation = await swal.fire({
        title: "Are you sure you want to remove the simulation?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      this.loading = true;
      this.loadingAll = true;
      if (confirmation.value === true) {
        axios
          .delete(
            process.env.VUE_APP_API_URL_PREFIX +
              `/rl/v1/all_results/${this.id}/${elt.id}`
          )
          .then((response) => {
            if (response.status === 200) {
              this.get();
              this.getResults();
              this.getAllResults();
              this.$notify({
                type: "success",
                message: "Successfully delete test run",
              });
            } else {
              this.$notify({
                type: "danger",
                message: "Could not delete test run",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      this.loading = false;
      this.loadingAll = false;
    },
    async openDetailRun(id) {
      this.loading3 = true;
      if (this.idVueRun && this.idVueRun === id) {
        this.idVueRun = null;
        this.showEpisodeRun = false;
        this.showRunsList = true;
      } else {
        this.idVueRun = id;
        this.showEpisodeRun = true;
        this.showRunsList = false;

        const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/all_results/${this.id}/${id}`;
        const res = await axios.get(uri);

        if (res.status === 200) {
          this.detailRuns = res.data.run_data ?? [];
          this.$notify({
            type: "success",
            message: "Successfully retrieved episodes run",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "no episode available for this execution ",
          });
        }
        this.loading3 = false;
      }
    },
    async setListFile() {
      let formData = new FormData();
      let requestId = this.items.requestId;
      formData.append("request_id", requestId);
      formData.append("action", "list");
      const headers = {
        "Content-Type": "application/json",
      };
      await axios
        .post(
          process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/getdatafile",
          formData
        )
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "data file error ",
              });
            } else if (response.status === 200) {
              this.listFile = response.data.datafiles;
            } else {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            swal.fire(JSON.stringify("check the structure of your file "));
          } else {
            swal.fire(JSON.stringify("Error Adding file"));
          }
        });
    },
    async startDownloadingEnhancerReport() {
      let id = this.items.requestId;
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + `/rl/v1/report/${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          window.open(URL.createObjectURL(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async handleCurrentChangeNextPage(val) {
      this.page_num = val;
      await this.getResults();
    },
    async handleRunStepsCurrentChange(val) {
      this.runStepsPagination.currentPage = val;
      await this.vueSteps(this.currentEpisode);
    },
    async getResults() {
      const params = new URLSearchParams();
      this.loading = true;
      params.append("page_num", this.page_num - 1);
      params.append("page_size", this.pagination.perPage);
      params.append("need_total", 1);

      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/steps/${this.id}?${params}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.RLtestcase = res.data.testcase;
        this.total = res.data.total_steps;
        this.pagination.totalPages = res.data.total_pages;
      } else if (
        res.data.message.includes("Input ID field should not be empty.")
      ) {
        this.getResults();
        console.error("Unable to load steps");
      }
      this.filteredResults = this.filteredResultSet;
      this.initializeState();
      this.loading = false;
    },
    async getAllResults() {
      this.loadingAll = true;
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/all_results/${this.id}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.allRuns = res.data.runs_data ?? [];
        this.totalRun = this.allRuns.length;
        this.handleRunCurrentChange(this.runPagination.currentPage);
      } else {
        console.error(res.error);
      }
      this.loadingAll = false;
    },
    async updatedRunResults() {
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/all_results/${this.id}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.allRuns = res.data.runs_data ?? [];

        this.totalRun = this.allRuns.length;
        this.handleRunCurrentChange(this.runPagination.currentPage);
      } else {
        console.error(res.error);
      }
    },
    async vueSteps(elt) {
      const params = new URLSearchParams();

      this.loading3 = true;
      this.loadingSteps = true;
      this.currentEpisode = Object.assign({}, elt);
      params.append("page_num", this.runStepsPagination.currentPage - 1);
      params.append("page_size", this.runStepsPagination.perPage);
      params.append("need_total", 1);

      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/all_results/${this.id}/${this.idVueRun}/${elt.id}?${params}`;
      const res = await axios.get(uri);

      if (res.status === 200) {
        this.defaultSteps = elt.episode_number + 1;
        this.runEpisodeToShow = res.data.episode_steps;
        this.totalRunSteps = res.data.total_steps;
        this.showStepsEpisode = true;
        this.showEpisodeRun = false;
        this.loading3 = false;
        this.loadingSteps = false;
      } else {
        console.error(res.error);
      }
    },
    async startTestEnhancerRun() {
      this.showRunStepsModal = false;
      if (this.RunStepRecommended === true) {
        this.stepsAlgorithmValue = "exploration";
      }
      if (this.stepsAlgorithmValue == null) {
        return this.$notify({
          type: "danger",
          message: "Select Algorithm",
        });
      }
      let formValues = {
        type: this.stepsAlgorithmValue,
        max_episodes: this.maxEpisodesValue,
        pageview_config: {
          key: this.selectProjectKey,
        },
      };
      let reqHeaders = {};
      if (this.runServer == "source") {
        reqHeaders = {
          "X-Cloud-Browser":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_browsers,
          "X-Cloud-Browser-Version": "latest",
          "X-Cloud-Platform-Name":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_platforms,
          "X-Cloud-Username":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_username,
          "X-Cloud-Access-Key":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauceLabs_accesstoken,
          "X-Cloud-Type":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_type,
          "X-Cloud-Region":
            this.integrationSaurceLabs[this.integrationSaurceLabsIndex]
              .sauce_lab_region,
        };
      }
      if (this.runServer == "aws") {
        reqHeaders = Object.assign({}, reqHeaders, {
          "X-Cloud-Browser":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_browsers,
          "X-Cloud-Browser-Version": "latest",
          "X-Cloud-Platform-Name":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_platforms,
          "X-Cloud-Access-Secret-Key":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_secret_key,
          "X-Cloud-Access-Key":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_access_key,
          "X-Cloud-Region":
            this.integrationAws[this.integrationAwsIndex].integration_details
              .aws_region,
          "X-Cloud-Type": "aws",
        });
      }
      if (formValues) {
        this.loadingAll = this.showRuns === true;
        const sendToBackend = true;
        axios.defaults.validateStatus = (status) => {
          return (
            status === 400 || (status >= 200 && status < 300) || status === 500
          );
        };
        if (sendToBackend) {
          const resp = await service.run_test_enhancer({
            id: this.items.requestId,
            parameters: formValues,
            action: "start",
            reqHeaders: reqHeaders,
          });
          if (resp.status === 200) {
            this.newRunId = resp.data.run_id;
            await this.getAllResults();
            this.$notify({
              type: "success",
              message: "Successfully started test run",
            });
            this.runActive = true;
          } else {
            if (
              resp.data.message ===
              "Another run is in progress, cannot run a new one!"
            ) {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: "Another run is in progress, cannot start a new one!",
              });
            } else {
              this.$notify({
                type: "danger",
                duration: 5000,
                message: resp.data.message,
              });
            }
            this.loadingAll = false;
          }
        } else {
          console.log("Debugging values, not sending anything..");
          console.log("formvalues: ", formValues);
        }
      }
      // this.loadingAll = false;
    },
    async endTestEnhancerRun(elt) {
      // this.loading = this.showSetup === true;
      const confirmation = await swal.fire({
        // title: "run with id " + elt.id + " will be stop?",
        title: "Stop run ?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.value === true) {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 300);
        };
        const resp = await service.run_test_enhancer({
          id: elt.id,
          action: "stop",
        });
        if (resp.status === 200) {
          this.$notify({
            type: "success",
            message: "Successfully stop test run",
          });
          this.getAllResults();
        } else {
          this.$notify({
            type: "failure",
            message: "Could not stop test run",
          });
        }
      } else {
        console.log("Debugging values, not sending anything..");
        // console.log("formvalues: ", formValues);
      }
      // this.loadingAll = false;
    },
    async modifyEnhancerRow(action) {
      this.loading = true;
      this.showTestStepModal = false;
      const row_index =
        this.pagination.perPage * (this.pagination.currentPage - 1) +
        this.row_id;
      const eltData = this.$refs["data-rendered"].innerHTML + this.ultraIpt;
      const eltDataXpath =
        this.$refs["data-rendered-xpath"].innerHTML + this.ultraIptXpath;
      this.editedTestStep.element.selector_value = eltDataXpath
        .replace(/<span class="ml-1 u--tag">/g, "${")
        .replace(/<\/span>/g, "}");
      this.editedTestStep.value = eltData
        .replace(/<span class="ml-1 u--tag">/g, "${")
        .replace(/<\/span>/g, "}")
        .replace(/<i class="ml-1 u--tag--i">/g, "")
        .replace(/<i class="ml-1">/g, "")
        .replace(/<\/i>/g, "")
        .replace(/  /g, " ")
        .replace(/{ /g, "{")
        .replace(/ }/g, "}")
        .trim();

      if (!this.editedTestStep.extra_info) {
        this.editedTestStep.extra_info = {};
      }

      // edit similar elements
      if (
        this.editedTestStep.extra_info.similar_elements &&
        this.editedTestStep.extra_info.similar_elements.length > 0
      ) {
        this.editedTestStep.extra_info.similar_elements = this.simItems;
      } else if (
        this.editedTestStep.extra_info.options &&
        this.editedTestStep.extra_info.options.length > 0
      ) {
        this.editedTestStep.extra_info.options = this.simItems;
      }

      this.editedTestStep.new = true;
      if (this.editedIndex < 0) {
        this.RLtestcase.sequence.splice(row_index + 1, 0, this.editedTestStep);
      } else {
        this.RLtestcase.sequence[row_index] = this.editedTestStep;
      }
      this.RLJson.testcase.sequence = this.RLtestcase.sequence;

      const resp = await service.run_test_enhancer({
        id: this.id,
        item: this.editedTestStep,
        action: action,
      });
      if (resp.status === 200 || resp.status === "success") {
        await this.get();
        await this.getResults();
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Successfully " + action + "  test item",
        });
      } else {
        this.$notify({
          type: "danger",
          message: "Could not " + this.editedTestStep.action + " test item",
        });
      }
      this.closeTestStepModal();
    },
    async deleteEnhancerRow(row, id) {
      row.new = true;
      const row_index =
        this.pagination.perPage * (this.pagination.currentPage - 1) + id;
      const confirmation = await swal.fire({
        title: "Delete Test Row " + (row_index + 1) + "?",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      try {
        if (confirmation.value === true) {
          // TODO: check with backend on permissions to delete
          //this.RLtestcase.sequence.splice(row_index, 1);
          this.RLJson.testcase.sequence = this.RLtestcase.sequence;
          const resp = await service.run_test_enhancer({
            id: this.id,
            index: row.index,
            action: "delete",
          });
          // delete this.RLtestcase.sequence[row_index];
          if (resp.status === 200) {
            // this.loading = true;
            this.$notify({
              type: "success",
              message: "Test row deleted successfully",
            });
            this.$emit("delete-step");
            console.log("emit child");
          }
          this.loading = false;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, Cannot delete this step. If problem persist, please contact support",
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onlyCurrentVariables(current_variables, type) {
      if (current_variables) {
        return Object.entries(current_variables)
          .filter((item) => {
            if (item[1] === type) {
              return item;
            }
          })
          .map((item) => {
            return item[0];
          });
      }
    },
    async getAllVariables() {
      this.loadingVariable = true;
      this.toShowLoading = true;
      try {
        const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/variables/${this.id}`;
        const res = await axios.get(uri);
        console.log(res)
        if (res.status === 200) {
          let datafile = res.data.datafile.variables;
          let testcase = res.data.testcase.variables;
          let currentVariables = res.data.current_variables;
          datafile
            ? (this.datafileVariable = datafile)
            : (this.datafileVariable = {});
          const activeDatafileVariable = await this.onlyCurrentVariables(
            currentVariables,
            "datafile"
          );
          const activeTestcaseVariable = await this.onlyCurrentVariables(
            currentVariables,
            "testcase"
          );
          const _v1 = testcase
            ? Object.entries(testcase)
                .filter((v) => {
                  if (activeTestcaseVariable.includes(v[0])) {
                    return v;
                  }
                })
                .map((v) => {
                  let ret = {
                    isFixed: false,
                    source: "synthetic",
                    name: v[0],
                    ...v[1],
                  };

                  return ret;
                })
            : [];
          const _v2 = datafile
            ? Object.entries(datafile)
                .filter((v) => {
                  if (activeDatafileVariable.includes(v[0])) {
                    return v;
                  }
                })
                .map((v) => {
                  let ret = {
                    isFixed: true,
                    name: v[0],
                    ...v[1],
                  };

                  return ret;
                })
            : [];
          const SortArrayVariable = (x, y) => {
            if (x.name < y.name) {
              return -1;
            }
            if (x.name > y.name) {
              return 1;
            }
            return 0;
          };
          this.qlyVariables = _v1.concat(_v2).sort(SortArrayVariable) ?? [];
          console.log(this.qlyVariables)
          this.loadingVariable = false;
          this.toShowLoading = false;
        } else {
          this.loadingVariable = false;
          this.toShowLoading = false;

          console.error(res);
        }
      } catch (error) {
        this.toShowLoading = false;
        console.log(error);
      }
    },
    async addVariable() {
      if (
        this.editedVariable.name === undefined ||
        this.editedVariable.name.length === 0
      ) {
        return this.$notify({
          type: "danger",
          message: "You need to set a variable name",
        });
      }

      this.loadingVariableCreation = true;

      if (this.modalType == "add") {
        const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/variables/${this.id}`;
        const payload = {
          action: "variable_add",
          variable: {
            [this.editedVariable.name]: {
              type: this.editedVariable.type,
              data: this.editedVariable.data,
              status: this.editedVariable.status,
            },
          },
        };

        const res = await axios.post(uri, payload);

        if (res.status === 200) {
          // this.qlyVariables.push(this.editedVariable)
          this.getAllVariables();
          this.$notify({
            type: "success",
            message: `The variable ${this.editedVariable.name} has been created successfully`,
          });
        } else {
          this.$notify({
            type: "danger",
            message: `Unable to create ${this.editedVariable.name} variable, check name / network`,
          });
        }
      } else {
        const _old = this.oldVariable.name;
        const _new = this.editedVariable.name;

        delete this.editedVariable.name;
        delete this.editedVariable.isFixed;
        delete this.oldVariable.name;
        delete this.oldVariable.isFixed;

        const payload = {
          new_variable: { [_new]: this.editedVariable },
          old_variable: { [_old]: this.oldVariable },
          action: "datafile_edit",
        };

        await this.updateVariable(payload, this.editedVariable.name);
      }

      this.editedVariable = Object.assign({}, this.newVariable);
      this.loadingVariableCreation = false;
      this.showVariablesModal = !(this.editedVariableIndex < 0);
      this.showAddVariableModal = false;
    },
    async changeSourceVariable(elt) {
      const _key = elt.name;
      delete elt.name;
      delete elt.isFixed;

      this.oldVariable = Object.assign({}, elt);
      this.editedVariable = {
        ...elt,
        status: elt.status === "active" ? "disabled-source" : "active",
      };

      const payload = {
        new_variable: { [_key]: this.editedVariable },
        old_variable: { [_key]: this.oldVariable },
        action: "datafile_edit",
      };

      await this.updateVariable(payload);
      this.showAddVariableModal = false;
    },
    async updateVariable(payload, name) {
      const uri = `${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/variables/${this.id}`;
      const res = await axios.put(uri, payload);

      if (res.status === 200) {
        this.getAllVariables();
        this.$notify({
          type: "success",
          message: `The variable ${name} has been updated successfully`,
        });
      } else {
        this.$notify({
          type: "danger",
          message: `Unable to update ${name} variable, check name / network`,
        });
      }

      this.editedVariableIndex = -1;
      this.oldVariable = undefined;
    },
    async editStepScreenshotView() {
      console.log("start editing");
      const row_index =
        this.pagination.perPage * (this.pagination.currentPage - 1) +
        this.row_id;

      this.editedTestStep.value = this.$refs["ssDataItem"].value;
      this.editedTestStep.element.selector_value =
        this.$refs["ssPathItem"].value;

      if (
        this.editedTestStep.extra_info.similar_elements &&
        this.editedTestStep.extra_info.similar_elements.length > 0
      ) {
        this.editedTestStep.extra_info.similar_elements = this.simItems;
      } else if (
        this.editedTestStep.extra_info.options &&
        this.editedTestStep.extra_info.options.length > 0
      ) {
        this.editedTestStep.extra_info.options = this.simItems;
      }

      if (this.editedIndex < 0) {
        this.RLtestcase.sequence.splice(row_index + 1, 0, this.editedTestStep);
      } else {
        this.RLtestcase.sequence[row_index] = this.editedTestStep;
      }
      this.RLJson.testcase.sequence = this.RLtestcase.sequence;

      console.log("send request");

      const item = Object.fromEntries(
        [
          "index",
          "current_url",
          "action",
          "element",
          "value",
          "required",
          "extra_info",
        ].map((key) => [key, this.editedTestStep[key]])
      );

      axios
        .put(
          `/interceptor/rl/v1/steps/${this.id}`,
          { item: item },
          { timeout: 2000 }
        )
        .then((res) => {
          console.log("success");
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Successfully edited test item",
          });
        })
        .catch((err) => {
          console.log("fail");
          this.$notify({
            type: "danger",
            message: "Could not edit test item",
          });
        });
    },
    //#endregion

    async initWebsocketStreaming() {
      const auth_token = localStorage.getItem("vue-authenticate.access_token");
      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/runstream/v1`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/runstream/v1`;
      }
      try {
        const socket = new WebSocket(url);
        socket.onopen = function (e) {
          socket.send(`AUTH ${auth_token}`);
        };
        socket.onmessage = (event) => {
          const response = JSON.parse(event.data);
          if (Object.keys(response.events).length === 0) {
            return;
          } else {
            const events = response.events;
            const uniqueValues = [...new Set(Object.values(events))];
            const getDriftSocket = uniqueValues.filter((item) => {
              return item.eventType === "drift run event";
            });
            getDriftSocket[0] ? this.pollSingleRun(getDriftSocket[0]) : "";
            // }
          }
        };
      } catch {
        console.log("error?");
      }
    },
    pollSingleRun(event) {
      if (event.runRequestId == this.$route.params.id) {
        const index = this.runToShow.findIndex(
          (el) => el.id === event.driftRunId
        );
        if (index === -1) {
          if (
            event.driftRunStatus == "IN PROGRESS" &&
            this.newRunId == event.driftRunId
          ) {
            this.runToShow.unshift({
              agent_type: event.driftAgentType,
              created_at: event.eventTimestamp,
              current_episodes: event.driftCurrentEpisode,
              id: event.driftRunId,
              max_episodes: event.driftTotalEpisodes,
              status: event.driftRunStatus,
              total_bugs: event.driftTotalBugs,
            });
            this.newRunId = null;
          }
        } else if (index > -1) {
          if (this.runToShow[index].status == "IN PROGRESS") {
            this.runToShow[index].agent_type = event.driftAgentType;
            this.runToShow[index].created_at = event.eventTimestamp;
            this.runToShow[index].current_episodes = event.driftCurrentEpisode;
            this.runToShow[index].id = event.driftRunId;
            this.runToShow[index].max_episodes = event.driftTotalEpisodes;
            this.runToShow[index].status = event.driftRunStatus;
            this.runToShow[index].total_bugs = event.driftTotalBugs;
          }
        }
      }
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.resultProject = res.data.response;
          this.selectProjectKey = res.data.response[0].apiKey;
        } else {
          this.resultProject = [];
        }
      } catch (error) {
        console.log("No project configured yet");
        // this.$notify({
        //   type: "danger",
        //   duration: 5000,
        //   message: "no project found ",
        // });
      }
    },
  },
  computed: {
    filteredResultSet() {
      const seq = this.RLtestcase && this.RLtestcase.sequence;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.query) {
              return item.description
                .toLowerCase()
                .includes(this.query.toLowerCase());
            } else {
              return item;
            }
          })
        : [];
    },
    canAddData() {
      return this.allDatas.filter((elt) => elt.value.length === 0).length > 0;
    },
    canAddEnhancer() {
      let elementPath = this.ultraIptXpath;
      if (this.$refs["data-rendered-xpath"]) {
        elementPath = this.$refs["data-rendered-xpath"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "${")
          .replace(/<\/span>/g, "}");
      }
      if (this.editedTestStep.action === "VERIFY_PAGE_CONTAINS") {
        const emptyValue = this.editedTestStep.current_url.trim().length > 0;
        return emptyValue;
      } else {
        const emptyValue =
          elementPath.trim().length > 0 &&
          this.editedTestStep.current_url.trim().length > 0;
        return emptyValue;
      }
    },
    canAddFile() {
      const emptyValue = this.file.length > 0;
      return emptyValue;
    },
    canAddVariableSalesforce() {
      const emptyValue =
        this.launch_salesforce_variable[0].name.length > 0 &&
        this.launch_salesforce_variable[0].value.length > 0;
      return emptyValue;
    },
    simItemsWithoutDisabled() {
      return this.simItems.filter(
        (item) => (item.isEnabled ?? true) && (item.isDisplayed ?? true)
      );
    },
    simItemsOnlyActive() {
      console.log("FILTER")
      return this.simItemsWithoutDisabled.filter((item) => item.active ?? true);
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.setSSDimLoad);
    this.ro.observe(this.$refs.ss_img);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.ss_img);
  },
};
</script>

<style lang="scss" >
.custom-header-names {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 20px 0 20px 0;
}
.container-advance-expander{
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}
.container-advance-expander .el-radio{
  
  display: flex;
}
.container-advance-expander .el-radio h4{
  margin-bottom: 0;
}
.container-advance-expander .el-radio__input{
  margin-top: 3px;
}
.custom-header {
  padding-left: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.fl--elt_container {
  display: flex;
  flex-direction: column;
  gap:20px;
  .fl--col {
    display: flex;
    flex-direction: column;
  }

  .fl--end {
    display: flex;
    justify-content: flex-end;
  }
}

.ultra--ipt {
  border-radius: 10px;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    color: #7d7d7d;
    height: 40px;
    outline: none;
    border: none;
    background: #cccccc;
  }

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#5e72e4, 0.75);
      background: rgba(#5e72e4, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    min-width: 330px;
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}
.var-table-modal{
  overflow-y:scroll;
  height: 50vh;
}
#var-modal .modal-body{
  overflow: hidden;
}
.add-var-modal,
.testEnhancer-run-modal {
  width: 100%;
}
.testEnhancer-run-modal p {
  margin-bottom: 10px;
  color:var(--main-blue-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.text-algorithm{
  color: var(--main-blue-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 10px;
}
.variable-modal .modal-body{
  height: 20vh;
}

.testEnhancer-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tic {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}
.custom-placeholder ::placeholder {
  color: var(--main-blue-color) !important;
}

.three-input input,
.three-input select {
  max-width: 165px;
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}
.custom-run-ex-buttons{
  display: flex;

}
.right-btn{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}
.left-btn{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 10px;
}
.custom-run-ex-buttons button{
  width: 100%;
  text-align: center;
  gap: 0;
}
.custom-el-input-number .el-input input {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0;
  background-color: #DBDBDB;
  color: #6E6E6E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  text-align: left;
}
.el-select .el-input .el-input__inner:focus{
  border: 1px solid #CCC;
  border-color:#CCC !important;

}

.testEnhancer-inputs input,
  
.testEnhancer-inputs select {
  color:var(--main-blue-color) !important;
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}
.enhancer > .enhancer-content {
  .el-select {
    height: 100% !important;
    width: 100%;
  }
}

.el-input input {
  height: 40px !important;
  width: 330px;
}

.six-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.six-input input,
.six-input select {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.sic {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sic:nth-child(2) {
  padding: 0 5px;
}

.sic:nth-child(3),
.sic:nth-child(4),
.sic:nth-child(5) {
  padding-right: 5px;
}

.bug-count-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #5e72e4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.total-steps-in-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.total-steps-dot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  overflow: hidden;
  width: 70px;
  height: 30px;
}
.text-generate{
  color: var(--main-blue-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.s-dots {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
}

.pulse {
  background-color: rgb(242, 179, 72);
  animation: pulse-animation 2s infinite;
}

.tesc {
  padding: 4px;
}
.modal-body-container-screen{
  gap:30px;
  padding: 0 24px 0 24px;
  justify-content: space-between;
  width: 100%;
  display: flex;
}
.custom-alert-tag-syn{
  background-color: #787878 !important;
  border-color: #787878 !important;
  text-transform: capitalize;
}
.test-enhancer-select-rows .el-input {
  height: 52px;
  width: 100px;
  padding: 3px;
}
.el-radio__label {
  color: var(--main-blue-color) !important;
}
.table-header-gradient {
  background-image: linear-gradient(to right, #f56646, #fbb140) !important;
}

.tsin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 55vw;
    margin: 1.75rem auto;
  }
}

@media (min-width: 576px) {
  .test-step-modal .modal-dialog {
    // max-width: 90vw;
    margin: 1.75rem auto;
  }

  .testEnhancer-run-modal-container .modal-dialog {
    max-width: 40vw !important;
    margin: 1.75rem auto;
  }

  .testEnhancer-run-modal-container-variable .modal-dialog {
    max-width: 65vw !important;
    margin: 1.75rem auto;
  }
}

.btn-exp {
  float: right;
  margin-bottom: 5px;
}

#maxEpisodesValue {
  height: 34px;
}

.el-table .itali-row {
  font-style: italic !important;

  td {
    color: #d3d1d1 !important;

    i {
      color: #d3d1d1 !important;
    }
  }
}

.modal-body {
  height: 60vh;
  overflow-y: scroll;
  padding-top: 0 !important;
}

.btn-lock {
  color: #5e72e494 !important;
}

.color-bleu {
  color: #5e72e4 !important;
}

.m-unlock-var {
  background: #5e72e494;
  color: #fff;
  padding: 0 3px;
  margin-left: 3px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 9px;
}
.big-input input {
  width: 100%;
}

.m-lock-var {
  background: #5e72e4;
  color: #fff;
  padding: 0 3px;
  margin-left: 3px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 9px;
}

.check-algorim {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: solid 1px rgba(#ced4da, 0.4);
  height: 7rem;
  padding: 1rem;
  text-align: center;
  align-items: center;
  cursor: pointer;

  h4 {
    color: #5e72e4;
    text-transform: capitalize;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
    font-size: 0.8em;
    font-style: italic;
    font-weight: 500;
    word-break: break-word;
  }
}

.check-algorim:hover,
.algorim-select {
  border-color: #5e72e4;
  border-bottom: solid #5e72e4 5px;
  background: rgba(#5e72e4, 0.13);
}

.btn--impot--var {
  float: right;
  margin-bottom: 10px;
}

.view--run--details {
  padding: 0px 18% 0px 18%;
  border-radius: 5px;
  box-shadow: 1px 1px 12px rgb(206, 206, 206);
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;

  .close--detail--run {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 1px;
    width: 40px;
  }

  .view--steps--details {
    padding: 10px 5px 0px 5px;
    margin: 0px 5% 0px 5%;
    width: 90%;
    max-height: 30vh;
    border-top: solid 2px #5e72e4;
    display: flex;

    div {
      width: 49.8%;
      min-height: 100%;
      padding: 0px 15px 0px 15px;
    }

    span {
      border: solid 1px #5e72e4;
      min-height: 200px;
      margin-bottom: 37px;
    }
  }
}

.test-time-v-color-red {
  border: solid 1px #ff0000 !important;
  background: #f9ebeb !important;
  color: #ff0000 !important;
}

.step-header {
  border-radius: 5px;
  background: #5e72e4;
  width: 100%;
  height: 4vh;
  padding: 5px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;

  h5 {
    color: #ffff;
  }
}

@media (max-width: 535px) {
  .adpt--settings {
    margin-left: 10px;
  }

  .adpt-mid--page {
    align-items: stretch;
  }
}

.list-step {
  display: flex;
  list-style: none;
  margin-top: 10px;

  li {
    padding: 5px 7px;
    margin-right: 3px;
    color: #409eff;
    background: #ecf5ff;
    border: solid 1px #b3d8ff;
  }
}

.elt--content--step {
  display: flex;
  width: 100%;
  margin: 10px;
  margin-bottom: 0;
  align-items: center;

  .step--number {
    border-radius: 50%;
    margin-right: 15px;
    font-size: 1.2em;
  }
}

.error--steps {
  display: flex;
  border-top: solid 2px #409eff;

  div {
    width: 50%;
    margin-top: 5px;

    h6 {
      text-align: center;
      color: #409eff;
    }

    .error {
      color: red;
    }

    p {
      font-size: 10px;
      padding: 3px;
    }
  }
}

.breadcrumb--run {
  display: flex;
  font-size: 1.3em;

  span {
    display: flex;
    align-items: center;
    color: #53568a !important;

    i {
      font-size: 0.5em !important;
    }

    p {
      margin: 0;
      font-size: 0.6em !important;
    }
  }
}

.test-time-cont {
  button {
    padding: 0.8em !important;
  }
}

.over--screenshot {
  position: fixed;
  padding: 10px;
  background-color: #2020204d;
  border-radius: 5px;
  width: 55%;
  top: 1vh;
  right: 20%;

  img {
    width: 100%;
    border-radius: 5px;
  }
}
.el-form-item-tall .el-form-item__content {
  width: 100%;
}
.el-form-item-tall {
  width: 100%;
}
.add-var-modal {
  .el-select__tags {
    height: 90% !important;
    overflow: auto !important;
    max-width: none !important;
  }
}

.flex--box {
  display: inline-flex;
}

.cell {
  overflow: auto !important;
  max-height: 200px;
}

.var--tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  border-radius: 3px;
  padding: 3px;
  transition: 1s;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.ultra--ipt--xpath {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 50px;
  width: 100%;
  align-items: center;
  overflow: hidden;

  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 90%;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#5e72e4, 0.75);
      background: rgba(#5e72e4, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn--xptah {
    float: right;
    text-align: center;
  }

  .ultra--ipt_content--xphat {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#5e72e4, 0.75);
      background: rgba(#5e72e4, 0.15);
      border-radius: 0.25rem;
    }

    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ultra--ipt_ipt {
    width: 100%;
  }

  .content--ultra {
    max-width: 75%;
  }

  .btn--clean {
    max-width: 22%;
  }
}

.ultra--ipt--content {
  float: left;
  display: block;
  width: 100%;
  padding: 10px;

  input {
    border: none;
    width: 100%;
  }

  input:focus {
    outline: none;
  }
}

.trsl--img {
  img {
    max-width: 100%;
    display: inline-block;
    border-radius: 0.3rem;
    cursor: zoom-in;
  }
}
.img-container-expander{
  border-radius: 10px;
  border: 1px solid var(--main-blue-color);
}
.video-modal-container .modal-dialog-centered {
  max-width: 1300px !important;
  width: 100% !important;
  padding: 0 !important;
}

.video-modal-container .modal-dialog .modal-content {
  height: 610px;
  text-align: center !important;
  margin: 0 auto !important;
  width: 90%;
}
.video-modal-container .modal-dialog .modal-content .modal-body{
  overflow:hidden;
}
.video-modal-container .modal-dialog .modal-content .modal-header .close span {
  font-size: 3rem !important;
}
.button-pag-word{
  background-color: white !important;
  border-color: white !important;
}
.screen-btns-pag{
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.video-modal-container .modal-dialog .modal-body {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.p--img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.ss-row > * {
  margin-bottom: 16px;
}

.ss-row * {
  line-height: inherit;
}

.ss-list {
  height: 100% !important;
  max-height: 304px;

  .tbl--body {
    overflow: hidden;
    display: block;
  }
}

// .ss-container{
//   display:inline-block;
//   width:100%;
//   position:relative;
//   img {
//     width: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index:1;
//   }

// }

.el-menu--collapse {
  width: 0% !important;
  overflow: auto;
  padding: 0 !important;
}
.xPath-input{
  width: 100%;
  height: 40px;
  background-color: #CCC;
  color:#7d7d7d;
  border-radius: 10px;
  padding-left: 10px;
}

.el-menu {
  width: 20%;
  padding: 1rem 0rem 1rem 1rem;
  border: none;
}
.tagged--ipt{
  border:1px solid #CCC !important;
  background-color: #CCC !important;
  height: 40px !important;
    width: 330px;
    border-radius: 10px;
}
.explore-form-cont input{
  border:1px solid #CCC !important;
  background-color: #CCC !important;
  height: 40px !important;
    width: 330px;
}
.explore-form-cont{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.explore-form-cont .el-form-item__label{
  margin-bottom: 0;
  line-height: 30px;
}
.switch-explore-container h3{
  margin-bottom: 0;
}
.switch-explore-container{
  align-items: center;
  gap:10px;
}
.explore-switch span{
  width:60px !important;
  height:30px;
  border-radius: 34px;
} 
.explore-switch .el-switch__core::after{
  width:26px;
  height:26px;
}
.explore-switch .el-switch.is-checked .el-switch__core::after{
  margin-left: -27px;
}
.explore-switch {
  justify-content: left;
  align-items: center;

  .el-switch__label {
    color: var(--main-blue-color);
  }

  .el-switch__label.is-active {
    color: black;
  }
}

.el-switch + .el-button {
  margin-left: 10px;
}

// #ssModal {
//   .modal-header {
//     display: flex;
//     justify-content: flex-end;
//     gap:10px;

//     .close svg {
//       margin-bottom: 5px !important;
//       margin: 0;
//     }
//   }
// }
#ssModal{
  .modal-body {
    display: block !important;
    padding: 0;
  }
}

.small-spaces {
  .el-form-item__label {
    font-weight: 700;
    font-size: 0.9rem;
  }

  .el-form-item {
    margin-bottom: 10px;

    &.no-space {
      margin: 0;

      .el-form-item__content {
        line-height: 0;

        label {
          font-weight: 600;
        }
      }
    }
  }
}

.ss-actions {

}

.th--label {
  text-transform: uppercase;
}
.explore-form-cont .el-input__suffix-inner{
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>
<style scoped>
.modal-header{
  margin-top: 0px !important;
}
</style>