<template>
    <!-- loading -->
    <div v-if="isLoading">
              <img
                  src="/img/loading.gif"
                  alt="loading..."
                  style="height: 100px; width: 100px"
                />
            </div>
    <!-- loading -->
    <!-- header -->
    <div v-else-if="!isLoading && dataView !='card' && toShow.length > 0">
        <div :class="[dataView == 'thumb' ? 'header-thumb' : 'header-list']" class="custom-header-rows">
                <div v-if="dataView=='thumb'" class="elt-row max-width" >
                    <h3>SCREENSHOT</h3>
                </div>
                <el-tooltip content="Click to sort by Name">
                    <div class="elt-row max-width pointer">
                    <h3>Name</h3>
                    <div @click="sortList('runName')" :class="sortedBy == 'runName' ? 'enable-sort' : 'disable-sort'">
                      <i  :class="isReversed['runName'] ? 'arrow-sort-up' : 'arrow-sort-down'"></i>
                    </div>
                </div>
                </el-tooltip>
                <el-tooltip content="Click to sort by Tags">
                    <div  class="elt-row max-width pointer" >
                    <h3>Tags</h3>
                    <div @click="sortList('runTags')" :class="sortedBy == 'runTags' ? 'enable-sort' : 'disable-sort'">
                      <i  :class="isReversed['runTags'] ? 'arrow-sort-up' : 'arrow-sort-down'"></i>
                    </div>
                </div>
                </el-tooltip>
                <el-tooltip content="Click to sort by Status">
                    <div  class="elt-row max-width pointer" >
                    <h3>Status</h3>
                    <div @click="sortList('status')" :class="sortedBy == 'status' ? 'enable-sort' : 'disable-sort'">
                      <i  :class="isReversed['status'] ? 'arrow-sort-up' : 'arrow-sort-down'"></i>
                    </div>
                </div>
                </el-tooltip>
                <el-tooltip content="Click to sort by Date">
                    <div  class="elt-row max-width pointer" >
                    <h3>Created</h3>
                    <div @click="sortList('acceptedAt')" :class="sortedBy == 'acceptedAt' ? 'enable-sort' : 'disable-sort'">
                      <i  :class="isReversed['acceptedAt'] ? 'arrow-sort-up' : 'arrow-sort-down'"></i>
                    </div>
                </div>
                </el-tooltip>
                <el-tooltip content="Click to sort by Steps">
                    <div  class="elt-row max-width pointer" style="white-space: nowrap;padding-left: 0 !important;">
                    <h3>Steps</h3>
                    <div @click="sortList('stepCount')" :class="sortedBy == 'stepCount' ? 'enable-sort' : 'disable-sort'">
                      <i  :class="isReversed['stepCount'] ? 'arrow-sort-up' : 'arrow-sort-down'"></i>
                    </div>
                </div>
                </el-tooltip>
                
                <div class="elt-row max-width" >
                </div>
        </div>
        <!-- header -->
        <!-- body for thumb and list view -->
        <div class="tbl--body table-body-scroll-tests mb-3">
                <template v-if="toShow.length > 0">
                    <div
                    v-for="elt in sortedToShow"
                    :key="elt.id"
                    class="tblb--row mb-2"
                    :class="elt.status === 'processing' && 'tblb--processing'"
                    @click="$parent._onRowClicked(elt)"
                    >
                    <div v-if="elt.status === 'processing'" class="tblb--over b">
                        <div class="tblbo--img" v-if="elt.thumbnail">
                        <img
                            :src="`data:image/png;base64,${elt.thumbnail}`"
                            alt="screenshot of current step"
                        />
                        </div>
                        <div class=" fl--center">
                        <span class="s--icon mr-4"
                            ><i class="el-icon-loading"></i
                        ></span>
                        <span class="s--name">{{ elt.runName }}</span>
                        </div>
                        <div class="tblo--step">
                        <span class="s--txt">
                            Processing Step:
                            <u class="h2">{{ elt.xpathCount }}</u>
                        </span>
                        </div>
                        <el-button
                        type="danger"
                        @click.stop="$parent.cancelRunItem(elt)"
                        plain
                        size="small"
                        class="cancel_button"
                        >
                        Cancel
                        
                        </el-button>
                    </div>
                    <div class="tblb--inner"  :class="[dataView == 'thumb' ? 'body-thumb' : 'body-list']">
                        <div
                        v-if="dataView == 'thumb'"
                        class="elt-row max-width"
                        
                        >
                        <img
                            v-if="elt.thumbnail"
                            :src="`data:image/png;base64,${elt.thumbnail}`"
                            alt="screenshot of current step"
                            class="elt--banner_img"
                        />
                        <img
                            v-else-if="elt.thumbnail !== null"
                            src="/img/loading.gif"
                            alt="loading..."
                            style="height: 100px; width: 100px"
                        />
                        <div v-else class="mt-2">
                            <i class="el-icon-warning ml-2"></i>
                            <span class="elt--banner_not_img ml-1">{{
                            $t("list_item_page.screenshot_not_availabe")
                            }}</span>
                        </div>
                        </div>
                        <div
                        class="elt-row max-width"
                        >
                        <span class="test-run-name">{{ elt.runName }}</span
                        ><br />
                        <span
                            v-if="elt.status === 'passed'"
                            class="test-time-cont"
                        >
                            <i class="far fa-clock mr-1"></i>
                            {{ $parent.secondsToRealTime(elt) }}
                        </span>
                        </div>
                        <div
                        class="elt-row max-width"
                        >
                        <span>
                            {{ elt.runDesc }}
                        </span>

                        <span v-if="elt.runTags" class="run-tags-cont">
                            <span
                            class="run-tags"
                            v-for="(tags, index) in elt.runTags"
                            :key="index"
                            >
                            {{ tags }}
                            </span>
                        </span>
                        </div>
                        <div
                        class="elt-row max-width"
                        >
                        <el-alert
                        style="display:flex"
                            class="no--close"
                            :title="elt.status"
                            :type="
                            elt.status === 'passed'
                                ? 'success'
                                : elt.status === 'failed'
                                ? 'error'
                                : 'danger'
                            "
                            show-icon
                        ></el-alert>
                        </div>
                        <div
                        class="elt-row max-width"
                        >
                        <span class="elt--txt text-date">
                            {{ $parent.formatDate(elt.acceptedAt) }}
                        </span>
                        </div>
                        <div
                        class="elt-row max-width"
                        >
                        <span  class="elt--value">
                            {{ elt.stepCount }}
                        </span>
                        </div>
                        <div
                        class="elt-row max-width"
                        style="display:flex;gap:5px;justify-content: center;"
                        >
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="Edit this test"
                            placement="top"
                        >
                            <el-button
                            type="primary"
                            icon="el-icon-edit"
                            @click.stop="$parent.editTestModal(elt)"
                            circle
                            plain
                            size="small"
                            ></el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="light"
                            content="Rerun this test"
                            placement="top"
                        >
                            <el-button
                            type="success"
                            icon="el-icon-refresh"
                            @click.stop="$parent.rerunItemModal(elt)"
                            circle
                            plain
                            size="small"
                            ></el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="light"
                            content="Delete this test"
                            placement="top"
                        >
                            <el-button
                            type="danger"
                            icon="el-icon-delete"
                            @click.stop="$parent.deleteItem(elt)"
                            circle
                            plain
                            size="small"
                            ></el-button>
                        </el-tooltip>
                        </div>
                    </div>
                    </div>
                </template>
                <div class="tblb--row mb-2 no-items-found" v-else>
                    {{ $t("list_item_page.no_items_found") }}
                </div>
                </div>
                <!-- body -->
                
</div>
            <!-- body for card view -->
            <div v-else-if="toShow.length > 0" style="height:54vh !important" class="ctb--body table-body-scroll adpt-ctb--card mb-3">
              <template v-if="toShow.length > 0">
                <div
                  v-for="elt in sortedToShow"
                  :key="elt.id"
                  class="ctbb--row mb-2"
                  :class="elt.status === 'processing' && 'ctbb--processing'"
                  @click="$parent._onRowClicked(elt)"
                >
                  <div v-if="elt.status === 'processing'" class="ctbb--over">
                    <img
                      v-if="elt.thumbnail"
                      :src="`data:image/png;base64,${elt.thumbnail}`"
                      alt="screenshot of current step"
                    />
                    <img
                      v-else
                      src="/img/loading.gif"
                      alt="loading..."
                      style="height: 100px; width: 100px"
                    />
                    <span class="s--icon"><i class="el-icon-loading"></i></span>
                    <span class="s--name">{{ elt.runName }}</span>
                    <span class="s--txt">
                      Processing Step:
                      <u class="h2">{{ elt.stepCount }}</u>
                    </span>
                    <el-button
                      type="danger"
                      @click.stop="$parent.cancelRunItem(elt)"
                      plain
                      size="small"
                      class="cancel_button"
                    >
                      Cancel
                      
                    </el-button>
                  </div>
                  <div class="ctbb--inner">
                    <div class="elt--banner" v-if="elt.thumbnail">
                      <img
                        :src="`data:image/png;base64,${elt.thumbnail}`"
                        alt="screenshot of current step"
                        class="elt--banner_img"
                      />
                      <div class="elt--banner_overlay"></div>
                      <el-alert
                        class="no--close elt--banner_overlay_status"
                        show-icon
                        :title="elt.status"
                        :type="
                          elt.status === 'passed'
                            ? 'success'
                            : elt.status === 'failed'
                            ? 'error'
                            : 'danger'
                        "
                      ></el-alert>
                      <span
                        v-if="elt.status === 'passed'"
                        class="elt--banner_overlay_duration"
                      >
                        <i class="far fa-clock mr-1"></i>
                        {{ $parent.secondsToRealTime(elt) }}
                      </span>
                      <span class="elt--banner_overlay_created text-date" style="color:white !important;">
                        {{ $parent.formatDate(elt.acceptedAt) }}
                      </span>
                    </div>
                    <div v-else-if="elt.thumbnail !== null">
                      <img
                        src="/img/loading.gif"
                        alt="loading..."
                        style="height: 100px; width: 100px"
                        v-if="elt.status !== 'processing'"
                      />
                    </div>
                    <div v-else class="mt-5">
                      <i class="el-icon-warning ml-2"></i>
                      <span class="elt--banner_not_img ml-1">{{
                        $t("list_item_page.screenshot_not_availabe")
                      }}</span>
                    </div>
                    <div class="elt--content">
                      <span style="height:100px" class="test-run-name"> {{ elt.runName }} </span>
                      <span class="test-desc my-1">
                        {{ elt.runDesc }}
                      </span>

                      <span v-if="elt.runTags" class="run-tags-cont">
                        <span
                          class="run-tags"
                          v-for="(tags, index) in elt.runTags"
                          :key="index"
                        >
                          {{ tags }}
                        </span>
                      </span>
                      <span class="elt--value">
                        {{ elt.stepCount }}
                        <em>
                          <small>
                            <u> Total Steps </u>
                          </small>
                        </em>
                      </span>
                      <el-divider></el-divider>
                      <div style="display:flex;gap:10px;">
                        <el-tooltip
                          class="item"
                          effect="light"
                          content="Edit this test"
                          placement="top"
                        >
                          <el-button
                            type="primary"
                            icon="el-icon-edit"
                            @click.stop="$parent.editTestModal(elt)"
                            rounded
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>

                        <el-tooltip
                          class="item"
                          effect="light"
                          content="Rerun this test"
                          placement="top"
                        >
                          <el-button
                            type="success"
                            icon="el-icon-refresh"
                            @click.stop="$parent.rerunItemModal(elt)"
                            rounded
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>

                        <el-tooltip
                          class="item"
                          effect="light"
                          content="Delete this test"
                          placement="top"
                        >
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            @click.stop="$parent.deleteItem(elt)"
                            rounded
                            plain
                            size="small"
                          ></el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div  class="ctbb--row mb-2 no-items-found" v-else>
                {{ $t("list_item_page.no_items_found") }}
              </div>
            </div>
            <!-- No Data -->
            <div class="no-items-container" v-else>
            {{ $t("list_item_page.no_items_found") }}
          </div>
</template>
<script>
export default {
    data(){
        return {
            isReversed : 
              {"runName" : false,
              "runTags" : false,
              "status" : false,
              "acceptedAt" : false,
              "stepCount" : false}
            ,
            sortedBy : 'runName',
        }
    },
    props: {
        dataView:{
            type: String,
        },
    toShow:{
        type: Array
    },
    isLoading:{
        type: Boolean
    }
    },
    methods:{
      sortList(name){
        this.sortedBy = name
        this.isReversed[this.sortedBy] = !this.isReversed[this.sortedBy]
      }
    },
    computed:{
        sortedToShow(){
      let sortedList = this.toShow.slice()
      if(['acceptedAt','stepCount'].includes(this.sortedBy)){
        //for numbers
        sortedList.sort((a,b) => 
      a[this.sortedBy] - b[this.sortedBy]
      )
      }else if(this.sortedBy == 'runTags'){
        //for lists
        sortedList.sort((a,b) => 
        a[this.sortedBy].length - b[this.sortedBy].length)
      }
      else{
      //for strings
      sortedList.sort((a,b) => a[this.sortedBy].localeCompare(b[this.sortedBy]))
    }
    if(this.isReversed[this.sortedBy])
      return sortedList.reverse()
    return sortedList
    },
    }
    
}
</script>
<style>
.disable-sort{
  padding-left: 5px;
  i{
    opacity: 0.5;
  }
}
.enable-sort{
  padding-left: 5px;
}
.header-thumb{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 100px 1fr 50px 1fr;
    text-align: center;
}
.header-list{
    display: grid !important;
    grid-template-columns:1fr 1fr 100px 1fr 50px 1fr;
    text-align: center;
}
.body-thumb{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 100px 1fr 50px 1fr;
    text-align: center;
}
.body-list{
    display: grid !important;
    grid-template-columns:1fr 1fr 100px 1fr 50px 1fr;
    text-align: center;
}
.custom-header-rows{
  user-select: none;
}
</style>