<template>
  <div>
    <div class=" row">
      <div class="col-lg-12 ovw-table">
        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-dark-new"
          emptyText="No Data"
          :border="true"
          :data="[getSuiteOverview()]"
        >
          <el-table-column
            label="Test Status"
            min-width="16%"
            prop="status"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="Run Time"
            min-width="16%"
            :formatter="formatColDate"
            align="center"
          >
          </el-table-column>

          <el-table-column
            label="Failures"
            min-width="16%"
            align="center"
            :formatter="failedPaths"
          >
          </el-table-column>
          <el-table-column
            label="Suggestions"
            min-width="16%"
            align="center"
            :formatter="sumOfTestSuiteSuggestions"
          >
          </el-table-column>
          <el-table-column
            label="Auto Healed"
            min-width="16%"
            align="center"
            :formatter="sumOfHealedSelectorCount"
          >
          </el-table-column>
          <el-table-column
            label="Try Time Saved"
            min-width="16%"
            :formatter="convertRunTimeToTimeSaveMS"
            align="center"
          >
          </el-table-column>
        </el-table>
        <!-- {{ log("totalxPath", totalxPath) }} -->
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-6">
        <v-chart
          class="chart"
          :autoresize="true"
          :loading="chartLoading"
          :option="overviewPieChart"
        />
      </div>
      <div class="col-lg-6">
        <v-chart
          class="chart"
          :autoresize="true"
          :loading="chartLoading"
          :option="overviewBarChart"
        />
      </div>
    </div>
    <!-- <div class="row mt-2">
      <div class="col-lg-12">
        <h2>Test Run Options</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="test-run-left">
          <div class="test-run-row">
            <p>Fast-heal mode</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions &&
                items.runOptions.use_fast_heal === false &&
                "disabled"
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Interceptor failure handling</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions && items.runOptions.handle_failure
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Collect page-interaction events</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions && items.runOptions.use_image_comparison
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Test run origin</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions && items.runOptions.test_run_origin
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Element neighbor max distance (px)</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions && items.runOptions.neighbor_maxdist
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Test step wait time (seconds)</p>
            &nbsp;:&nbsp;x
            <span>{{
              items.runOptions && items.runOptions.runner_step_wait
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="test-run-right">
          <div class="test-run-row">
            <p>Interceptor screenshots</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions &&
                items.runOptions.take_screenshots === true &&
                "enabled"
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Browser window size</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions && items.runOptions.runner_resolution
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Ignore disabled elements</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions &&
                items.runOptions.filter_disabled_elements === true &&
                "enabled"
            }}</span>
          </div>
          <div class="test-run-row">
            <p>Ignore invisible elements</p>
            &nbsp;:&nbsp;
            <span>{{
              items.runOptions &&
                items.runOptions.filter_invisible_elements === true &&
                "enabled"
            }}</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "SuiteOverview",
  props: {
    items: {
      type: Object,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VChart,
  },

  data() {
    return {
      log: console.log,
      totalxPath: "",
      totalHealedSelectorCount: "",
      totalTrainedSelectorCount: "",
      totalSuggestedSelectorCount: "",
      suiteStartTime: "",
      suiteFinishedTime: "",
      totalAcceptedAtmilisec: "",
      totalFinishedAtmilisec: "",
      chartLoading: true,
      overviewPieChart: {
        title: {
          text: "Time saved",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["Enhancements", "Auto Heal"],
        },
        series: [
          {
            name: "Enhancement vs Heal",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 0,
                name: "Enhancements",
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 0,
                name: "Auto Heal",
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      overviewBarChart: {
        title: {
          text: "Before & After Wring",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ",
        },
        xAxis: {
          type: "category",
          data: ["#Success", "#Failures", "#Heals"],
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            name: "Before Wring",
            data: [
              {
                value: 4,
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#FCA311",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
          {
            type: "bar",
            name: "After Wring",
            data: [
              {
                value: 3,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
              {
                value: 0,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
    };
  },

  methods: {
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    convertRunTimeToTimeSaveMS() {
      let actualRunTime = Number(
        this.totalFinishedAtmilisec - this.totalAcceptedAtmilisec
      );
      let healedCount = Number(this.totalHealedSelectorCount);
      let suggestedCount = Number(this.totalSuggestedSelectorCount);
      let trainCount = Number(this.totalTrainedSelectorCount);
      let x = suggestedCount + trainCount;
      const totalHealSecs = healedCount * 3600;
      const totalSuggestedSecs = x * 300;
      let z = actualRunTime + totalHealSecs + totalSuggestedSecs;
      const time = this.hhmmss(z);
      // console.log(actualRunTime);
      return time;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    failedPaths() {
      let healedCount = Number(this.totalHealedSelectorCount);
      let xpathCount = Number(this.totalxPath);
      let trainCount = Number(this.totalTrainedSelectorCount);
      var total = xpathCount - trainCount - healedCount;
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    sumOfTestSuiteSuggestions() {
      return this.totalxPath;
    },
    sumOfHealedSelectorCount() {
      return this.totalHealedSelectorCount;
    },
    getSuiteOverview() {
      // console.log("overview", this.items.testRunDetails);
      let xPathSum = [];
      let healedSelectorCountSum = [];
      let trainedSelectorCountSum = [];
      let suggestedSelectorCount = [];
      let acceptedAt = [];
      let finisedAt = [];
      let suiteStartTime = "";
      let suiteFinishedTime = "";
      if (this.items) {
        Object.values(this.items.testRunDetails).filter((item) => {
          xPathSum.push(item.xpathCount);
          healedSelectorCountSum.push(item.healedSelectorCount);
          trainedSelectorCountSum.push(item.trainedSelectorCount);
          suggestedSelectorCount.push(item.suggestedSelectorCount);
          acceptedAt.push(item.acceptedAt);
          finisedAt.push(item.finishedAt);
          suiteStartTime = item.testSuiteRunStartedAt;
          suiteFinishedTime = item.testSuiteRunFinishedAt;
        });
        this.totalxPath = xPathSum.reduce((a, b) => a + b, 0);
        this.totalHealedSelectorCount = healedSelectorCountSum.reduce(
          (a, b) => a + b,
          0
        );
        this.totalTrainedSelectorCount = trainedSelectorCountSum.reduce(
          (a, b) => a + b,
          0
        );
        this.totalSuggestedSelectorCount = suggestedSelectorCount.reduce(
          (a, b) => a + b,
          0
        );
        this.totalAcceptedAtmilisec = acceptedAt.reduce((a, b) => a + b, 0);
        this.totalFinishedAtmilisec = finisedAt.reduce((a, b) => a + b, 0);
        this.suiteStartTime = suiteStartTime;
        this.suiteFinishedTime = suiteFinishedTime;

        let resData = this.item;
        this.overviewBarChart.series[0].data[0].value = this.totalTrainedSelectorCount;
        this.overviewBarChart.series[1].data[0].value = this.totalxPath;
        var trained = this.totalTrainedSelectorCount;
        if (trained > this.totalxPath) {
          trained = this.totalxPath;
        }
        this.overviewPieChart.series[0].data[0].value = trained;
        this.overviewPieChart.series[0].data[1].value =
          this.totalxPath - this.items.trainedSelectorCount;
        if (this.totalTrainedSelectorCount || this.totalxPath) {
          this.chartLoading = false;
        }
        return this.items;
      } else return [];
    },
    formatColDate() {
      // const time = moment(value * 1000).format("HH mm");
      // if (time != "Invalid date") {
      //   return time;
      // }

      var startTime = moment(this.suiteStartTime);
      var endTime = moment(this.suiteFinishedTime);
      var duration = moment.duration(moment(endTime).diff(startTime));
      var hours = duration.hours();
      var minutes = duration.minutes();
      var seconds = duration.seconds();
      if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
      } else if (hours <= 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
  },
};
</script>

<style></style>
