<template>
  <div class="simItemBB2" :style="cssVars" @click="$emit('click', $event)">
    <!-- <h2> {{ rect.text }}</h2> -->
  </div>
</template>
  
<script>

const ItemColors = {
  MAIN: "#6C00FF",
  SIMILAR: "#1F8A70",
  SIMILAR_DISABLED: "#FC7300",
  HOVER: "#BFDB38"
}

export default {
  name: 'sim-item',
  props: {
    rect: Object,
  },
  computed: {
    cssVars() {
      let modalImg = document.getElementById("screenshot");
      console.log("modal Client", modalImg?.clientWidth)
      console.log("modal natural", modalImg.naturalWidth)
      let newX = this.rect.x * (modalImg?.clientWidth / modalImg.naturalWidth)
      let newY = this.rect.y * (modalImg?.clientHeight / modalImg.naturalHeight)
      let newWidth = this.rect.width * (modalImg?.clientWidth / modalImg.naturalWidth)
      let newHeight = this.rect.height * (modalImg?.clientHeight / modalImg.naturalHeight)
      let borderWidth = 2;
      let display = "inherit";
      let bgColor = this.rect.interactions >= 0 && this.rect.interactions < 0.1 ? 'lightgreen' 
        : this.rect.interactions > 0.1 && this.rect.interactions < 0.3 ? 'green' 
          : this.rect.interactions > 0.3 && this.rect.interactions < 0.5 ? 'yellow' 
            : this.rect.interactions > 0.5 && this.rect.interactions < 0.7 ? 'lightpink' 
            : this.rect.interactions > 0.7 && this.rect.interactions <= 1 ? "red" 
              : ''
      console.log("bgColor", bgColor)
      let diagAngle = Math.atan(newWidth / newHeight) * 180 / Math.PI

        return {
            '--x':`${newX}px`,
            '--y': `${newY}px`,
            '--width':`${newWidth}px`,
            '--height':`${newHeight}px`,
            '--color': 'red',
            '--hover-color': ItemColors.HOVER,
            '--border': `${borderWidth}px`,
            '--diag-angle': `${diagAngle}deg`,
            'display': display
        }
    },
  }

}
</script>
<style>
.simItemBB2 {
  border-width: var(--border);
  border-style: solid;
  border-color: var(--color);
  position: absolute;
  top: var(--y);
  left: var(--x);
  width: var(--width);
  height: var(--height);
  z-index: 3;
  background: var(--bg--color);
}

.simItemBB2:hover {
  border-color: var(--hover-color);
}

.simItemBB2:before,
.simItemBB2:after {
  position: absolute;
  /* content: ''; */
  background: var(--color);
  display: block;
  width: var(--border);
  height: var(--width);
  /* -webkit-transform: calc(var(--diag-angle) * -1);
      transform: rotate(calc(var(--diag-angle) * -1));     */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.simItemBB2:after {
  /* -webkit-transform: rotate(var(--diag-angle));    
      transform: rotate(var(--diag-angle)); */
}
</style>
  