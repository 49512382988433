<template>
    <div class="container-fluid right-content">
      <div class="c--breadcrumb py-4">
        <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link to="/details/project-explorer/list-projects">
          Apps
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <router-link :to="`/details/project-explorer/view-project/${$route.params.id}`">
          {{ $route.params.name }}
        </router-link>
        <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
        <span> Project test suite files</span>
      </div>
      <template>
        <div class="body--modale">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <data-list  :loading="loading" :items="file" :customSizes="true"
                ref="dataListTestSuite">
                <template #content>
                  <div style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 150px ;text-align: center;" class="custom-header-rows">
              <div class="elt-row max-width">
                <h3>Name</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>RUNDESC</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>Status</h3>
              </div>
              <div class="elt-row max-width" >
                <h3>Created</h3>
              </div>
              <div class="elt-row max-width" >
              </div>
              </div>
                  <div class="tblb--row mb-2" v-for="(      elt, id      ) in       file       " :key="elt.id">
                    <div style="display: grid;grid-template-columns: 1fr 1fr 100px 1fr 150px ;text-align: center;" class="tblb--inner">
                      <div class="elt-row max-width" >
                        <span class="test-run-name">{{ elt.runName.replace('Step', 'Test') }}</span>
                        <br />
                      </div>
                      <div class="elt-row max-width" >
                        <span style="overflow: hidden;text-overflow: ellipsis;">
                          {{ elt.runDesc }}
                        </span>

                        <span class="run-tags-cont" style="overflow: hidden;text-overflow: ellipsis;">
                          <span class="run-tags">
                            {{ elt.fileName }}
                          </span>
                        </span>

                      </div>
                      <div class="elt-row max-width" >
                        <el-alert class="no--close" :title="elt.isActive === true ? 'active' : 'disabled'" :type="elt.isActive === true
                          ? 'success'
                          : elt.isActive === false
                            ? 'danger'
                            : 'danger'
                          " show-icon></el-alert>
                      </div>
                      <div class="elt-row max-width" >
                        <span class="elt--txt">
                          {{ formatDate(elt.updatedAt) }}
                        </span>
                      </div>
                      <div class="elt-row max-width" style="display: flex;gap: 5px;">
                        <el-tooltip class="item" effect="light" content="Delete file on test" placement="top">
                          <el-button type="danger" icon="el-icon-delete" @click.stop=" deleteItem(elt)"
                            circle plain size="small"></el-button>
                        </el-tooltip>

                        <el-tooltip class="item" effect="light" content="Download test suite file" placement="top">
                          <el-button type="secondary" icon="el-icon-download" @click.stop="downloadTestSuiteFile(elt)"
                                     circle plain size="small"></el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
              </data-list>
            </div>
          </div>
        </div>
      </template>

    </div>
  </template>

  <script>
  import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
    Select,
    Option,
    Button,
    Collapse,
    CollapseItem,
  } from "element-ui";
  import swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import moment from "moment";
  import "moment-timezone";
  import axios from "axios";
  import DataList from "@/components/Custom/DataList.vue";


  export default {
    layout: "DashboardLayout",
    components: {
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Select.name]: Select,
      [Option.name]: Option,
      [Button.name]: Button,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
      DataList,
    },

    data() {
      return {
        file: [],
        tHeaderSuitFile: [
            {
            label: this.$t("list_item_page.name"),
            key: "runName",
            sortable: true,
            width: "25%",
            sortDetails: { asc: true, desc: false, type: "string" },
            },
            { label: "runDesc", key: "runDesc", width: "30%", sortable: false },
            { label: this.$t("list_item_page.status"), key: "", width: "15%", sortable: false },
            {
            label: this.$t("list_item_page.created"),
            key: "acceptedAt",
            sortable: true,
            width: "20%",
            sortDetails: { asc: true, desc: false, type: "date" },
            },
            { label: "", key: "", width: "10%", sortable: false },
        ],
        loading:false,
      };
    },
    async created() {
        this.getList()
    },
    methods: {
      async downloadTestSuiteFile(row) {
        const testSuiteId = this.$route.params.testSuitRunId;
        const fileId = row.fileKey.split("/")[1];
        try {
          const resp = await axios.get(
              `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuiteId}/file/${fileId}`
          );

          const respdata = resp.data;
          const fileName = respdata.fileName;
          const fileURL = respdata.url;
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$notify({
            type: "success",
            message:  "Your session is downloaded successfully",
          });
        } catch (error) {
          console.log(error);
          console.log("Could not download test suite file");
          this.$notify({
            type: "danger",
            message:  "Could not download test suite file",
          });
        }


      },
        async deleteItem(row) {
        const testSuitRunId = this.$route.params.testSuitRunId;
        const confirmation = await swal.fire({
            title: "File '" + row.fileName + "' will be removed.",
            type: "question",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
        });

        if (confirmation.value === true) {
            const formData = new FormData();
            formData.append('suiteFiles', JSON.stringify([{ operation: "remove", fileKey: row.fileKey }]));
            await axios
            .put(
                `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`, formData
            )
            .then(async (response) => {
                // this.items = Object.values(response.data.response);
                if (response.data.status == "success") {
                this.$notify({
                    type: "success",
                    message: "Test delete successfully.",
                });
                await this.getList()
                this.loading = false;
                } else {
                this.loading = false;
                }

            })
            .catch((error) => {
                console.log("Error: ", error);
                this.loading = false;
            })
        }

        },
        async getList() {
            this.loading = true;
            axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || status === 404  || (status >= 200 && status < 500);
            };
            const testSuitRunId = this.$route.params.testSuitRunId;

            axios
                .get(
                `${process.env.VUE_APP_API_URL_PREFIX}/testsuite/v1/${testSuitRunId}`
                )
                .then( async (response) => {
                if(response.data.status == 'success'){
                    this.file = Object.values(response.data.response.testSuiteFiles)
                    this.loading = false;
                }else{
                    this.file = []
                    this.loading = false;
                }
                })
                .catch((error) => {
                console.log(error)
                this.loading = false;
                this.$notify({
                    type: "danger",
                    message: "Cannot load user Project... Try refresh" + error,
                });
                })
                .finally(() => {
                this.loading = false;
                });
        },
        formatDate(a) {
            return moment(a).format("MMM DD YYYY, h:mm:ss a");
        },
    }
  };
  </script >
