import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
const aioTokenUrl = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/aiotoken";
const aioTokenRefreshUrl = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/aiorefresh";
const forgotPasswordUrl = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/users/password/forgot";
const newPasswordUrl = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/users/password/reset";

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_URL_PREFIX,
  tokenName: "access_token",

  loginUrl: "/auth/v1/users/login",
  registerUrl: "/auth/v1/users/new",

  responseDataKey: "response",
  tokenPath: "data.response.access_token",
  tokenPrefix: "",
  logoutUrl: "/auth/v1/users/logout",
});

export default {
  state: {
    isAuthenticated:
      localStorage.getItem("vue-authenticate.access_token") !== null,
    namespaced: true,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
  },

  actions: {
    async login(context, payload) {
      const res = await vueAuth.login(payload.user, payload.requestOptions)

      if (res && res.status === 200) {
        context.commit("isAuthenticated", { isAuthenticated: vueAuth.isAuthenticated() })

        const res2 = await axios.post(aioTokenRefreshUrl, payload.requestOptions)

        if (res2.status === 200) {
          const aioToken = res2.data.response.aioToken;

          localStorage.setItem("testgold.aioToken", aioToken);
          router.push({ path: "/dashboard" });
        } else {
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("testgold.aioToken");
          localStorage.setItem(
            "login--message",
            (res2.data && res2.data.message) ?? res2.message ?? "User trial period has ended. Please contact support@testgold.dev."
          );
          window.location.reload();
        }
      } else {
        return res
      }
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          if (
            response.message &&
            response.message === "Request failed with status code 400"
          ) {
            return "code 400";
          } else {
            router.push("/confirmation");
          }
        })
        .catch((e) => {
          return false;
        });
    },

    async forgotPassword(context, payload) {
      const res =  axios.post(forgotPasswordUrl,payload.user, payload.requestOptions)
      return res
    },
    async createNewPassword(context, payload) {
      const res =  axios.post(newPasswordUrl,payload.user, payload.requestOptions)
      return res
    },

    logout(context, payload) {
      vueAuth
        .logout()
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
        })
        .catch((e) => {
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("vue-authenticate.is_sharing");
          localStorage.removeItem("testgold.aioToken");
          localStorage.removeItem("testgold.userInfo");
          if(window.location.href.includes("app")) {
            window.location.replace("https://keycloak.app.wringr.ai/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("dev")) {
            window.location.replace("https://keycloak.dev.wring.dev/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("localhost")) {
            window.location.replace("https://keycloak.dev.wring.dev/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("key")) {
            window.location.replace("https://keycloak.key.wring.dev/realms/Wring/protocol/openid-connect/logout")
          }
        })
        .finally(() => {
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("vue-authenticate.is_sharing");
          localStorage.removeItem("testgold.aioToken");
          localStorage.removeItem("testgold.userInfo");
          if(window.location.href.includes("app")) {
            window.location.replace("https://keycloak.app.wringr.ai/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("dev")) {
            window.location.replace("https://keycloak.dev.wring.dev/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("localhost")) {
            window.location.replace("https://keycloak.dev.wring.dev/realms/Wring/protocol/openid-connect/logout")
          } else if(window.location.href.includes("key")) {
            window.location.replace("https://keycloak.key.wring.dev/realms/Wring/protocol/openid-connect/logout")
          }
        });
    },
    async userInfo(){
      const Url = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/users/me";
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      const res = await axios.get(Url);
      if (res.data && res.data.status === "success") {
        localStorage.setItem("testgold.userInfo", JSON.stringify(res.data.response));
        return true
      }else{
        return false
      }
    },
  },
};
