<template>
    <div>
        <div class="htbd--onbarding" v-show="show === 'true'">
            <div id="onbarding--content" class="onbarding--content">
                <div class="onbarding--msg">
                    <header>
                        <h3 class="title">{{titel}}</h3>
                        <span 
                            class="close"
                            @click="closeOnbarding()"
                            style="cursor: pointer;"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20 4L4 20M20 20L4 4" stroke="#1F213E" stroke-width="2" stroke-linecap="round"/>
</svg>
                        </span>
                    </header>
                    <div class="ond--content">
                        <div class="onbarding--img">
                            <img v-if="img.length > 0" :src="img" alt="logo" width="100" />
                        </div>
                        <p class="text--onbarding" > {{desc}}</p>
                        <div class="list--btn">
                            <el-button 
                                v-if="showStep > 0 "
                                type="primary" 
                                icon="el-icon-back" 
                                @click="runStep('Previous')"
                            >
                                Previous 
                            </el-button>
                            <el-button 
                                type="primary" 
                                icon="el-icon-right" 
                                v-if="showStep < steps.length - 1"
                                @click="runStep('Next')"
                            >
                                Next
                            </el-button>
                            <el-button 
                            v-if="showStep >= steps.length - 1"
                                type="primary" 
                                icon="el-icon-close"
                                @click="closeOnbarding()"
                            >
                                Close
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="pointer---onbarding"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        Table,
        TableColumn,
        DropdownMenu,
        DropdownItem,
        Dropdown,
        Tooltip,
        Select,
        Option,
        Button,
        Collapse,
        CollapseItem,
    } from "element-ui";
    import { computed } from "vue";
    export default {
        nmae: "onboarding",
        components: {
            [Tooltip.name]: Tooltip,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Dropdown.name]: Dropdown,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Select.name]: Select,
            [Option.name]: Option,
            [Button.name]: Button,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
        },
        props: {
            steps: {
            default: () => [],
            description: "List of step",
            },
            showonbarding: {
            type: String,
            default: "true",
            },

        },
        data() {
            return {
                titel: '',
                desc: '',
                showStep: 0,
                img: "",
                show: "true",
                scrollY: window.pageYOffset,
            }
        },
        mounted(){
            this.runStep()
        },
        methods:{
            async runStep(st) {
                const el_pointer = document.getElementById(this.steps[this.showStep].element)
                el_pointer ? el_pointer.classList.remove("show--pointer") : "" ;
                if(st == "Next"){
                    this.showStep = this.showStep + 1
                }else if(st == "Previous"){
                    this.showStep = this.showStep - 1
                }
                this.titel = this.steps[this.showStep].titel
                this.desc = this.steps[this.showStep].desc
                this.img = this.steps[this.showStep].img ? this.steps[this.showStep].img : "" ;
                let el = document.getElementById(this.steps[this.showStep].element)
                el ? el.classList.add("show--pointer") : "" ;
                const onbarding = document.getElementsByClassName('onbarding--content')[0]
                const elPointer = document.getElementsByClassName('pointer---onbarding')[0]
                setTimeout(() => {
                    if(this.steps[this.showStep].element){
                        let b = el.getBoundingClientRect();
                        if(st != "scroll"){
                            if(b.top + 400 > window.innerHeight ){
                                window.scroll(0,b.top - window.innerHeight + 400 );
                                el = document.getElementById(this.steps[this.showStep].element)
                                b = el.getBoundingClientRect();
                            }else if(b.top < 0){
                                window.scroll(0,0);
                                el = document.getElementById(this.steps[this.showStep].element)
                                b = el.getBoundingClientRect();
                            }
                        }
                        let leftR = 0
                        if(window.innerWidth <= (b.left + 400)){
                            onbarding.style.left = window.innerWidth - 420 +'px'
                            leftR = (window.innerWidth) - b.left
                        }else if(b.left < 10){
                            onbarding.style.left = 10 + 'px'
                        }else{
                            onbarding.style.left = b.left+'px'

                        }
                        onbarding.style.top = b.top+'px'
                        if(this.steps[this.showStep].position === "right"){
                            window.innerWidth <= (b.left + 400 + el.getBoundingClientRect().width + 10)
                            ? onbarding.style.left = window.innerWidth - 420 +'px'
                            : onbarding.style.left = b.left + el.getBoundingClientRect().width + 10 + "px";
                            elPointer.style.transform = "translate3d(-5px, 20px, 0px)"
                        }else if(this.steps[this.showStep].position === "bottom"){
                            onbarding.style.top = el.getBoundingClientRect().height + 20 + b.top + "px"
                            leftR == 0 
                                ? elPointer.style.transform = `translate3d(${b.width / 2}px, 0px, 0px)`
                                :elPointer.style.transform = `translate3d(${(400 - leftR )+ (b.width / 2) + 10}px, 0px, 0px)` ;
                        }else if(this.steps[this.showStep].position === "top"){
                            onbarding.style.top = el.getBoundingClientRect().top - onbarding.getBoundingClientRect().height  - 20 + "px"
                            leftR == 0 
                                ? elPointer.style.transform = `translate3d(${b.width / 2}px, ${onbarding.getBoundingClientRect().height}px, 0px)`
                                :elPointer.style.transform = `translate3d(${(400 - leftR )+ (b.width / 2)}px, ${onbarding.getBoundingClientRect().height}px, 0px)` ;
                        }else if(this.steps[this.showStep].position === "left"){
                            window.innerWidth <= (b.left + 400 + el.getBoundingClientRect().width + 10)
                            ? onbarding.style.left = window.innerWidth - 420 - b.width - 30  +'px'
                            : onbarding.style.left = b.left + el.getBoundingClientRect().width - 30  - b.width + "px";
                            elPointer.style.transform = "translate3d(395px, 40px, 0px)"
                        }
                    }else{
                        onbarding.style.left = (window.innerWidth - 400 )/ 2 +'px'
                        onbarding.style.top = (window.innerHeight - onbarding.getBoundingClientRect().height )/ 3 +'px'
                        elPointer.style.transform = "translate3d(50px, 50px, 50px)"
                    }
                }, 10);
            },
            closeOnbarding(){
                const el_pointer = document.getElementById(this.steps[this.showStep].element)
                el_pointer ? el_pointer.classList.remove("show--pointer") : "" ;
                this.show = "false"
                localStorage.setItem('wringHelpTooltips', "false")
            },
            initializeshowonbarding(){
                this.show = "true"
                this.showStep = 0
                this.runStep()
            },
        },
        created() {
            this.show = this.showonbarding
        },
        mounted() {
            window.addEventListener('scroll', e => {
                    this.runStep("scroll")
            });

        },
    }
</script>
<style lang="scss">
.htbd--onbarding{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.122);
    .onbarding--content{
        width: 400px;
        height: auto;
        position: absolute;
        top: 20px;
        background-color: #ffff;
        z-index: 2;
        border-radius: 5px;
        .onbarding--msg{
            width: 100%;
            padding: 1rem;
            z-index: 99999;
            header{
                width: 100%;
                .title{
                    float: left;
                }
                .close{
        
                    float: right;
                }
            }
            .ond--content{
                display: inline-block;
                margin: 0 auto;
                width: 100%;
                .list--btn{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 5px;
                }
            }
        }
    }
    .pointer---onbarding{
        position: absolute;
        left: 0px;
        transform: translate3d(50px, 50px, 50px);
        top: -5px;
    }
    .pointer---onbarding::after{
        position: absolute;
        content: "";
        background:#ffff ;
        top: 0;
        left: 0;
        transition: transform .2s ease-out,visibility .2s ease-out ; 
        visibility: visible;
        transform: translate(0) rotate(45deg);
        transform-origin: center;
        width: 10px;
        height: 10px;
        position: absolute;
        z-index: -1;
    }
    .onbarding--img{
        text-align: center;
        margin: 20px;
    }
}
.show--pointer{
    border: solid #5f72e4 0.5px !important;
    background: #e9ecfc !important;
}
</style>