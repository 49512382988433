<template>
  <div class="container-fluid mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span>{{ $t('profile_page.profile') }}</span>
    </div>
    <div class="row">
      <div class="col">
        <user-card :user="user" :loading="loading" />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-6 order-xl-1">
        <user-edit-card :user="user" :loading="loading" />
      </div>
      <div class="col-xl-6 col-md-6 order-xl-2">
        <user-edit-password-card :user="user" :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import UserEditCard from "@/views/Examples/UserProfile/UserEditCard.vue";
import UserEditPasswordCard from "@/views/Examples/UserProfile/UserEditPasswordCard.vue";
import UserCard from "@/views/Pages/UserProfile/UserCard.vue";
import axios from "axios";

export default {
  components: {
    UserEditCard,
    UserEditPasswordCard,
    UserCard,
  },
  data() {
    return {
      user: {},
      loading: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        await axios
          .get(process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`)
          .then((response) => {
            if (
              response &&
              response.message === "Request failed with status code 402"
            ) {
              console.log("navbar 402");
              localStorage.removeItem("vue-authenticate.access_token");
              localStorage.removeItem("testgold.aioToken");
              localStorage.setItem(
                "login--message",
                "User trial period has ended. Please contact support@testgold.dev."
              );
              window.location.reload();
            }

            this.user = {
              ...response.data.response,
              name: response.data.response.full_name,
              tierName: response.data.response.tier_name,
              tierLimits: response.data.response.tier_limits,
              tierUsage: response.data.response.tier_usage,
            };
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e.message}`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
.b {
  border: 1px solid red;
}
</style>
