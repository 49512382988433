export default {
  nav: {
    dashboard: "Tablero",
    testruns: "Pruebas",
    integrations: "Integraciones",
    virtualizeApi: "Virtualice",
    installation: "Instalación",
    profile: "Perfil de usuario",
    docs: "Leer la documentación",
    liveEvents: 'Eventos en vivo',
    subscription: 'Suscripción',
    github: "Informar de los problemas",
  },
  footer: {
    made: "hecho con",
    by: "por",
  },
  generate_test:{
    generate_test: "generate test"
  },
  dashboard: {
    average_runtime: "Tiempo medio de ejecución",
    tests: "Pruebas",
    since_last_month: "Desde el último mes",
    longest_test_run: "Prueba mas Larga",
    test_case_healed: "Casos de Prueba reparados",
    suggestions: "Sugerencias",
    auto_heals: "Auto reparaciones",
    time_saved: "Tiempo ahorrado ",
    test_heals: "Pruebas reparadas",
    singular_hour: " Hora",
    plural_hour: " Horas",
    singular_minute: " Minuto",
    plural_minute: " Minutos",
    singular_second: " Segundo",
    plural_second: " Segundos",
    auto_healed: "Autorreparado",
    suggested: "Sugerido",
  },
  user_edit_card: {
    edit_profile: "Actualiza tu información",
    edit_password: "Editar contraseña",
    picture: "Imagen",
    remove: "Eliminar",
    select_image: "Seleccionar Imagen",
    change: "Cambiar",
    name: "Nombre",
    email: "Correo Electrónico",
    old_password: "Contraseña antigua",
    new_password: "nueva contraseña",
    submit: "Guardar",
    save: "Save",
  },
  user_card: {
    level: "Nivel",
    usage: "Uso",
    subscription_plan: "Plan de suscripción:",
  },
  profile_page: {
    profile: "Perfil",
  },
  subscription_view: {
    subscription: "Plan de suscripción",
    current_plan: "Límites del plan actual y uso",
    update_plan: "Gestión del plan",
    plan_billing: "Billing details",
    plan_usage: "Utilización del plan"
  },
  social_traffic_table: {
    test_generation_highlights: "Hitos de Generación de Pruebas",
    test_name: "Prueba",
    paths: "Rutas",
    empty: "Sin Datos",
  },
  list_item_page: {
    test_explorer: "Explorador de Pruebas",
    test_explorer_api: "Pruebas de funcionamiento Api",
    search: "Buscar ...",
    filter: "Filtrar",
    no_items_found: "No se encontraron elementos",
    total_steps: "Pasos totales",
    created: "Creado",
    tags: "Etiquetas",
    name: "Nombre",
    status: "Estatus",
    entries: "registros",
    of: "de",
    to: "a",
    add_test: "Añadir prueba",
    run_katalon_selenium: "Ejecutar scripts de prueba de Katalon o IDE de Selenium",
    generate_test: "Generar Pruebas a partir de la Sesión",
    run_name: "Nombre de Ejecución (requerido):",
    placeholder_run_name: "Ingrese un nombre para la ejecución de esta prueba",
    description: "Descripción:",
    placeholder_run_description: "Ingrese una descripción opcional o comentarios para la ejecución de esta prueba",
    run_tags: "Etiquetas de ejecución:",
    placeholder_run_tags: "Opcionalmente ingrese etiquetas para esta ejecución, separadas por comas",
    settings: "Configuración",
    advanced: "Detalles",
    test_script: "Opciones de Script de Prueba",
    browser_width: "Ancho del Navegador",
    browser_height: "Alto del Navegador",
    max_wait: "Tiempo de Espera máximo (segundos)",
    test_script_files: "Archivos de Script de Prueba",
    upload_test_script: "Suba archivos de script de prueba",
    up_to_50: "(hasta 50 archivos .xml, .side, o .wringr)",
    up_to_50_project: "(hasta 50 archivos .xml, .side, o .wringr)",
    uploaded_files: "Archivos subidos",
    clear_all: "Eliminar todo",
    ignore_disabled: "Ignorar elementos deshabilitados",
    ignore_invisible: "Ignorar elementos invisibles",
    collect_interaction: "Recopilar datos eventos de interacción de página para mejora de casos de prueba",
    collect_extra_element: "Recopilar datos extra de elementos y tratar de reparar selectores rotos (lento)",
    generate_selectors: "Generar selectores para todos los elementos en cada página visitada (lento)",
    try_to_avoid: "Intentar evitar métodos de cadenas/texto al generar selectores de XPath",
    verify_suggested: "Verificar selectores sugeridos",
    trigger_integrations: "Activar integraciones (GitHub, GitLab, Jira) al fallo de prueba",
    avoid_attributes: "Evitar estos atributos al generar selectores",
    placeholder_avoid_attributes: "Ingrese atributos a evitar, separados por espacios, por ejemplo id class href",
    use_attributes: "Usar estos atributos al generar selectores",
    placeholder_use_attributes: "Ingrese atributos a usar, separados por espacios, por ejemplo id class href",
    continue_test_suite: "Continuar ejecución de conjunto de pruebas si un componente de prueba falla",
    timeout_and_cancel: "Tiempo de expiración y cancelación de la prueba",
    placeholder_timeout_and_cancel: "max 3600.0",
    seconds: "segundos",
    close: "Cerrar",
    start_run: "Iniciar Ejecución",
  },
  data_list: {
    no_items_found: "No se encontraron ",
  },
  integrations: {
    integrations: "Integraciones",
    ci: "Integración Continua",
    triggers: "Disparadores",
    scheduled_runs: "Ejecuciones programadas",
    description: "Descripción",
    name: "Nombre",
    status: "Estatus",
    actions: "Acciones",
    origin: "Origen",
    added_updated: "Añadido/Actualizado en",
    last_at : "Por última vez en",
    last_status: "Último estatus",
    last_five: "Últimos cinco",
  },
  scheduled_run: {
    scheduled_test: "Prueba programada",
    name: "Nombre",
    schedule: "Programado",
    last_run: "Última ejecución",
    next_run: "Próxima ejecución",
    is_active: "Está activo",
    last_updated: "Actualizado por última vez",
  },
  configurations_content: {
    quick_setup: "Configuración Rápida",
    click_on_your_os: "Haga click en el sistema operativo de su preferencia",
    copy_tg_token: "Copie el siguiente comando y ejecútelo en su terminal para establecer su TG_TOKEN",
    windows: "Windows PowerShell",
    linux_macos: "Linux/MacOS",
    copy_cypress_token: 'Copie el siguiente comando y ejecútelo en su ventana de terminal para establecer su CYPRESS_TG_TOKEN y su CYPRESS_TG_ENABLED',
    quickly_interceptor_packages: "Paquetes de Interceptor Wringr",
    download_java_3_14_0: "Descargar JAVA JAR - 3.14.0",
    download_java_3_141_59: "Descargar JAVA JAR - 3.141.59",
    include_this_selenium: "Incluya este JAR de Selenium en el classpath de Java cuando ejecute su prueba en lugar del JAR normal de Selenium. Este JAR contiene nuestro interceptor Selenium, así como sus dependencias.",
    examples: "Ejemplos",
    download_python_wheel: "Descargar Python wheel",
    install_this_python_wheel: "Instala este wheel Python para anular la dependencia normal de su paquete Selenium:",
    pip_install_selenium: "pip install --upgrade --force-reinstall selenium-20.11.0-py2.py3-none-any.whl",
    download_npm_package: "Descargar paquete NPM",
    install_this_npm: "Instala este paquete NPM para anular la dependencia normal de su paquete Selenium:",
    npm_install_selenium: "npm install selenium-webdriver-20.11.0.tgz",
    npm_install_aichemy: "npm install @aichemy/wring-cypress-plugin",
    for_cypress_settings: "Para configuraciones de Cypress visite este enlace:",
    js_examples: "Ejemplos JS",
    ts_examples: "Ejemplos TS",
    you_are_on_the: "Estás en la versión",
    version: "",
    service_versions: "Versiones de servicio",
    ui_version: "La versión de la interfaz de usuario es:",
    api_version: "La versión de la API es:",
  },
  user_profile: {
    installation: "Instalación",
  },
  dashboard_navbar: {
    welcome: "Bienvenid@",
    my_profile: "Mi perfil",
    logout: "Salir",
    language: "Idioma",
    english: "Inglés",
    spanish: "Español",
  },
  view_item_page: {
    test_explorer: "Explorador de Pruebas",
    overview: "Resumen",
    run_steps: "Pasos de la carrera",
    advanced: "Avanzado",
    enhancer: "Optimizador",
    share: "Compartir",
    rerun: "Ejecutar de nuevo",
    share: "Compartir",
    view_websocket_logs: "Ver registros de websocket",
    view_runner_log: "Ver registro de ejecución",
    download_csv_report: "Descargar reporte CSV",
    download_pdf_report: "Descargar reporte PDF",
    select_selenium_test: "Seleccionar script de prueba Selenium",
    download_python_script: "Descargar script python",
    download_selenium_test: "Descargar script de prueba Selenium con selectores originales",
    download_selenium_pom: "Descargar POM Selenium para selectores sugeridos",
    download_selenium_script: "Descargar script de prueba Selenium usando POM para selectores sugeridos",
    download_selenium_pom_verified: "Descargar POM Selenium usando selectores verificados",
    download_generated_cypress_verified: "Descargar scripts de prueba Cypress con selectores verificados",
    download_generated_cypress: "Descargar scripts de Prueba Cypress generados",
    search: "Buscar ...",
    file_variables: "Variables de Archivo",
    share_this_item: "Comparte este Item",
    permissions: "Permisos",
    read_only: "Solo lectura",
    edit_item_properties: "Editar propiedades de Item",
    rerun_this_item: "Ejecutar de nuevo este Item",
    generate_shareable_url: "Generar URL compartible",
    rerun_test_with: "Reiniciar prueba con pasos actuales",
    run_name: "Nombre de Ejecución (requerido):",
    placeholder_run_name: "Ingrese un nombre para la ejecución de esta prueba",
    description: "Descripción:",
    placeholder_run_description: "Ingrese una descripción opcional o comentarios para la ejecución de esta prueba",
    run_tags: "Etiquetas de ejecución:",
    placeholder_run_tags: "Opcionalmente ingrese etiquetas para esta ejecución, separadas por comas",
    settings: "Configuración",
    advanced: "Avanzado",
    test_script: "Opciones de Script de Prueba",
    browser_width: "Ancho del Navegador",
    browser_height: "Alto del Navegador",
    max_wait: "Tiempo de Espera máximo (segundos)",
    test_script_files: "Archivos de Script de Prueba",
    upload_test_script: "Suba archivos de script de prueba",
    up_to_50: "(hasta 50 archivos .xml, .side, o .wringr)",
    uploaded_files: "Archivos subidos",
    clear_all: "Eliminar todo",
    ignore_disabled: "Ignorar elementos deshabilitados",
    ignore_invisible: "Ignorar elementos invisibles",
    collect_interaction: "Recopilar datos eventos de interacción de página para mejora de casos de prueba",
    collect_extra_element: "Recopilar datos extra de elementos y tratar de reparar selectores rotos (lento)",
    generate_selectors: "Generar selectores para todos los elementos en cada página visitada (lento)",
    try_to_avoid: "Intentar evitar métodos de cadenas/texto al generar selectores de XPath",
    verify_suggested: "Verificar selectores sugeridos",
    trigger_integrations: "Activar integraciones (GitHub, GitLab, Jira) al fallo de prueba",
    avoid_attributes: "Evitar estos atributos al generar selectores",
    placeholder_avoid_attributes: "Ingrese atributos a evitar, separados por espacios, por ejemplo id class href",
    use_attributes: "Usar estos atributos al generar selectores",
    placeholder_use_attributes: "Ingrese atributos a usar, separados por espacios, por ejemplo id class href",
    continue_test_suite: "Continuar ejecución de conjunto de pruebas si un componente de prueba falla",
    timeout_and_cancel: "Tiempo de expiración y cancelación de la prueba",
    placeholder_timeout_and_cancel: "max 3600.0",
    seconds: "segundos",
    close: "Cerrar",
    start_rerun: "Reiniciar Ejecución",
    websocket_live_logs: "Registros de WebSocket en vivo",
    logs_status: "Estatus de registro:",
    test_runner_console_logs: "Registros de consola de ejecutor de pruebas",
    console_log_for_stdout: "Registro de consola para STDOUT",
    no_logs_available_as_script: "Sin registros disponibles debido a que el script no fue ejecutado en nuestro ambiente",
    console_log_for_stderr: "Registro de consola para STDERR",
    run_steps: "Pasos de la carrera",




  },
  overview: {
    test_status: "Estatus de Prueba",
    run_time: "Tiempo de Ejecución",
    steps: "Pasos",
    failures: "Fallas",
    auto_healed: "Auto-reparado",
    time_saved: "Tiempo ahorrado",
    test_run_options: "Opciones de Ejecución de Prueba",
    fast_heal_mode: "Modo de auto reparación Rápida",
    interceptor_failure_handling: "Gestión de Fallos de Interceptor",
    collect_page_interaction: "Recopilación eventos de interacción de página",
    test_run_origin: "Origen de Ejecución de Prueba",
    element_neighbor_max: "Distancia Máxima de Elemento Vecino (px)",
    test_step_wait: "Tiempo de Espera de Paso (segundos)",
    interceptor_screenshots: "Captura de Pantalla de Interceptor",
    browser_window_size: "Tamaño de Ventana de Navegador",
    ignore_disabled_elements: "Ignorar Elementos deshabilitados",
    ignore_invisible_elements: "Ignorar Elementos invisibles",
    enhancements: "Enhancements",

  },
  test_run_steps: {
    search: "Buscar ...",
    to: "a",
    of: "de",
    entries: "entradas",
    step: "Paso",
    no_screenshot: "No se encontró captura de pantalla",
    edit: "Editar",
    save: "guardar",
    add: "Añadir",
    delete: "Eliminar",
    action_to_perform: "Acción por ejecutar",
    selector_type: "Tipo de selector",
    selector_to_use: "Selector a usar",
    input_value: "Valor de entrada",
    enable_step: "Paso habilitado",
    cancel: "Cancelar",
    insert_new_step: "Insertar nuevo paso antes del paso existente",
    new_step: "Nuevo Paso",
    actions: "Acciones",
    choose_selector_type: "Escoja tipo de selector",
    enter_selector: "Ingrese selector",
    enter_value: "Ingrese valor",
    proceed_step_edition: "Proceder con edición del Paso",
    add_a_new_step: "Añadir un nuevo Paso",
  },

  suggestions: {
    step: "Pasos",
    fix: "Reparación",
    page_url: "Url de la página",
    quikly_suggest: "Sugerencias de AI",
    details: "",
    to: "a",
    of: "de",
    entries: "entradas",
    more_details: "Más Detalles",
    see_more: "Ver más",
    original_path: "Ruta Original:",
    suggested_selectors: "Selectores Sugeridos:",
    improvement: "Mejoras",
  },

  test_enhancer: {
    setup: "Configuración",
    runs: "Ejecuciones",
    search: "Buscar ...",
    to: "a",
    of: "de",
    entries: "entradas",
    step: "Paso",
    description: "Descripción",
    operations: "",
    date_run: "Fecha de Ejecución",
    failures: "#Fallas",
    episodes: "#Episodios",
    agent_type: "#Tipo de Agente",
    status: "#Status",
    runs_list: "Lista de Ejecuciones",
    Data_file_upload: "Carga de archivos de datos",
    name: "Nombre",
    saved_at: "Guardado en",
    actions: "Acción",
    add_file: "Añadir Archivo",
    cancel: "Cancelar",
    Run_the_simulator: "Ejecutar el simulador",
    algorithm: "Algoritmo",
    maximum_number_of_tests: "Número máximo de pruebas a Generar",
    randomize: "Aleatorizar",
    randomize_description: "Aleatorizar datos usados en la prueba.",
    sequential: "Secuencial",
    sequential_description: "Aplicar datos proporcionados en el archivo de forma secuencial.",
    exhaustive: "Exhaustivo",
    exhaustive_description: "Cubre todas las combinaciones de datos. Episodios de mayor tamaño proporcionan mejores resultados.",
    permutation: "Permutación",
    permutation_description: "Intenta permutaciones inteligentes de pasos explorables.",
    run: "Ejecutar",
    could_not_start: "No se ha podido iniciar la ejecución de la prueba",
    preparing_test_enhancer: "Preparando el optimizador de pruebas ...",
    no_file_variable: "Sin Archivo de Variables",
    Setup_dynamic_variables: "Configuración de variables dinámicas",
    no_variable_data: "Sin datos de Variables",
    variable: "Variable",
    type: "Tipo",
    data: "Datos",
    is_active: "Está activo",
    source: "Fuente",
    add_variable: "Añadir Variable",
  },

};
