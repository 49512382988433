<template>
  <div>
    <!-- <data-list :tHeader="tHeader" :loading="loading" :items="[getOverview()]">
      <template #content> -->
    <div style="display: flex;gap:15%">
      <div class="charts-cards">
        <div style="margin-top:10px" v-if="!loading && items.response !== null">
        <div
          v-for="elt ,index in [getOverview()]"
          :key="index"
          class="cards-overview-custom"
          style=" border-radius: 10px;"
        >
        <div class="col-xl-3 cs-col-xl-3 col-md-2 " style="max-width: 100%;padding-left: 0;">
            <stats-card :id_card="`0`" class="stat-card-overview" type="gradient-indigo" title="RUN TIME:" :sub-title-time="secondsToRealTime(itemToShow)?.toString()
              " >
            </stats-card>
          </div>
          <!-- <div v-if="elt.trainedSelectorCount?.toString() > 0" class="col-xl-3 cs-col-xl-3 col-md-2 ">
            <stats-card  type="gradient-blue" :title="$t('overview.steps')" :sub-title="elt?.trainedSelectorCount.toString()
              " >
            </stats-card>
          </div>
          <div v-if="failedPaths(elt)?.toString() > 0" class="col-xl-3 cs-col-xl-3 col-md-2 ">
            <stats-card  type="gradient-red" :title="$t('overview.failures')" :sub-title="failedPaths(elt)?.toString()
              ">
            </stats-card>
          </div>
          <div class="col-xl-3 cs-col-xl-3 col-md-2 " v-if="elt?.healedSelectorCount.toString() > 0">
            <stats-card type="gradient-green" :title="$t('overview.auto_healed')" :sub-title="elt?.healedSelectorCount.toString()
              " >
            </stats-card>
          </div> -->
          <div style="max-width: 100%;" class="col-xl-3 cs-col-xl-3 col-md-2 ">
            <stats-card :id_card="`1`" class="stat-card-overview" type="gradient-teal" title="TIME SAVED:" :sub-title-time="convertRunTimeToTimeSaveMS(itemToShow)?.toString()
              " >
            </stats-card>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;max-width: 100%;" class="col-lg-6">
          <v-chart
            class="chart"
            :autoresize="true"
            :option="overviewPieChart"
          />
        </div>
      </div>
        <div class="col-lg-6">
          <div class="video-first text-center mt-1 custom-header-video" v-loading="loadingVideo">
            <el-collapse v-model="activeNames">
              <el-collapse-item v-if="canShowVid" title="Video" name="1">
                <div class="video-player" id="videoPlayer">
                  <video-player
                    class="vjs-custom-skin "
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    @ready="playerReadied"
                  ></video-player>
                </div>
              </el-collapse-item>
              <el-collapse-item
                v-else
                title="Video is not available for the test run"
                name="2"
              >
                <div class="video-player"></div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <h2>{{ $t('overview.test_run_options') }}</h2>
        </div>
      </div>
      <div class="row justify-between">
        <div class="col-lg-5">
          <div class="test-run-left card--animation2">
            <div class="test-run-row">
              <p>{{ $t('overview.fast_heal_mode') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.use_fast_heal === false &&
                  "disabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_failure_handling') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.handle_failure
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.collect_page_interaction') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.use_image_comparison
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_run_origin') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.test_run_origin
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.element_neighbor_max') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.neighbor_maxdist
              }}</span>
            </div>
            
          </div>
        </div>
        <div class="col-lg-5">
          <div class="test-run-right card--animation2">
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_screenshots') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.take_screenshots === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.browser_window_size') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.runner_resolution
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_disabled_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_disabled_elements === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_invisible_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_invisible_elements === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_step_wait') }}</p>
              &nbsp;:&nbsp;x
              <span>{{
                items.runOptions && items.runOptions.runner_step_wait
              }}</span>
            </div>
          </div>
        </div>
      </div>
          <!-- <div class="tblb--inner">
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <el-alert
                class="no--close"
                :title="elt.status"
                :type="
                  elt.status === 'passed'
                    ? 'success'
                    : elt.status === 'failed'
                    ? 'error'
                    : 'danger'
                "
                show-icon
              ></el-alert>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span class="test-time-cont">
                <i class="far fa-clock mr-1"></i>
                {{ secondsToRealTime(elt) }}
              </span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ elt.trainedSelectorCount }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ failedPaths(elt) }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span>{{ elt.healedSelectorCount }}</span>
            </div>
            <div
              class="elt-row"
              :style="'width: calc(100% /' + tHeader.length + ')'"
            >
              <span class="test-time-cont" style="max-width: 100%">
                <i class="far fa-clock mr-1"></i>
                {{ convertRunTimeToTimeSaveMS(elt) }}
              </span>
            </div>
          </div>
        </div> -->
      <!-- </template>
    </data-list> -->

    <!-- <card style="background-color: #E6E6E6;">
      <div class="row"> -->
        <!--Pie Chart -->
        

        <!--Bar Chart -->
        
      <!-- </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <h2>{{ $t('overview.test_run_options') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <el-card class="test-run-left card--animation2">
            <div class="test-run-row">
              <p>{{ $t('overview.fast_heal_mode') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.use_fast_heal === false &&
                  "disabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_failure_handling') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.handle_failure
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.collect_page_interaction') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.use_image_comparison
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_run_origin') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.test_run_origin
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.element_neighbor_max') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.neighbor_maxdist
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.test_step_wait') }}</p>
              &nbsp;:&nbsp;x
              <span>{{
                items.runOptions && items.runOptions.runner_step_wait
              }}</span>
            </div>
          </el-card>
        </div>
        <div class="col-lg-5">
          <el-card class="test-run-right card--animation2">
            <div class="test-run-row">
              <p>{{ $t('overview.interceptor_screenshots') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.take_screenshots === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.browser_window_size') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions && items.runOptions.runner_resolution
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_disabled_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_disabled_elements === true &&
                  "enabled"
              }}</span>
            </div>
            <div class="test-run-row">
              <p>{{ $t('overview.ignore_invisible_elements') }}</p>
              &nbsp;:&nbsp;
              <span>{{
                items.runOptions &&
                  items.runOptions.filter_invisible_elements === true &&
                  "enabled"
              }}</span>
            </div>
          </el-card>
        </div>
      </div>
    </card> -->
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import StatsCard from "@/components/Cards/StatsCard";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";
import axios from "axios";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import DataList from "@/components/Custom/DataList.vue";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Overview",
  props: {
    items: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading state",
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VChart,
    DataList,
    StatsCard,
    videoPlayer,
  },
  data() {
    return {
      itemToShow : null,
      result_second: 0,
      trained : 0,
      videoError: "",
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      activeNames: [],
      chartLoading: true,
      overviewPieChart: {
        
      },
      overviewBarChart: {
        title: {
          text: "Before & After Wringr",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ",
        },
        xAxis: {
          type: "category",
          data: ["#Failures", "#Heals"],
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            name: "Before Wringr",
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 1,
                itemStyle: {
                  color: "#FCA311",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
          {
            type: "bar",
            name: "After Wringr",
            data: [
              {
                value: 0,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
              {
                value: 1,
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
      playerOptions: {
        height: "300",
        width: "500",
        autoplay: false,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
      },
      tHeader: [
        { label: this.$t('overview.test_status'), sortable: false },
        { label: this.$t('overview.run_time'), sortable: false },
        { label: this.$t('overview.steps'), sortable: false },
        { label: this.$t('overview.failures'), sortable: false },
        { label: this.$t('overview.auto_healed') , sortable: false },
        { label: this.$t('overview.time_saved') , sortable: false },
      ],
      canShowVid: false,
      loadingVideo: false,
    };
  },
  methods: {
    playerReadied(player) {
      player.currentTime(5);
    },
    async getVideo() {
      const aioToken = localStorage.getItem("vue-authenticate.access_token");
      const requestId = this.$route.params.id;
      const videoUrl = `${this.baseUrl}/video-presigned/v1?walServerRequestid=${requestId}&walToken=${aioToken}`;

      try {
        this.loadingVideo = true;
        const res = await axios.get(videoUrl)

        if(res.status === 200) {
          this.activeNames = ["1"];
          this.canShowVid = true;
          this.playerOptions.sources[0].src = res.data.response;
          console.log("FFFFFFFFFFFFFFFFF")
          console.log(this.playerOptions)
        } else {
          console.log('an error has occured')
        }
      } catch (e) {
        console.log("Catch2: ", e);
      } finally {
        this.loadingVideo = false;
      }
    },
    forTransparentTr() {
      return {
        background: "transparent !important",
        color: "#ffffff",
        fontWeight: "bold",
      };
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.ceil(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      // return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(secs)}s`;
      return result;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },
    convertRunTimeToTimeSaveMS(a) {
      let actualRunTime = Number(a.finishedAt - a.startedAt);
      let healedCount = Number(a.healedSelectorCount);
      let suggestedCount = Number(a.suggestedSelectorCount);
      let trainCount = Number(a.trainedSelectorCount);
      let x = suggestedCount + trainCount;
      const totalHealSecs = healedCount * 3600;
      const totalSuggestedSecs = x * 300;
      let z = actualRunTime + totalHealSecs + totalSuggestedSecs;
      // const time = moment(z * 1000).format("HH mm");
      const time = this.hhmmss(z);
      return time;
    },
    secondsToRealTime(a) {
      let sec = Number(a.finishedAt - a.startedAt);
      return this.hhmmss(sec);
    },
    failedPaths(a) {
      let healedCount = Number(a.healedSelectorCount);
      let xpathCount = Number(a.xpathCount);
      let trainCount = Number(a.trainedSelectorCount);
      var total = xpathCount - trainCount - healedCount;
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    createDiagram(){
      var first_value = this.trained
      var second_value = this.result_second
      console.log(`fisrt : ${first_value} , second : ${second_value}`)
      this.overviewPieChart = {
        title: {
          text: "Time saved chart",
          left: "left",
          textStyle:{
           color:'#1F213E',
           fontSize: '20px',
           fontWeight: '500'
          }
        },
        tooltip: {
          position:"right",
          trigger: "item",
          formatter: `Enhancement : ${first_value}`,
        },
        legend: {
          orient: "vertical",
          right: "right",
          top: '35%',
          data: [this.$t('overview.enhancements'), "Auto Heal"],
          textStyle:{
            color:"#1F213E",
            fontSize: '12px',
           fontWeight: '600'
          }
        },
        series: [
          {
            name: "Enhancement vs Heal",
            type: "pie",
            top:"10%",
            right:'50%',
            radius: "90%",
            data: [
              {
                value: first_value,
                name: "Enhancements",
                itemStyle: {
                  color: "#53568A",
                },
                labelLine : {
                  show:false
                },
                label:{
                  show : true,
                  position:'inside',
                  formatter: function(params){
                    if(second_value == 0 && first_value == 0){
                      return "0%"
                    }else if(second_value == 0){
                      return "100%"
                    }else{
                        var res = Math.round((first_value/Math.abs(Math.abs(second_value) + Math.abs(first_value))) * 100)
                        return `${res}%`
                      
                    }
                    
                },
                },
              },
              {
                value: Math.abs(second_value),
                name: "Auto Heal",
                itemStyle: {
                  color: "#1F213E",
                },
                labelLine : {
                  show:false
                },
                label:{
                  show : true,
                  position:'inside',
                  formatter: function(params){
                    if(second_value == 0){
                      return ''
                    }else if(second_value == 0 || first_value == 0){
                      return '';
                    }else{
                      var res = 100 - Math.round((first_value/Math.abs(Math.abs(second_value) + Math.abs(first_value))) * 100)
                      console.log(res)
                      return(`${res}%`)
                    }
                
                },

                }
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      }
    },
    getOverview() {
      if (this.items && !this.itemToShow) {
        console.log(this.items)
         this.trained = this.items.trainedSelectorCount;
        if (this.trained > this.items.xpathCount) {
          this.trained = this.items.xpathCount;
        }

        // this.overviewBarChart.series[0].data[0].value = this.items.failedPaths;
        // this.overviewBarChart.series[1].data[0].value =
        //   this.items.failedPaths - this.items.healedCount;
        // this.overviewBarChart.series[0].data[1].value = 0;
        // this.overviewBarChart.series[1].data[1].value = this.trained;

        // this.overviewPieChart.series[0].data[0].value = this.trained;
        // this.overviewPieChart.series[0].data[1].value =
        //   this.items.xpathCount - this.items.trainedSelectorCount;
         this.result_second = this.items.xpathCount - this.items.trainedSelectorCount;
        if (this.items.trainedSelectorCount || this.items.xpathCount) {
          this.chartLoading = false;
          this.itemToShow = this.items
          return this.itemToShow
        }
      }
       else return [];
    },
    formatColDate(row, col, value, index) {
      const time = moment(value * 1000).format("HH mm");
      if (time != "Invalid date") {
        return time;
      }
    },
    proccessRunOptions(obj) {
      let runOptions = obj;
      return Object.keys(runOptions).reduce((result, key) => {
        result[key] = {
          name: obj[key],
          type: "foo",
        };
        return result;
      }, {});
    },
  },
  watch : {
    itemToShow(){
      this.createDiagram()
    }
  },
  async created() {
    await this.getVideo();
    this.$nextTick(()=>{
      // this.createDiagram()
      if (!this.$store.state.imageSrc) {
      this.$store.dispatch('loadImage');
    }
    })
    
  },
  computed:{
    imageSrc(){
      return this.$store.state.imageSrc
    }
  },
  mounted() {},
};
</script>

<style lang="css" src="@/assets/css/video-player.css" />
<style lang="scss">
@import "../../../assets/sass/custom/_variablesCustom";
.custom-cards--overview{
  background-color:rgb(73, 80, 246)  ;
}
.b {
  border: 1px solid red;
}
.el-table__row {
  cursor: pointer;
}
.button-aligned {
  margin-bottom: 0;
}
.click-here {
  cursor: pointer;
}
.click-here a {
  color: black;
  text-decoration: none;
}

.test-run-left,
.test-run-right {
  border: 1px solid #E6E6E6 !important;
  height: 100%;
  padding: 5px;
}

.test-run-row {
  margin-bottom: 5px;
  border-bottom: 1px solid #CFCFCF;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}
.cards-overview-custom{
  gap: 8px;
  margin-top: 20px;
  justify-content: start;
  flex-direction: column;
  display: flex;
}
.charts-cards{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.cards-overview-custom .cs-col-xl-3 .card{
  min-width: 312px;
  border-radius: 10px;
  width: 100%;
}
.custom-header-video .el-collapse .el-collapse-item div .el-collapse-item__header{
  background-color:#E6E6E6;
}
.custom-header-video .el-collapse .el-collapse-item div .el-collapse-item__wrap{
  background-color: #E6E6E6;
}
.cards-overview-custom .cs-col-xl-3 .card .card-body .row .col{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 57px;
  width: 350px;
  padding-left: 0;
  margin-left: 0;
}
.cards-overview-custom .cs-col-xl-3 .card .card-body .row .col h5{
  margin-left: 39px;
  color: var(--main-blue-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; 
  text-transform: uppercase;
  white-space: nowrap;
}
.cards-overview-custom .cs-col-xl-3 .card .card-body .row .col span{
  overflow:hidden;
  text-overflow:ellipsis;
  color: var(--main-blue-color);
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.cards-overview-custom .cs-col-xl-3{
  padding-left: 0;
}
#time-stat-card_0,#time-stat-card_1{
  margin-right: 10px;
}
.letter{
  color: #767692 !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-subtitle{
  margin: 0;
}
.cards-overview-custom .card-body{
  width: 100%;
  min-height: 57px;
}

.cards-overview-custom .cs-col-xl-3 .card .card-body{
  padding:0;
 
}
.col-lg-5{
  width: 50%;
  flex:1;
  max-width: 100%;
}
.col-lg-12 h2{
  color: var(--main-blue-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.test-run-row p {
  margin: 0;
  color: var(--main-blue-color);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.test-run-row span{
  margin-left: 10px;
  color: var(--main-blue-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.chart{
  height: 185px;
  width: 100%;
}
.ovw-table thead th .cell {
  justify-content: center;
}

.table-header-gradient {
  background-image: linear-gradient(to right, #f56646, #fbb140) !important;
}

@media screen and (max-width: 815px) {
  .modal-dialog {
    max-width: 80% !important;
    width: 80% !important;
  }
  .modal-dialog img {
    width: 100%;
  }
}

@media (max-width: 535px) {
  .adpt-cards--overview {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    padding-right: 15px;
  }
}

.vjs-error-display {
  position: relative;

  &::after {
    content: "Wrong video url";
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    height: 100%;
    background: #f5454e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 600;
  }

  .vjs-modal-dialog-content {
    display: none;
  }
}
.cs-col-xl-3 {
  padding-right: 0px;
  .card {
    // margin-top: 30px;
    .card-body > div > div > span {
      font-size: 0.8rem;
    }
  }
}

.card--animation2 {
  width: 100% !important;
  transition: 0.3s;
}

.card--animation2:hover {
  box-shadow: $card-shadow !important;
}

.custom-cards--overview {
  display: flex;
  justify-content: center;
}

@media (min-width: 1200px)
{
  .cs-col-xl-3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 20%;
    max-width: 25%;
}
}
</style>
