import clickOutside from '@/directives/click-ouside.js';
import observeResize from '@/directives/img-resize.js';
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive(observeResize.name, observeResize);
  }
};

export default GlobalDirectives;
