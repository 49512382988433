export default {
    name:'observe-size',
    inserted:function(el, binding) {
        const onResizeCallback = binding.value;
        window.addEventListener('resize', () => {
            const width = parseFloat(getComputedStyle(el).width)
            const height = parseFloat(getComputedStyle(el).height);
            onResizeCallback({ width, height });
          })      
    }
  };
  

