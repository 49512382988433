<template>
  <div class="container-fluid mt-4">
    <div class="mid--page pb-4 no-border">
      <div class="mp--l">
        <div class="btn-group align-items-center">
          <el-button
            @click="showComponent('overview')"
            icon="el-icon-pie-chart"
            class="tab--btn"
            :class="{ active: showOverview }"
          >
            {{ $t("view_item_page.overview") }}
          </el-button>
          <el-button
            @click="showComponent('test-run-steps')"
            icon="el-icon-guide"
            class="tab--btn"
            :class="{ active: showTestRunSteps }"
          >
            {{ $t("view_item_page.run_steps") }}
          </el-button>
          <el-button
            @click="showComponent('suggestions')"
            icon="el-icon-sunrise-1"
            class="tab--btn"
            :class="{ active: showSuggestions }"
          >
            {{ $t("view_item_page.advanced") }}
          </el-button>
          <el-button
            @click="showComponent('testenhancer')"
            icon="el-icon-s-ticket"
            class="tab--btn"
            :class="{ active: showTestEnhancer }"
          >
            {{ $t("view_item_page.enhancer") }}
          </el-button>
        </div>
      </div>
      <div class="mp--r">
        <div
          v-show="showSuggestions && !showAllSuggest"
          class="down-btn-container"
        >
          <button
            v-if="!hideWebSocketLogBtn"
            @click="openWebSocketModal()"
            class="ic--btn mr-2"
          >
            <el-tooltip
              :content="$t('view_item_page.view_websocket_logs')"
              placement="top"
            >
              <small>
                <i class="fas fa-code"></i>
              </small>
            </el-tooltip>
          </button>
          <button
            v-if="showRunnerLogButton"
            @click="openScriptModal()"
            class="ic--btn mr-2"
          >
            <el-tooltip
              :content="$t('view_item_page.view_runner_log')"
              placement="top"
            >
              <small>
                <i class="fas fa-terminal"></i>
              </small>
            </el-tooltip>
          </button>
          <button @click="downloadTestFile('csv')" class="ic--btn mr-2">
            <el-tooltip
              :content="$t('view_item_page.download_csv_report')"
              placement="top"
            >
              <i class="fas fa-file-csv"></i>
            </el-tooltip>
          </button>
          <button @click="downloadTestFile('report-pdf')" class="ic--btn mr-2">
            <el-tooltip
              :content="$t('view_item_page.download_pdf_report')"
              placement="top"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </el-tooltip>
          </button>
          <button
            v-if="
              items.runOptions &&
                items.runOptions.test_run_origin === 'submitted-katalon-xml'
            "
            @click="downloadTestFile('katalonxml')"
            class="ic--btn mr-2"
          >
            <el-tooltip
              content="Download your original Katalon test script XML"
              placement="top"
            >
              <img src="../../../assets/images/Katalon.png" alt="Katalon" />
            </el-tooltip>
          </button>
          <button
            v-if="
              items.runOptions &&
                items.runOptions.test_run_origin === 'submitted-selenium-ide'
            "
            @click="downloadTestFile('JSON')"
            class="ic--btn mr-2"
          >
            <el-tooltip
              content="Download your original observer script file"
              placement="top"
            >
              <img
                src="../../../assets/images/selenium-script-blue.png"
                alt="Observer script file"
              />
            </el-tooltip>
          </button>
          <span v-if="items.runTags">
            <button
              v-if="items.runTags[0] === 'suggested selectors verified'"
              @click="downloadTestFile('verified-cypress-script')"
              class="ic--btn mr-2"
            >
              <el-tooltip
                :content="
                  $t('view_item_page.download_generated_cypress_verified')
                "
                placement="top"
              >
                <img
                  src="../../../assets/images/verified-icon-blue.png"
                  alt="Verified"
                />
              </el-tooltip>
            </button>
          </span>

          <span class="mr-2">
            <base-dropdown
              v-if="
                items.runOptions &&
                  (items.runOptions.test_run_origin ===
                    'submitted-katalon-xml' ||
                    items.runOptions.test_run_origin ===
                      'submitted-selenium-ide')
              "
            >
              <button slot="title-container" class="dropdown-toggle ic--btn">
                <el-tooltip
                  :content="$t('view_item_page.select_selenium_test')"
                  placement="top"
                >
                  <img
                    src="../../../assets/images/selenium.png"
                    alt="Selenium"
                  />
                </el-tooltip>
              </button>
              <div class="subdownbtns px-2">
                <button
                  @click="downloadTestFile('generated-python-script')"
                  class="ic--btn small no-border mr-2"
                >
                  <el-tooltip
                    :content="$t('view_item_page.download_python_script')"
                    placement="top"
                  >
                    <img
                      src="../../../assets/images/selenium.png"
                      alt="Selenium"
                    />
                  </el-tooltip>
                </button>
                <button
                  @click="downloadTestFile('generated-seleniumpy-pom')"
                  class="ic--btn small no-border mr-2"
                >
                  <el-tooltip
                    :content="$t('view_item_page.download_selenium_pom')"
                    placement="top"
                  >
                    <img
                      src="../../../assets/images/selenium_blue.png"
                      alt="Selenium Pom"
                    />
                  </el-tooltip>
                </button>
                <button
                  @click="downloadTestFile('generated-pypom-script')"
                  class="ic--btn small no-border mr-2"
                >
                  <el-tooltip
                    :content="$t('view_item_page.download_selenium_script')"
                    placement="top"
                  >
                    <img
                      src="../../../assets/images/selenium-script-blue.png"
                      alt="Selenium Script"
                    />
                  </el-tooltip>
                </button>
                <span v-if="items.runTags">
                  <button
                    v-if="items.runTags[0] === 'suggested selectors verified'"
                    @click="downloadTestFile('verified-seleniumpy-pom')"
                    class="ic--btn small no-border mr-2"
                  >
                    <el-tooltip
                      :content="
                        $t('view_item_page.download_selenium_pom_verified')
                      "
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/verified-icon-blue.png"
                        alt="Verified"
                      />
                    </el-tooltip>
                  </button>
                </span>
                <span v-if="items.runTags">
                  <button
                    v-if="items.runTags[0] === 'suggested selectors verified'"
                    @click="downloadTestFile('verified-pypom-script')"
                    class="ic--btn small no-border mr-2"
                  >
                    <el-tooltip
                      content="Download test script using verified Selenium POM and verified selectors"
                      placement="top"
                    >
                      <img
                        src="../../../assets/images/verified-icon-blue.png"
                        alt="Verified"
                      />
                    </el-tooltip>
                  </button>
                </span>
              </div>
            </base-dropdown>
          </span>

          <button
            v-if="
              items.runOptions &&
                (items.runOptions.test_run_origin === 'submitted-katalon-xml' ||
                  items.runOptions.test_run_origin === 'submitted-selenium-ide')
            "
            @click="downloadTestFile('generated-cypress-script')"
            class="ic--btn mr-2"
          >
            <el-tooltip
              :content="$t('view_item_page.download_generated_cypress')"
              placement="top"
            >
              <img
                src="../../../assets/images/cypress_blue.png"
                alt="Katalon"
              />
            </el-tooltip>
          </button>
        </div>
        <!-- Suggestions -->

        <div v-show="showTestEnhancer" class="down-btn-container">
          <button
            v-show="lookedSalesforceIntegration"
            @click="openListSalesforceVariables()"
            class="ic--btn mr-2"
          >
            <el-tooltip content="Salesforce Variables" placement="top">
              <i class="fa fa-cloud" aria-hidden="true"></i>
            </el-tooltip>
          </button>
          <!-- <button @click="downloadEnhancerReport()" class="ic--btn mr-2">
            <el-tooltip content="Download Test Enhancer Report" placement="top">
              <i class="fa fa-download" aria-hidden="true"></i>
            </el-tooltip>
          </button> -->
          <button @click="openListFileVariables()" class="ic--btn mr-2">
            <el-tooltip
              :content="$t('view_item_page.file_variables')"
              placement="top"
            >
              <i class="fa fa-folder" aria-hidden="true"></i>
            </el-tooltip>
          </button>
          <button @click="openVariables()" class="ic--btn mr-2">
            <el-tooltip content="Variables" placement="top">
              <i class="fa fa-key" aria-hidden="true"></i>
            </el-tooltip>
          </button>
          <button @click="showRunSteps()" class="ic--btn mr-2">
            <el-tooltip
              :content="$t('view_item_page.test_enhancer_run')"
              placement="top"
            >
              <i
                id="enhancer_start_stop_btn"
                v-bind:class="[runActive ? 'fa-stop' : 'fa-play', 'fa']"
                aria-hidden="true"
              ></i>
            </el-tooltip>
          </button>
        </div>
        <!-- Test Enhancer end-->

        <!-- Rus steps start-->
        <div v-show="showTestButtons === 'test_run_steps' && showTestRunSteps">
          <el-button
            @click="showRerunModalWithCurrentSteps()"
            icon="el-icon-refresh-left"
            class="tab--btn mr-2"
            v-if="sharedJSON.scopes.includes('shared-rerun')"
          >
            {{ $t("view_item_page.rerun") }}
          </el-button>
        </div>
        <!-- Rus steps end-->
      </div>
    </div>
    <div class="second-card">
      <div class="row">
        <div class="w-100" id="content">
          <div v-if="showTestRunSteps" class=" mx-3">
            <test-run-steps
              ref="testRunSteps"
              :items="items"
              :toggleTestBtn="toggleTestBtn"
            ></test-run-steps>
          </div>

          <div v-if="showSuggestions" class=" mx-3">
            <suggestions ref="suggestions" :items="items"></suggestions>
          </div>

          <div v-if="showTestEnhancer" class=" mx-3">
            <test-enhancer
              ref="testEnhancer"
              :get="get"
              :id="id"
              :loadingVar="loading2"
              :RLtestcase="RLtestcase"
              :RLdatafile="RLdatafile"
              :loadingSalesforce="loadingSalesforce"
              :RLJson="RLJson"
            ></test-enhancer>
          </div>

          <div v-if="showOverview" class=" mx-3">
            <overview
              ref="overview"
              :items="items"
              :loading="loading"
            ></overview>
          </div>
        </div>
      </div>
    </div>

    <!-- Console modal start -->
    <div class="console-modal-container">
      <modal :show.sync="showTestConsoleModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("view_item_page.test_runner_console_logs") }}
          </h5>
        </template>
        <div>
          <div class="console-modal">
            <div class="row">
              <div class="col">
                <div v-if="stdout" class="script-first">
                  <h2>{{ $t("view_item_page.console_log_for_stdout") }}</h2>
                  <div class="script">
                    <pre>{{ stdout }}</pre>
                  </div>
                </div>
                <div v-else class="script-first">
                  <h2>
                    {{ $t("view_item_page.no_logs_available_as_script") }}
                  </h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div v-if="stderr" class="script-second">
                  <div class="script-heading">
                    <h2>{{ $t("view_item_page.console_log_for_stderr") }}</h2>
                    <i
                      v-if="errCopied"
                      class="fa fa-check"
                      aria-hidden="true"
                      @click="errCopied = false"
                    ></i>
                    <i
                      v-else
                      class="ni ni-single-copy-04"
                      @click="copyStderr"
                    ></i>
                  </div>
                  <div class="script">
                    <pre>{{ renderStderr() }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Console modal end -->

    <!-- Websocket modal start -->
    <div class="websocket-modal-container">
      <modal :show.sync="showWebSocketLogsModal">
        <template slot="header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("view_item_page.websocket_live_logs") }}
          </h5>
        </template>
        <div>
          <div class="websocket-modal" v-if="webSocketServerData">
            <div class="row">
              <div class="col">
                <div
                  :class="[
                    {
                      'log-status-blink': webSocketLogStatus === 'processing',
                      'log-status': webSocketLogStatus !== 'processing',
                    },
                  ]"
                >
                  <h2>
                    {{ $t("view_item_page.logs_status") }}
                    <mark>{{ webSocketLogStatus }}</mark>
                  </h2>
                </div>

                <div class="script-cont">
                  <div class="script" id="WebSocketLiveLogs">
                    <pre><div
                      v-for="(val, index) in webSocketServerData"
                      :key="index"
                    >{{ val }}</div></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <!-- Websocket modal end -->

    <div class="add-test-modal">
      <modal :show.sync="rerunTestStepModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("view_item_page.rerun_test_with") }}
          </h5>
        </template>
        <div>
          <div class="add-test-modal">
            <div class="run-name">
              <label class="model-label is-required" for="run_name">
                {{ $t("view_item_page.run_name") }}
              </label>
              <input
                type="text"
                id="run_name"
                name="run_name"
                :placeholder="$t('view_item_page.placeholder_run_name')"
                v-model="run_name"
              />
              <label class="model-label" for="run_description">{{
                $t("view_item_page.description")
              }}</label>
              <textarea
                id="run_description"
                name="run_description"
                rows="2"
                :placeholder="$t('view_item_page.placeholder_run_description')"
                v-model="run_description"
              ></textarea>
              <label class="model-label" for="run_name">
                {{ $t("view_item_page.run_tags") }}
              </label>
              <input
                type="text"
                id="run_tags"
                name="run_tags"
                :placeholder="$t('view_item_page.placeholder_run_tags')"
                v-model="run_tags"
              />
            </div>

            <div class="s-a-tabs">
              <ul class="nav nav-tabs nav-justified">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('settings')"
                    :class="{ active: isActive('settings') }"
                    href="#home"
                    >{{ $t("view_item_page.settings") }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    @click.prevent="setActive('advanced')"
                    :class="{ active: isActive('advanced') }"
                    href="#profile"
                    >{{ $t("view_item_page.advanced") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content py-3" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('settings') }"
                  id="settings"
                >
                  <div class="mt-2">
                    <h4 class="fbb">{{ $t("view_item_page.test_script") }}</h4>
                    <div class="three-input">
                      <div class="tic">
                        <label class="model-label" for="browser_width">{{
                          $t("view_item_page.browser_width")
                        }}</label>
                        <input
                          type="text"
                          id="browser_width"
                          name="browser_width"
                          placeholder="1920"
                          v-model="browser_width"
                        />
                      </div>
                      <div class="tic">
                        <label class="model-label" for="browser_height">{{
                          $t("view_item_page.browser_height")
                        }}</label>
                        <input
                          type="text"
                          id="fname"
                          name="browser_height"
                          placeholder="1980"
                          v-model="browser_height"
                        />
                      </div>

                      <div class="tic">
                        <label class="model-label" for="max_wait_time">{{
                          $t("view_item_page.max_wait")
                        }}</label>
                        <input
                          type="text"
                          id="max_wait_time"
                          name="max_wait_time"
                          placeholder="5.0"
                          v-model="max_wait_time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('advanced') }"
                  id="advanced"
                >
                  <div class="modal-checks-container">
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_disabled_elements"
                        name="ignore_disabled_elements"
                        v-model="ignore_disabled_elements"
                      />
                      <label for="ignore_disabled_elements">{{
                        $t("view_item_page.ignore_disabled")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="ignore_invisible_elements"
                        name="ignore_invisible_elements"
                        v-model="ignore_invisible_elements"
                      />
                      <label for="ignore_invisible_elements">{{
                        $t("view_item_page.ignore_invisible")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="collect_page_interaction_data"
                        name="collect_page_interaction_data"
                        v-model="collect_page_interaction_data"
                      />
                      <label for="collect_page_interaction_data">{{
                        $t("view_item_page.collect_interaction")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="extra_element_data"
                        name="extra_element_data"
                        v-model="extra_element_data"
                      />
                      <label for="extra_element_data">{{
                        $t("view_item_page.collect_extra_element")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="all_suggest"
                        name="all_suggest"
                        v-model="all_suggest"
                      />
                      <label for="all_suggest">{{
                        $t("view_item_page.generate_selectors")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="downweight_strings"
                        name="downweight_strings"
                        v-model="downweight_strings"
                      />
                      <label for="downweight_strings">{{
                        $t("view_item_page.try_to_avoid")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="verify_suggested_selectors"
                        name="verify_suggested_selectors"
                        v-model="verify_suggested_selectors"
                      />
                      <label for="verify_suggested_selectors">{{
                        $t("view_item_page.verify_suggested")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="enable_integrations"
                        name="enable_integrations"
                        v-model="enable_integrations"
                      />
                      <label for="enable_integrations">{{
                        $t("view_item_page.trigger_integrations")
                      }}</label>
                    </div>
                    <div class="modal-checks">
                      <input
                          type="checkbox"
                          id="generate_css_selectors"
                          name="generate_css_selectors"
                          v-model="generate_css_selectors"
                      />
                      <label for="generate_css_selectors">
                        Generate CSS selectors during run (slow, needed for Cypress export)
                      </label>
                    </div>

                    <br />

                    <label class="model-label mt-3" for="testrun_start_url">
                      Environment start URL for test run
                    </label>
                    <input
                        id="testrun_start_url"
                        name="testrun_start_url"
                        placeholder="https://example.com"
                        v-model="testrun_start_url"
                    />

                    <label class="model-label" for="bad_attributes_list">{{
                      $t("view_item_page.avoid_attributes")
                    }}</label>
                    <textarea
                      id="bad_attributes_list"
                      name="bad_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_avoid_attributes')
                      "
                      v-model="bad_attributes_list"
                    ></textarea>

                    <label
                      class="model-label"
                      for="good_attributes_list"
                      >{{ $t("view_item_page.use_attributes") }}</label
                    >
                    <textarea
                      id="good_attributes_list"
                      name="good_attributes_list"
                      rows="2"
                      :placeholder="
                        $t('view_item_page.placeholder_use_attributes')
                      "
                      v-model="good_attributes_list"
                    ></textarea>
                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="continue_test_suite_on_fail"
                        name="continue_test_suite_on_fail"
                        v-model="continue_test_suite_on_fail"
                      />
                      <label for="continue_test_suite_on_fail">{{
                        $t("view_item_page.continue_test_suite")
                      }}</label>
                    </div>

                    <div class="modal-checks">
                      <input
                        type="checkbox"
                        id="timeout_cancel_run"
                        name="timeout_cancel_run"
                        v-model="timeout_cancel_run"
                      />
                      <label for="timeout_cancel_run">{{
                        $t("view_item_page.timeout_and_cancel")
                      }}</label>
                      <input
                        class="mod-chk-last-input"
                        type="text"
                        id="timeout_cancel_run_value"
                        name="timeout_cancel_run_value"
                        v-model="timeout_cancel_run_value"
                        :placeholder="
                          $t('view_item_page.placeholder_timeout_and_cancel')
                        "
                      />
                      {{ $t("view_item_page.seconds") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="rerunTestStepModal = false">{{
              $t("view_item_page.close")
            }}</base-button>
            <base-button type="primary" @click="rerunWithCurrentSteps()">{{
              $t("view_item_page.start_rerun")
            }}</base-button>
          </div>
        </template>
      </modal>
    </div>

    <div class="is-scrollable webhook--modal">
      <modal :show.sync="toggleShareModal" v-loading="gettingShareUrl">
        <template slot="header">
          <h5 class="modal-title">
            {{ $t("view_item_page.share_this_item")  }}
          </h5>
        </template>
        <div class="mt--3">
          <div class="mod--content">
            <el-form
              class="small-spaces"
              @submit.native.prevent="processShare()"
            >
              <label>{{ $t("view_item_page.permissions") }}</label>
              <el-checkbox-group v-model="editedShareValue.permissions">
                <el-checkbox-button
                  v-for="elt in permissionOptions"
                  :label="elt.value"
                  :key="elt.id"
                  >{{ elt.label }}</el-checkbox-button
                >
              </el-checkbox-group>
              <div class="edit-accordion-btns mt-3 ml--2 mb-4">
                <base-button
                  type="primary"
                  native-type="submit"
                  :disabled="!canGenerate"
                >
                  {{ $t("view_item_page.generate_shareable_url") }}
                </base-button>
              </div>
              <template v-if="shareableUrl.length > 0">
                <hr class="my--1" />
                <el-form-item label="Shareable URL" class="mt-3">
                  <el-input
                    :readonly="true"
                    ref="input"
                    placeholder="Shareable url value should be provided here ..."
                    v-model="shareableUrl"
                  >
                    <template slot="append">
                      <el-tooltip content="Copy trigger URL" placement="top">
                        <span
                          class="h--copy"
                          style="cursor: pointer"
                          id="clipboard_copy"
                          data-copytarget="#exampleFormControlTextarea3"
                          @click="handleCopy2()"
                        >
                          <i class="el-icon-copy-document"></i>
                        </span>
                      </el-tooltip>
                    </template>
                  </el-input>
                </el-form-item>
                <el-alert type="warning" show-icon class="mb-3">
                  <template slot="title">
                    Make sure to copy your <code>Shareable url</code> above
                  </template>
                </el-alert>
              </template>
            </el-form>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import Suggestions from "./Suggestions";
import TestEnhancer from "./TestEnhancer";
import testRunSteps from "./testRunSteps";
import Overview from "./Overview";
import axios from "axios";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export var runActive = false;
export default {
  layout: "DashboardLayout",
  components: {
    Overview,
    TestEnhancer,
    testRunSteps,
    Suggestions,
    BaseButton,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],
  data() {
    return {
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      testrun_start_url: "",
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      generate_css_selectors: false,
      activeItem: "settings",
      // For rerun test with current steps end
      showRunnerLogButton: true,
      webSocketLogStatus: null,
      hideWebSocketLogBtn: true,
      webSocketServerData: [],
      showWebSocketLogsModal: false,
      rerunTestStepModal: false,
      videoSrc: "",
      errCopied: false,
      stdout: "",
      stderr: "",
      showTestConsoleModal: false,
      showVideoModal: false,
      runActive: this.runActive,
      overviewPieChart: {
        title: {
          text: "Time saved",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["Enhancements", "Auto Heal"],
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: 335,
                name: "Enhancements",
                itemStyle: {
                  color: "#FCA311",
                },
              },
              {
                value: 310,
                name: "Auto Heal",
                itemStyle: {
                  color: "#4ac3db",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
          },
        ],
      },
      colorPalette: "red",
      showSeleniumIcons: false,
      shareableUrl: "",

      log: console.log,
      file: "",
      loading: true,
      showOverview: true,
      showSuggestions: false,
      showTestRunSteps: false,
      showTestEnhancer: false,
      showAllSuggest: false,
      toggleAddStepModal: false,
      toggleReorderStepModal: false,
      date_at: new Date(),
      items: {},
      RLJson: {},
      RLtestcase: {
        sequence: {},
        variables: {},
      },
      RLdatafile: {},
      rl_sequence: {},
      id: "",
      enabled: true,
      list: [
        { name: "Lorem", id: 0 },
        { name: "Ipsum", id: 1 },
        { name: "Dolor", id: 2 },
      ],
      newShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      editedShareValue: {
        shareableId: "",
        shareableItem: "",
        regenerate: 0,
        permissions: [],
        shareUrl: "",
      },
      permissionOptions: [
        { label: this.$t("view_item_page.read_only"), value: "read" },
        {
          label: this.$t("view_item_page.edit_item_properties"),
          value: "edit",
        },
        { label: this.$t("view_item_page.rerun_this_item"), value: "rerun" },
      ],
      dragging: false,
      canGenerate: true,
      showTestButtons: "test_run_steps",
      disableDwnCoverageBtn: true,
      toggleShareModal: false,
      gettingShareUrl: false,
      lookedSalesforceIntegration: false,
      loading2: false,
      loadingSalesforce: false,
      sharedJSON: {},
    };
  },
  methods: {
    //#region helpers
    handleCopy2() {
      const ipt = this.$refs["input"].$el.children[0];
      ipt.select();
      document.execCommand("copy");
      this.$notify({
        type: "success",
        message: "Copied to the Clipboard!",
      });
    },
    openWebSocketModal() {
      this.showWebSocketLogsModal = true;
    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    forToggleCoverageReportBtn() {
      const id = this.$route.params.id;
      const flag = "No Run ID found for given user_id and request_id";
      axios
        .get(`${process.env.VUE_APP_API_URL_PREFIX}/rl/v1/${id}`)
        .then((res) => {
          if (res.data.results.status === flag) {
            this.disableDwnCoverageBtn = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleTestBtn(a) {
      this.showTestButtons = a;
    },
    showRerunModalWithCurrentSteps() {
      this.rerunTestStepModal = true;
      const currentTest = this.items.runOptions;
      this.run_name = currentTest.testSuiteId
        ? currentTest.testSuiteName
        : currentTest.test_run_name;
      this.run_description = currentTest.test_run_description;
      this.ignore_disabled_elements = currentTest.filter_disabled_elements;
      this.ignore_invisible_elements = currentTest.filter_invisible_elements;
      this.run_tags = currentTest.runTags
        .filter((item) => {
          return item !== "test run retry" && item !== "integration enabled";
        })
        .join(", ");

      this.extra_element_data = currentTest.extra_element_data;
      this.all_suggest = currentTest.allsuggest_enabled;
      this.collect_page_interaction_data = currentTest.use_tg_analyze;
      this.handle_failure = currentTest.handle_failure;
      this.enable_integrations = currentTest.runTags.includes(
        "integration enabled"
      );
      this.downweight_strings = !!currentTest["xpath_downweight_strings"];
      this.verify_suggested_selectors = !!currentTest[
        "run_selector_verification"
      ];
      this.testrun_start_url = currentTest["testrun_start_url"];
      this.generate_css_selectors = currentTest["generate_css_selectors"];
      this.bad_attributes_list = currentTest["bad_attribute_list"];
      this.good_attributes_list = currentTest["good_attribute_list"];
      if (
        currentTest["user_set_timeout"] !== null &&
        currentTest["user_set_timeout"] !== undefined
      ) {
        this.timeout_cancel_run = true;
        this.timeout_cancel_run_value = currentTest["user_set_timeout"];
      }
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    renderStderr() {
      let txt = this.stderr;
      for (var i = 0; i < txt.length; i++) {
        if (txt.slice(i, i + 5) == "[INFO") {
          // console.log(txt.slice(i, i + 5));
        }
      }
      return txt;
    },
    openScriptModal() {
      this.showTestConsoleModal = true;
    },
    copyStderr() {
      navigator.clipboard.writeText(this.stderr).then(() => {
        this.$notify({
          type: "success",
          message: "Copied Successfully.",
        });
      });
      this.errCopied = true;
    },
    getOverview() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    goBack() {
      this.$router.push({
        name: "List Items",
      });
    },
    showComponent(name) {
      // this.activecomponent = name;
      this.showOverview = false;
      this.showTestRunSteps = false;
      this.showTestEnhancer = false;
      this.showSuggestions = false;
      this.showAllSuggest = false;

      if (this.items.allSuggestResults) {
        this.showAllSuggest = true;
      }

      if (name === "overview") {
        this.showOverview = true;
      } else if (name === "suggestions") {
        this.showSuggestions = true;
      } else if (name === "testenhancer") {
        this.showTestEnhancer = true;
      } else if (name === "test-run-steps") {
        this.showTestRunSteps = true;
      }
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
    setRunStatus(status) {
      this.item.runStatus = status;
    },
    removeImage() {
      this.file = null;
    },
    formatRequired(row, col, value, index) {
      if (value) {
        return "Y";
      } else {
        return "N";
      }
    },
    //#endregion

    //#region async
    async initWebsocketStreaming() {
      const id = this.$route.params.id;
      const auth_token = localStorage.getItem("vue-authenticate.access_token");

      let url = "";
      if (process.env.VUE_APP_WEBSOCKET_API_URL === undefined) {
        url = `wss://${window.location.host}/interceptor/results/v1/logs/${id}`;
      } else {
        url = `${process.env.VUE_APP_WEBSOCKET_API_URL}/results/v1/logs/${id}`;
      }
      const socket = new WebSocket(url);

      const errorMsg = `ERROR while processing log stream for test request ID: ${id}. Internal server error.`;
      const errorMsg2 = `ERROR while processing log stream for test request ID: ${id}. This request ID does not exist or the log stream has expired.`;

      socket.onopen = function(e) {
        socket.send(`AUTH ${auth_token}`);
      };

      socket.onmessage = (event) => {
        if (event.data === errorMsg || event.data === errorMsg2) {
          socket.onclose = function(event) {
            console.log(
              "The connection has been closed successfully View item Page",
              event
            );
          };
          this.hideWebSocketLogBtn = false;
          this.showRunnerLogButton = true;
        } else {
          this.webSocketLogStatus = JSON.parse(event.data).status;
          JSON.parse(event.data).lines.map((item, index) => {
            this.webSocketServerData.push(item.line);
          });
          this.hideWebSocketLogBtn = true;
          this.showRunnerLogButton = false;
        }
      };
    },
    async openVariables() {
      await this.$refs.testEnhancer.toggleVariableModal();
    },
    async showRunSteps() {
      await this.$refs.testEnhancer.showRunSteps();
    },
    async rerunWithCurrentSteps() {
      let requestId = this.items.requestId;

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }
      let reRunWithUpdatedFields = {
        runName: this.run_name,
        runDesc: this.run_description,
        runTags: parsedRunTags.join(","),
        browserWidth: this.browser_width,
        browserHeight: this.browser_height,
        stepWait: this.max_wait_time,
        ignoreInvisible: this.ignore_invisible_elements,
        ignoreDisabled: this.ignore_disabled_elements,
        slowHeal: this.extra_element_data,
        allSuggest: this.all_suggest,
        tgAnalyze: this.collect_page_interaction_data,
        runnerUserTimeout: this.timeout_cancel_run_value,
        runSelectorVerification: this.verify_suggested_selectors,
        badAttributeList: this.bad_attributes_list,
        goodAttributeList: this.good_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        generateCssSelectors: this.generate_css_selectors,
      };

      if (this.testrun_start_url && this.testrun_start_url.length > 0) {
        reRunWithUpdatedFields["runStartUrl"] = this.testrun_start_url;
      }

      if (requestId) {
        try {
          await axios
            .post(
              process.env.VUE_APP_API_URL_PREFIX +
                `/testrun/v1/steps/${requestId}/redo`,
              reRunWithUpdatedFields
            )
            .then((response) => {
              if (response.data.requestId && response.status === 202) {
                this.$notify({
                  type: "success",
                  message: `Rerun has been ${response.statusText}`,
                });
                this.rerunTestStepModal = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Rerun is unsuccessful`,
          });
        }
      }
    },
    async get() {
      try {
        this.loading = true;
        this.loading2 = true;
        const id = this.$route.params.id;
        await this.$store.dispatch("items/get", id);
        this.items = this.$store.getters["items/item"];
        this.forToggleCoverageReportBtn();

        this.$nextTick(() => {
          this.$refs.suggestions && this.$refs.suggestions.getSuggestions();
          this.$refs.suggestions && this.$refs.suggestions.getAllSuggestions();

          this.$refs.testEnhancer && this.$refs.testEnhancer.initializeState();
          // this.$refs.testEnhancer && this.$refs.testEnhancer.pollResults();

          this.$refs.testRunSteps && this.$refs.testRunSteps.getTestRunSteps();
        });
        if (this.items.subprocess) {
          this.stdout = this.items.subprocess.stdout;
          this.stderr = this.items.subprocess.stderr;
        }

        this.id = id;
        let index = 0;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async downloadTestFile(type) {
      let message = ""
      if (type === "csv") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testrun/v1/export/${
              this.items.requestId
            }/csv?walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "katalonxml") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "JSON") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-python-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "generated-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            message = ""
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "allsuggest-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "verified-cypress-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-seleniumpy-pom") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "verified-pypom-script") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=${type}&walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            if (error.response.data.status === "failed") {
              this.$notify({
                type: "danger",
                message: "Script file is not available for this test",
              });
            }
          });
      } else if (type === "pdf") {
        return (
          process.env.VUE_APP_API_URL_PREFIX +
          `/testrun/v1/export/${
            this.items.requestId
          }/pdf?walToken=${localStorage.getItem(
            "vue-authenticate.access_token"
          )}`
        );
      } else if (type === "report-pdf") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testrun/v1/export/${
              this.items.requestId
            }/pdf?walToken=${localStorage.getItem(
              "vue-authenticate.access_token"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "rebuild-side-file") {
        await axios
          .get(
            process.env.VUE_APP_API_URL_PREFIX +
            `/testscript/v1?requestId=${
              this.items.requestId
            }&fileType=JSON&walToken=${localStorage.getItem(
              "testgold.aioToken"
            )}`
          )
          .then((response) => {
            const respdata = response.data;
            const fileName = respdata.fileName;
            const fileURL = respdata.url;
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.$notify({
        type: "success",
        message: "Downloading file now! " + message,
      });
    },
    async handleSubmit() {
      if (this.$isDemo == 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default items.",
        });
        return;
      }
      try {
        await this.$store.dispatch("profile/me");
        this.user = {
          ...this.$store.getters["profile/me"],
        };
        if (this.user.roles[0].name === "member") {
          this.$notify({
            type: "danger",
            message: "Oops, you are not authorized to do this action.",
          });
          return;
        }
        if (this.file) {
          await this.$store.dispatch("items/upload", {
            item: this.item,
            image: this.file,
          });
          this.item.image = await this.$store.getters["items/url"];
        }
        this.item.tags = [];
        this.tags.forEach((tag) => {
          this.item.tags.push({
            id: tag,
            type: "tags",
          });
        });
        await this.$store.dispatch("items/update", this.item);
        this.$notify({
          type: "success",
          message: "Item updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Oops, cannot add/edit. If problem persist, please contact support",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    async openVideoModal() {
      this.showVideoModal = true;
      const aioToken = localStorage.getItem("vue-authenticate.access_token");
      const requestId = this.items.requestId;
      let videoUrl =
        process.env.VUE_APP_API_BASE_URL +
        `/video-presigned/v1?walServerRequestid=${requestId}&walToken=${aioToken}`;
      await axios
        .get(videoUrl)
        .then((response) => {
          if (response) {
            this.videoSrc = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Video is not available for the test run");
          this.videoSrc = "404";
        });
    },
    async downloadEnhancerReport() {
      await this.$refs.testEnhancer.startDownloadingEnhancerReport();
    },
    async openListFileVariables() {
      await this.$refs.testEnhancer.toggleFileVariableModal();
    },
    async openListSalesforceVariables() {
      await this.$refs.testEnhancer.toggleSalesforceVariableModal();
      await this.setSalesforceVar();
    },
    async setSalesforceVar() {
      this.loadingSalesforce = true;
      if (
        this.items.testStepList[0].inputValue.includes("my.salesforce.com") ||
        this.items.testStepList[0].inputValue.includes("salesforce.com") ||
        this.items.testStepList[0].inputValue.includes("lightning.force.com")
      ) {
        try {
          await axios
            .get(process.env.VUE_APP_API_URL_PREFIX + `/rl/v1/salesforce`)
            .then((response) => {
              if (response.status === 200) {
                this.lookedSalesforceIntegration = true;
                this.items.salesforcevar = response.data.results;
                this.loadingSalesforce = false;
              }
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `an error occurred while checking the salesforce integration `,
          });
          this.loadingSalesforce = false;
        }
      }
    },
    //#endregion
  },

  computed: {},
  watch: {
    webSocketServerData: {
      handler() {
        const container = this.$el.querySelector("#WebSocketLiveLogs");
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
      },
      deep: true,
    },
  },

  async created() {
    await Promise.all([
      this.initWebsocketStreaming(),
      this.get(),
      this.forToggleCoverageReportBtn(),
    ]);

    const walToken = localStorage.getItem("vue-authenticate.access_token");
    this.sharedJSON = Object.assign({}, this.parseJwt(walToken));
  },
};
</script>
