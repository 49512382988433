<template>
  <div class="container--graph" style="">
    <button v-if="isFullscreen" class="btn-close-fullscreen" @click="$parent.fullSize = false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"/></svg></button>

    <div :class="isFullscreen ? 'fullsize-map' : ''" v-if="this.$props.items.nodes.length > 0" class="wrapper-graph" id="echart-main" ref="wrapper-graph">
    </div>
    <!-- <base-button  @click="showModalGallery"> Show Gallery </base-button> -->
    <el-button class="button--trigger" icon="el-icon-s-fold" circle></el-button>
    <div class="right--gallery is-hide">
      <div class="container--gallery">
        <span class="close-gallery" id="close-gallery"
          style="cursor: pointer;top: 218px; right: 43px; position: fixed; font-size: 40px;">&times;</span>
        <div v-if="!isNoImage">
          <div class="slider-container--img" v-for="(item, index) in allData.nodes" :key="index">
            <img v-if="item.screenshot" class="slider-img" :src="`data:image/png;base64,` + item.screenshot"
              style="width: 90%;" @click="onTap(index)" />
          </div>
        </div>
        <div v-else class="no-img"><label>No screenshot for this appmap</label></div>
        <!-- <slider ref="slider" :options="optionsSlider" @slide='slide' @onTap="onTap" @init='onInit'>
          <slideritem v-for="(item, index) in allData.nodes" :key="index" v-if="item.screenshot !== null"
            style="width: 600px;" :class="'index-' + index">
            <img :src="`data:image/png;base64,` + item.screenshot" style="width: 60%;" @click="onTap" />
          </slideritem>
          <div slot="loading">loading...</div>
        </slider> -->
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import { Loading } from 'element-ui';
import * as echarts from 'echarts';
import axios from "axios";

export default {
  props: {
    isFullscreen:{
      type: Boolean,
      default : false
    },
    items: {
      type: Object,
      default: () => [],
      description: "List of items to show",
    },
    highlight_edges: {
      type: Array,
      default: () => null,
      description: "List of items highlights",
    },
    nameApp: {
      type: String,
      default: () => [],
      description: "Name of App"
    }
  },
  data: () => ({
    networkEvents: "",
    counterForNodes: 0,
    counterForEdges: 0,
    allData: null,
    hoverNode: null,
    optionsSlider: {
      direction: 'vertical',
      currentPage: 0,
      speed: 300,
      itemAnimation: true,
      centeredSlides: true,
      thresholdDistance: 100,
      thresholdTime: 300,
      loopedSlides: 2,
      slidesToScroll: 1,
      loop: true
    },
    myChartDef: null,
    highlight_edgesValue: [],
    highlight_edgesId: [],
    indexSlide: null,
    showGallery: false,
    isNoImage: false,
    reworkNetwork: false,
  }),
  components: {
    slider,
    slideritem,
  },
  async created() {
    this.highlight_edgesValue = this.$props.highlight_edges
    await this.initEchartsGraph()
  },
  methods: {
    async initEchartsGraph() {

      await this.beforeDrawingNodes()
      await this.beforeDrawingEdges()
      let chartDom = document.querySelector(".wrapper-graph")
      console.log("chartDom", chartDom)
      let containerGraph = document.getElementById("echart-main")
      containerGraph.style.height = (window.innerHeight - 150) + "px" 
      console.log("containerGraph", containerGraph.style)
      let btnTrigger = document.querySelector(".button--trigger")
      let rightGallery = document.querySelector(".right--gallery")
      let containerGallery = document.querySelector(".container--gallery")
      let btnClose = document.querySelector(".close-gallery")
      let myChart = echarts.init(chartDom, null, { renderer: 'svg' });
      // chartDom.style.height = containerGraph.clientHeight
      // chartDom.style.width = containerGraph.clientWidth
      let option;
      let tooltipDisplay = ''
      let count = 0
      let highlight = false
      let nodeIndex = []
      let isShowToolBox = !this.isFullscreen
      myChart.showLoading();
      option = {
        tooltip: {
          formatter: (params) => {
            return tooltipDisplay
          }
        },
        toolbox: {
          show: isShowToolBox,
          feature: {
            // dataView: {
            //   show: true, readOnly: true, iconStyle: {
            //     borderColor: "#1F213E",
            //   }
            // },
            restore: {
              show: true, iconStyle: {
                borderColor: "#1F213E",
              }
            },
            saveAsImage: {
              show: true, iconStyle: {
                borderColor: "#1F213E",
              }
            },
            dataZoom: {
              show: true, iconStyle: {
                borderColor: "#1F213E",
              }
            },
            // magicType: {
            //   type: ['line', 'bar', 'stack'],
            //   show: true
            // },
            // brush: {
            //   type: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear']
            // }
          }
        },
        title: {
          text: this.$props.nameApp,
          top: 'bottom',
          left: 'left'
        },
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            name: this.$props.nameApp,
            type: 'graph',
            layout: 'force',
            data: this.$props.items.nodes,
            links: this.$props.items.links,
            categories: ["TEST"],
            roam: true,
            zoom: 8,
            label: {
              position: 'right',
              formatter: '{b}'
            },
            edgeSymbol: ['', 'arrow'],
            lineStyle: {
              color: '#1F213E',
              curveness: 0.3
            },
            force: {
              edgeLength: this.$props.items.nodes.length < 10 ? 60 : 35,
              repulsion: this.$props.items.nodes.length < 10 ? 60 : 35,
              gravity: this.$props.items.nodes.length < 10 ? 0.5 : 0.2,
              friction: this.$props.items.nodes.length < 10 ? 0.5 : 0.1,
            },
            scaleLimit: {
              min: 1,
              max: 15
            },
            emphasis: {
              scale: this.$props.items.nodes.length < 10 ? 1.5 : 2.5,
              focus: 'adjacency',
              lineStyle: {
                width: 10
              },

            },

          }
        ]
      };
      console.log("my chart", myChart)
      myChart.hideLoading();
      myChart.on("dblclick", (e) => {
        if (e.dataType === "node") {
          this.onDoubleClick(e.data)
        }
      })
      let dataType = ''
      myChart.on('mouseover', (params) => {
        if (params.dataType == "edge") {
          dataType = ''
          tooltipDisplay = params.data.title
        } else if (params.dataType === 'node') {
          dataType = "node"
          tooltipDisplay = params.data.name
        }
      });
      myChart.on('restore', (e) => {
        setTimeout(() => {
          console.log('dispose', this.$refs['wrapper-graph'])
        myChart.dispose(this.$refs['wrapper-graph'])
        this.initEchartsGraph() 
        }, 1000);
      })
      if (this.indexSlide !== null) {
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.indexSlide
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: this.indexSlide
        });
      }

      btnTrigger.addEventListener("click", (e) => {
        rightGallery.classList.remove("is-hide")
        rightGallery.classList.toggle("is-show")
        containerGallery.classList.toggle("gallery-open")
        btnTrigger.style.display = "none"
        btnClose.style.display = "block"
      })
      btnClose.addEventListener("click", () => {
        rightGallery.classList.remove("is-show")
        rightGallery.classList.toggle("is-hide")
        containerGallery.classList.remove("gallery-open")
        btnTrigger.style.display = "block"
        btnClose.style.display = "none"
      })

      myChart.setOption(option);
      if (this.reworkNetwork) {
        console.log("restore appmap")
        myChart.dispatchAction({
          type: 'restore'
        })
        this.reworkNetwork = false
      }
    },
    showModalGallery() {
      this.showGallery = true
    },
    beforeDrawingNodes() {
      console.log("beforeDrawingNodes", this.$props.items.nodes.length)
      let countNoImage = 0
      this.allData = this.$props.items
      for (let node of this.$props.items.nodes) {
        if (node.screenshot !== null) {
          if (node.screenshot.indexOf("data:image/png;base64,") === -1) {
            node.symbol = `image://data:image/gif;base64,${node.screenshot}`;
            node.shape = "image";
            node.value = 1
            node.color = "#5603AD"
            node.name = node.label
            node.category = "0"
            node.id = `${node.id}`
            node.symbolSize = this.$props.items.nodes.length < 10 ? [40, 40] : [20, 20]
          }
        } else {
          countNoImage++
        }
      }
      if (countNoImage === this.allData.nodes.length) {
        this.isNoImage = true
      }
    },
    slide() {
      console.log("slide")
    },
    async onTap(index) {
      console.log("Tap", index)
      this.indexSlide = index
      console.log(this.indexSlide)
      await this.initEchartsGraph()
    },
    onInit() {
      console.log("Init")
    },
    onDoubleClick(ctx) {
      let buttonShow = document.querySelector(".btn-graph--show")
      let buttonClear = document.querySelector(".btn-graph--clear")
      let closeGraph = document.getElementById("close-img")
      let imgModal = document.getElementById("imgModal");
      let modalImg = document.getElementById("screenshot");
      imgModal.style.display = "block";
      this.getAppMapScreen(modalImg, ctx.id, ctx.screenshot)
      // modalImg.src = img.image;
      buttonShow.addEventListener("click", () => {
        buttonShow.style.display = 'none'
        buttonClear.style.display = "block"
        JSON.stringify(localStorage.setItem("idNode", ctx.id))
      })
      buttonClear.addEventListener("click", () => {
        buttonClear.style.display = "none"
        buttonShow.style.display = "block"
        localStorage.removeItem("idNode")
      })
      closeGraph.addEventListener("click", () => {
        buttonClear.style.display = "none"
        buttonShow.style.display = "block"
        localStorage.removeItem("idNode")
      })
    },
    async getAppMapScreen(img, id, oldImg) {
      let appMapId = localStorage.getItem("appMapId")
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/appmap?project_id=${this.$route.params.id}&app_map_id=${appMapId}&node_id=${id}`;
      let loadingInstance = Loading.service({ fullscreen: true });
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status <= 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "finished") || res.data.status == 200) {
          console.log(res)
          if (res.data.exploration_results !== null) {
            if (res.data.exploration_results.screenshot !== undefined) {
              img.src = `data:image/png;base64,${res.data.exploration_results.screenshot}`
            } else {
              img.src = `data:image/png;base64,${oldImg}`
            }
          } else {
            img.src = `data:image/png;base64,${oldImg}`
          }
          this.$nextTick(() => { // Loading should be closed asynchronously
            loadingInstance.close();
          });
        } else {
          this.$nextTick(() => { // Loading should be closed asynchronously
            loadingInstance.close();
          });
          img.src = `data:image/png;base64,${oldImg}`
        }
      } catch (error) {
        console.log(error);
      }
    },
    beforeDrawingEdges() {

      // if (this.$props.highlight_edges != null) {
      //   this.$props.highlight_edges.map((item) => {
      //     console.log("item", item)
      //     this.highlight_edgesValue.push(`${item[0]}-${item[1]}`)
      //   })
      // }
      for (let link of this.$props.items.links) {
        link.from = link.source;
        link.source = `${link.source}`
        link.target = `${link.target}`
        //console.log(link.from);
        link.to = link.target;
        let title = link.description;
        if (!title) {
          title = `${link.command}: ${link.element}`;
        }
        link.title = title;
        // link.smooth = true;
        link.length = 200;
        link.color = { color: "#000000" };
        if (link.users_pct !== undefined) {
          link.value = link.users_pct * 50
        }

        if (link.users_pct) {
          title += ` Users: ${(link.users_pct * 100).toFixed(2)} %`
        }
        link.title = title
      }

    },
    restoreNetwork() {
      this.reworkNetwork = true
    },
    updataNetwork() {
      // this.highlight_edgesValue = this.$props.highlight_edges
      this.initEchartsGraph()

    },
  },
};
</script>

<style lang="scss" >
/* @import "~vue-visjs/dist/vue-visjs.css"; */
$color: #5e72e4;
@import "../../../assets/sass/custom/_variablesCustom";

.network {
  font-family: Verdana;
}

.wrapper-graph {
  // padding: 10px 10px;
  height: 120%;
  text-align: center;
}

.events {
  text-align: left;
  height: 70px;
}

.network {
  width: 100%;
  border: 1px solid #ccc;
  margin: 5px 0;
}

// hr:nth-child(2n) {
//   display: none;
// }

.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 5px;
  white-space: nowrap;
  font-family: verdana;
  font-size: 14px;
  color: #000000;
  background-color: #f5f4ed;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #808074;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 5;
}


.vis-zoomExtends {
  position: absolute;
  cursor: pointer;
  right: 1%;
  top: 1%;
  width: 50px;
  height: 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWElEQVR4nO1Z224TMRD1f5TMNJTLa4v4CwjrST4FSgV9qUSF+BMe+8ZbyldQKGrK5bkREvB+0DibaLNXL1u667BHGinarD3nrO3xjG1Mjx7dBEfYJcEXtpjpbxMSOMIuC65YADUS/BhM8NCESJ5DEsEF5IMQwRXkOy2CxrjNFvMVUYs5CU4SpE/W/hdc0QhD0xVQhOdJcjzBHguOEs+O3LOECLLYN13B1hh3yeIrCz4sQ2ZagHs2wR4JzvRdbWO6jDwBQYF7AS0j+BEgwUEijB6Y0EC6Nwgu1PR3uyFS8OwmSQwsdtTnrRHuNOqIBA+Wmw9ZfLw2htV+z5c7uu4djcnH8/jMt+0gwogE71nwW40sTsnicQ3fn5JpSW0RafIuPfAsSkjwuiSRO/bpg1NpRy0RGfI1GuuXr8xGPUeC/0ZEptHC4VsNg3nGYzxNLu542pQKYIvpSrDFjuujoH/1nWo7L50JroatIpC1i9UHsPjlUQ/8XL0vLszW9XdZPAJagHddgMWsVlVFgnds8abADtemkMVpnSlEi0LosKh/53u9fXUwyYiosYh1gXqMwCOfvji7Hr0jYTMRguMSAa/+OfmSs51z4wk3EhZTtyYWNvX98gqy+NyIfK6IssVzzWDBZWPyS+jpgRbgN1nDbi1q7H2OsG3awvYT3CfB9yAK+Dyw4GUiCLwwoYFDLym5F9AyghqBocU9svjmksF4x84TENcaM323U5GJkoe7cdqRFpApUbt0uEsjDOser7e6SW3cBcdGXDFtxCXfRlyzronQsBriRXeP/wl/AKqUUR3/pF7WAAAAAElFTkSuQmCC");
}

.button--trigger {
  position: absolute;
  right: -2%;
  top: 50%;
  background-color: var(--main-blue-color) !important;

  i {
    color: white !important;
    font-size: 1.25rem !important;
  }
}

.right--gallery {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -50%;
  top: 0%;
  height: 100%;
  transition: 0.3s;
}

.right--gallery.is-hide {
  display: block;
  transition: all ease 0.8s !important;
  min-width: 0px !important;
  width: 0px;
}

.container--gallery {
  overflow-y: auto;
  right: -50%;
  position: absolute;
  transition: all ease 0.3s !important;
  width: 400px;
  height: 100%;
  background-color: white;
  border-radius: 2px;
  box-shadow: $card-shadow;
  z-index: 99999;
}

.gallery-open {
  right: 0%;
  transition: all ease 0.3s !important;
}

@media (max-width: 1200px) {
  .container--gallery {
    left: -50%;
  }

  .gallery-open {
    left: 0%;
    transition: all ease 0.3s !important;
  }
}

.no-img {
  margin: 15% 20%;
}

.right--gallery.is-show {
  width: 100%;
  right: 0%;
  transition: all ease 0.8s !important;
  display: block;
}

.slider-pagination-bullet-active {
  background: #000000 none repeat scroll 0 0 !important;
}

.container--graph {
  height: 100%;
}

.vis-zoomExtends:hover {

  border: 2px solid #0000ff;
  border-radius: 32px;
}

#echart-main {

  width: 100%;
}

.slider-container--img {
  margin-left: 10%;
  margin-bottom: 20px;
}

.slider-container--img:nth-child(1n) {
  margin-top: 20px;
}

.slider-img {
  transition: 0.3s;
}

#echart-main {
  div:nth-child(3n) {
    z-index: 99999;
  }
}

.slider-img:hover {
  box-shadow: $card-shadow;

}
.fullsize-map{
  height: 100% !important;
  
  
}
</style>