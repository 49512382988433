<template>
  <div class="card card-profile" v-loading="loading">
    <img src="/img/theme/profile-header.png" alt="Image placeholder" class="card-img-top" />

    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <img v-if="!user.profile_pic" src="/img/placeholder.jpg" class="rounded-circle" />
            <img v-else :src="user.profile_pic" class="rounded-circle" style="background: #fff; padding: 3px" />
          </a>
        </div>
      </div>
    </div>

    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between">
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row mt-5">
        <div class="col">
          <div class="text-center">
            <h5 class="h3" v-if="user">
              <span class="user-name">{{ user.name }}</span>
            </h5>
            <div class="h5 font-weight-300" >
              <span class="d-flex align-items-center justify-content-center tier-name font-weight-bold">
                <i class="ni ni-briefcase-24 mr-2"></i>{{ $t('user_card.subscription_plan') }}&nbsp; <router-link
                  to="/details/subscription">{{ user.tierName }}</router-link>

              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {}
  }
};
</script>
<style>
.user-name,
.tier-name {
  text-transform: capitalize;
}

.small-mb {
  text-transform: capitalize;
}
</style>
