<template>
  <div class="card" v-loading="loading">
    <div class="card-header">
      <h1>{{ $t('user_edit_card.edit_profile') }}</h1>
    </div>
    <div class="card-body" id="userProfileEdit">
      <form ref="profile_form" @submit.prevent="handleProfileUpdate">
        <div class="form-group">
          <label class="form-control-label">{{ $t('user_edit_card.picture') }}</label>
          <div v-if="image" class="profile-image card-img pb-4">
            <img :src="`${image}`" class="profile-image argon-image" />
          </div>
          <div v-else class="profile-image">
            <img
              v-if="!user.profile_pic"
              src="/img/placeholder.jpg"
              class="profile-image argon-image"
            />
            <img
              v-else
              :src="user.profile_pic"
              class="profile-image argon-image"
            />
          </div>
          <div class="image-upload">
            <base-button
              v-if="image"
              type="button"
              class="btn btn-sm btn-danger"
              @click="removeImage"
            >
              <span>
                <i class="fa fa-times" />
                {{$t('user_edit_card.remove')}} 
              </span>
            </base-button>
            <base-button type="button" class="btn btn-sm btn-primary">
              <label v-if="!image" for="imageInput" class="mb-0"
                >{{$t('user_edit_card.select_image')}}</label
              >
              <label v-else for="imageInput" class="mb-0">{{$t('user_edit_card.change')}}</label>
              <input
                id="imageInput"
                ref="imageInput"
                accept="image/*"
                type="file"
                style="display: none"
                @input="onFileChange"
              />
            </base-button>
          </div>
        </div>
        <validation-error :errors="apiValidationErrors.attachment" />
        <base-input
          :label="$t('user_edit_card.name')"
          prepend-icon="fas fa-user"
          placeholder="Your name"
          v-model="user.name"
        />
        <validation-error :errors="apiValidationErrors.name" />
        <base-input
          :label="$t('user_edit_card.email')"
          prepend-icon="fas fa-envelope"
          placeholder="Email"
          v-model="user.email"
        />
        <validation-error :errors="apiValidationErrors.email" />
        <div class="my-4">
          <base-button
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
          >
            {{$t('user_edit_card.submit')}}
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import axios from "axios";

export default {
  name: "UserEditCard",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
  },

  mixins: [formMixin],

  props: {
    user: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      image: null,
      file: null,
      fileBase64: null,
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
        this.fileBase64 = reader.result;
      };
    },

    removeImage() {
      this.image = null;
    },

    async handleProfileUpdate() {
      if (this.$isDemo == 1 && ["1", "2", "3"].includes(this.user.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default users.",
        });
        return;
      } else {
        const url = process.env.VUE_APP_API_URL_PREFIX;
        const data =
          this.file === null
            ? {
                newFullName: this.user.name,
                newEmail: this.user.email,
              }
            : {
                newFullName: this.user.name,
                newEmail: this.user.email,
                newProfilePic: "add",
                file: this.fileBase64,
              };

        try {
          this.loading = true;
          await axios
            .post(`${url}/auth/v1/users/update`, data)
            .then((res) => {
              if (
                res.message &&
                res.message === "Request failed with status code 400"
              ) {
                this.$notify({
                  type: "danger",
                  message:
                    "This user was created via Google OAuth2 signup. Your email address can't be changed because it is associated with your account on that service.",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: "Profile updated successfully",
                });

                setTimeout(() => {
                  window.location.reload();
                }, 500); // Auto reload the page after profile update
              }
            })
            .catch((e) => {
              this.$notify({
                type: "danger",
                message: e.message,
              });
            });

          await this.$store.getters["profile/me"];
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, Cannot edit profile !",
          });
          this.setApiValidation(error.response.data.errors);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
