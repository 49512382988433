<template>
    <div style="display: grid;grid-template-rows: 110px 74vh;overflow: hidden;" class=" right-content container-chat">
        <div class="chat-header">
            <div class="header-links">
                <div><router-link to="/details/test-explorer/list-tests" class=""
            ><i
              class="el-icon-house"
              style="
                color: rgb(86, 3, 173);
                font-size: 18px;
                font-weight: 400;
                margin-top: 1px;
              "
            ></i
          ></router-link>

          <span><i class="el-icon-d-arrow-right mx-1 cb--divider" style="color: black; font-size: 14px; font-weight: 400; margin-left: 5px !important;"></i></span>
          <span>Chat</span></div>
        <div><h3 class="page-name-chat">Chat</h3></div>
            </div>
        </div>
        
        <div style="display:flex;">
        <div class="right-chat--side">
            <div class="btns-of-choose">
                <div class="choose-chat-version-cont">
<!--                <span>To ask questions about exact Project</span>-->
        <el-select v-model="chat_version" style="width: 330px" placeholder="Select Chat Version">
            <el-option 
            v-for="item in chatOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"

            />
        </el-select>
            </div>
            <div class="choose-chat-version-cont">
<!--                    <span >Select App</span>-->
                    <el-select v-model="selectedProject"   placeholder="Select App"
                        class="select-app--history-custom" @change="createNewSession" :loading="loadingSessionChats">
                        <el-option v-for="(item, id) in resultProject" :key="id" :label="item.name" :value="item.name"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            
            <!-- <div class="chat-header"> -->
                <!-- <el-select v-model="selectedVersion" mclearable placeholder="Select Version" class="select--chat">
                    <el-option v-for="(item, id) in versionChat" :key="id" :label="item.label" :value="item.value"
                        class="cstm--option">
                        <span class="select-label">{{ item.label }}</span>
                        <span class="select-decs">{{ item.value }}</span>
                    </el-option>
                </el-select> -->
                <!-- <h1 class="name-chat"> ChatWring</h1> -->
                <!-- <div class="selecters-chat">
                    <el-select v-model="selectedProject" clearable  placeholder="Apps"
                        class="select--app" @change="createNewSession">
                        <el-option v-for="(item, id) in resultProject" :key="id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
            </div> -->
            <!-- <hr class="my-3 cstm--line" style="
                            border-color: rgba(194, 190, 190, 0.1) currentcolor currentcolor;
                            border-style: solid none none;
                            border-width: 1px 0px 0px;
                            border-image: none 100% / 1 / 0 stretch;
                            min-width: 98%;
                            overflow: visible;
                            box-sizing: content-box;
                            height: 0px;
                          " /> -->
            <div class="cstm-container">
                <div :style="{ paddingTop: postedMessages.length !== 0 ? '100px' : '0px' }" class="messages-history" v-if="postedMessages.length !== 0">
                    <div class="container--message" v-for="(message, id) in postedMessages" :key="id">
                        <el-col class="cstm-el--col" :span="15">
                            <el-card style="overflow: visible;" shadow="hover">
                                <div class="header--message">
                                    <img class="avatar--message" alt="avatar"
                                        :src="message.role === 'user' ? '/img/icons/common/user_icon.png' : '/img/brand/wring.png'"
                                        width="30" />
                                    <h4> {{ message.role === "user" ? userName.full_name : "" }} </h4>
                                    
                                </div>
                                <div class="body--message">
                                    <span v-html="message.content"></span>
                                    <!-- <div v-if="message.role != 'user'" :id="'options-message_'+id" class="options-message">
                                    <div style="display: inline-block;"  class="mr-4 rrwew-content video-chat" :id="'rrweb_'+id">
                                        <img v-if="loadingVideo && id === postedMessages.length - 1" src="/img/loading.gif" alt="loading..." style="height: 64px; width: 64px" />
                                     </div>
                                     <div class="btns-option-message">
                                        <button @click="convertApiCalls(id)" style="display: none;" class="add_button" :id="'convert_button_'+id">Convert</button>
                                     <a target="_blank" :id="'test_link_'+id" class="add_button" href="" style="display: none;">Show Test</a>
                                     </div>
                                     <div :id="'chart_'+id" class="custom-chart chart-message" style="width:400px;height:200px;"><v-chart class="chart" :autoresize="true"
                                                    :option="overviewPieChart[id]" /></div>
                                    </div> -->
                                </div>
                            </el-card>
                        </el-col>
                    </div>
                    
                </div>
                <div class="messages-history" v-else>
                    <div class="container--greeting">
                        <img src="/img/brand/wring_black.png" width="100" />
                        <h2 class="hello-text"> How can I help you today?</h2>
                    </div>
                </div>
                <form class="form form-settings" @submit.prevent="handleSubmit">
                    <div class="typing-indicator" v-if="loadingMessage">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="message-place">
                      <el-input class="message-input" placeholder="Please write your message" v-model="message"
                                 >
                            </el-input>
<!--                        <el-tooltip class="item" effect="light"-->
<!--                            :disabled="selectedVersion === '' || selectedProject === '' ? false : true"-->
<!--                            content="Please select chat version and app" placement="top">-->
<!--                            -->
<!--                        </el-tooltip>-->
                        <button :disabled="isSumbit" class="btn-send--message" @click.stop="handleSubmit">
                            <span class="send-mes-icon"></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="left-chat--side">
            <div class="chat-history container">
                <div class="history-header">

                    <el-button class="btn-new--chat" @click="createNewSession()">
                        <div class="new--chat">
                            <!-- <img src="/img/brand/logo_wring.svg" width="40px" /> -->
                            <h2 class="hstr"> New Chat </h2>
                        </div>
                        <svg fill="#1F213E" style="width:24px;height:24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                    </el-button>
                </div>
                <hr class="my-3 cstm--line" style="
                            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
                            border-style: solid none none;
                            border-width: 1px 0px 0px;
                            border-image: none 100% / 1 / 0 stretch;
                            min-width: 98%;
                            overflow: visible;
                            box-sizing: content-box;
                            height: 0px;
                          " />
                <!-- <el-tree ref="elTree" id="elTree" class="custom-tree" :data="projectTree" :props="defaultProps"
                    @node-click="handleNodeClick"></el-tree> -->
                
                <div class="history-messages-name" v-if="showSessionsChat" v-loading="loadingSessionChats">
                    <div style="padding-left: 10px;" v-for="(item, id) in showSelectedSessions" :key="id">
                        <h2 class="project--time"> {{ item.time === 'today' && item.content.length !== 0 ? 'Today :' :
                            item.time === 'yesterday'
                                &&
                                item.content.length !== 0 ? 'Yesterday :' : item.time === '2 days ago' && item.content.length
                                    !==
                                    0 ? '2 Days Ago' : '' }}
                        </h2>
                        <div class="container-project--history" v-for="(mes, index) in item.content" :key="index">
                            <el-tooltip class="item" effect="light" :content="mes.message[0].content" placement="right">
                                <h2 class="project--name" @click="handleShowMessage(mes)"> {{ mes.message[0].title != null ? mes.message[0].title : mes.message[1].project_name }}
                                </h2>
                            </el-tooltip>
                            <span @click="handleClickDelete(mes)" class="trash-icon"></span>
                            <!-- <el-dropdown class="history--del" @command="handleClickDelete(mes)">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-more"></i>
                                </span>
                                <el-dropdown-menu class="cstm-btns" slot="dropdown">
                                    <el-dropdown-item command="del">Delete</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>

import axios from "axios";
import rrwebPlayer from "rrweb-player";
import moment from "moment";
import "moment-timezone";
import VChart from "vue-echarts";
import { text } from "d3";

export default {
    data() {
        return {
            isSumbit : false,
            overviewPieChart : {},
            appmap_id: '',
            chat_version:"WringGPT",
            chatOptions:[
            {
                value: "GPT",
                name:"GPT"
            },
            
            {
                value: "WringGPT",
                name : "WringGPT"
            }
            ],
                
            
            user_message:'',
            showVideo:false,
            message: '',
            loadingProject: false,
            resultMessages: [],
            listProject: [],
            userName: '',
            postedMessages: [],
            selectedVersion: 'WringGpt',
            selectedProject: '',
            resultProject: [],
            // versionChat: ["WringGpt", "Ogpt", "ApiGpt"],
            versionChat: [{
                value: 'Wring’s unique GPT to help you gather insights on user sessions',
                label: 'WringGpt'
            }, {
                value: 'Generalized GPT to help you gain insights on users',
                label: 'Ogpt'
            }, {
                value: 'Wring’s API GPT to help you gather insights on user API calls',
                label: 'ApiGpt'
            }],
            value: '',
            loadingMessage: false,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            loadingVideo : false,
            projectTree: [],
            messageProjectHistory: [],
            projectHistory: [],
            isProjectHistory: false,
            allPostedMessages: [],
            showButtons: false,
            sessionsChats: [],
            sessionId: null,
            sortedSessionsChats: [],
            showSessionsChat: false,
            showSelectedSessions: [],
            userId: null,
            updateSessions: false,
            loadingSessionChats: false,
            apiCallsForConvert: {},
            projectIdForConvert: null
        }
    },
    components: {
        VChart
    },
    async created() {
        this.showSessionsChat = true
        this.loadingSessionChats = true
        this.userName = JSON.parse(localStorage.getItem("wring-user-info"))
        console.log(this.userName)
        if (localStorage.getItem('wring-user-info'))
            this.userId = JSON.parse(localStorage.getItem('wring-user-info'))
        // await this.messageProjects()
        this.createNewSession()
        await this.getProject()
        await this.getSessionsChat()
        this.showSessions()
        
    },
    mounted() {
        console.log("mounted")
        document.querySelector(".messages-history").addEventListener('scroll', () => {
            this.handleDebouncedScroll()
        });
    },

    beforeDestroy() {
        document.querySelector(".messages-history").removeEventListener('scroll', this.handleDebouncedScroll());
    },
    methods: {
        async convertApiCalls(message_id){
            this.loadingVideo = true
            var apiCallsToSend = this.apiCallsForConvert[message_id]
            var dataSending = {
                "project_id" : this.projectIdForConvert,
                "appmap_id" : this.appmap_id,
                "task_type" : 'make_test',
                "front_apicalls" : apiCallsToSend
            }
            var json = JSON.stringify(dataSending)
            var res = await axios
                    .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/appmap_chat", json, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
        
                this.loadingVideo = false
            
                this.$nextTick(() =>{
            var link = JSON.parse(res.data.response.content).optional_data["link"]
            console.log(link)
            var convert_button = document.getElementById(`convert_button_${message_id}`)
            var test_link = document.getElementById(`test_link_${message_id}`)
            convert_button.style.display = "none"
            test_link.style.display = "flex"
            test_link.href = link
                })
            
        },
       async putVideoInAnswer(sessionId,projectId,id){
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", sessionId );
      params.append("page", 1);
      params.append("limit", 150);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      const url = this.$route.query.postHog ? process.env.VUE_APP_API_URL_PREFIX + "/pageviews/snapshots/v2/wring?" + params : process.env.VUE_APP_API_URL_PREFIX + "/pageviews/snapshots/v2/wring?" + params
      const res = await axios.get(url)
      var snapshots = res.data.response.snapshots
      this.showVideo = true
      const rrweb = document.getElementById(`rrweb_${id}`);

      console.log(rrweb)
      this.webPlayer = new rrwebPlayer({
        target: rrweb, // customizable root element
        props: {
          events: snapshots,
          width: 720,
          height: 320,
          autoPlay: true,
          skipInactive: false,
          goTo: 0,
          mouseTail: true,
        },
        liveMode: true,
      });
      this.loadingVideo = false
        
        },
        handleDebouncedScroll() {
            const containerMsg = document.querySelector(".messages-history")
            const scrollTop = containerMsg.scrollTop
            const offset = 20
            const sum = scrollTop + offset
            if (containerMsg.scrollHeight - sum < containerMsg.clientHeight) {
                containerMsg.classList.remove("is-overview-messages")
            } else if (containerMsg.classList[1] !== 'is-overview-messages') {
                containerMsg.classList.add("is-overview-message")
            }
        },
        createNewSession() {
            console.log("created new session!")
            this.resultMessages = []
            this.postedMessages = []
            this.showSelectedSessions = []
            const newId = (() => {
                return String(performance.now()).replace('.', '') + Date.now();
            })
            this.sessionId = newId()
            if (this.selectedProject !== '') {
                this.updateSessions = true
                this.showSessions()
            }

        },
        async handleClickDelete(item) {
            console.log(item)
            this.$confirm('Are you sure to delete this?', 'Warning', {
                customClass:'custom-messagebox',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                confirmButtonClass: 'add_button',
                cancelButtonClass: 'cancel_button'
            }).then(async () => {
                this.$message({
                    type: 'success',
                    message: 'Delete completed'
                });
                const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?user_id=${this.userId.system_id}&app_id=${item.app_id}&session_name=${item.chat_name}`;
                try {
                    axios.defaults.validateStatus = (status) => {
                        // return status >= 200 && status < 401; // to catch 400 error
                        return status === 400 || (status >= 200 && status < 500);
                    };
                    const res = await axios.delete(url)
                    console.log(res)
                    if (res.data.status === 'success') {
                        this.postedMessages = []
                        this.updateSessions = true
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    if (this.updateSessions)
                        await this.getSessionsChat()
                }

            }).catch(() => {
            });
        },
        async handleShowMessage(project) {
            this.apiCallsForConvert = {}
            console.log("click app", project)
            this.postedMessages = project.message
            this.sessionId = project.chat_name
        },
        showSessions() {
            this.showSessionsChat = true
            let selectedProjectId = null
            this.showSelectedSessions = []
            // let selectApp = document.querySelector(".select-app--history-custom")
            this.resultProject.map((item) => {
                if (item.name === this.selectedProject) {
                    selectedProjectId = item.projectId
                }
            })
            // if (this.selectedProject.split('').length > 7) {
            //     selectApp.style.width = "37%"
            //     if (this.selectedProject.split('').length > 10) {
            //         selectApp.style.width = "48%"
            //         if (this.selectedProject.split('').length > 13) {
            //             selectApp.style.width = "50%"
            //             if (this.selectedProject.split('').length > 15) {
            //                 selectApp.style.width = "52%"
            //             }
            //         }
            //     }
            // }
            if (this.updateSessions) {
                this.showSelectedSessions = []
                this.updateSessions = false
            }
            this.sessionsChats.data.map((item) => {
                if (selectedProjectId === item.app_id) {
                    this.showSelectedSessions.push(item)
                }
            })
            console.log("showSelectedSessions", this.showSelectedSessions)
            // this.showSelectedSessions.map((item) => {
            //     if (item.message[0].content.split('').length >= 20) {
            //         let shortMesssage = item.message[0].content.split('', 20)
            //         item.message[0].shortContent = shortMesssage.join('') + "..."
            //     }
            // })
            this.getFilterDate()
            this.loadingSessionChats = false
        },
        async handleSubmit() {
            this.isSumbit = true
            const containerMsg = document.querySelector(".messages-history")
            console.log(this.message)
            if (this.message !== '' && this.message !== " ") {
                this.postedMessages.push({
                    role: "user",
                    content: this.message
                })
                this.user_message = this.message
                this.message = ''

                // Creating title for a session
                let titleChat = ''
                let res = ''
                if(this.postedMessages.length == 1){
                    const dataSendingTitle = {
                            "chat_version": this.selectedVersion,
                            "project_id": project_id,
                            "messages": this.postedMessages,
                            "user_id": this.userId.system_id,
                            "session_name": this.sessionId.toString(),
                            "type" : "title"
                    }

                        res = await axios.post(`/interceptor/pageviews/mixtralchat/v2?engine=openai`,dataSendingTitle)
                        titleChat = res.data.response.title
                    }

                if (containerMsg.clientHeight < containerMsg.scrollHeight) {
                    containerMsg.classList.add("is-overview-messages")
                } else {
                    containerMsg.classList.remove("is-overview-messages")
                }
                let project_id = null
                Array.from(this.resultProject).map((item) => {
                    if (item.name === this.selectedProject)
                        project_id = item.projectId
                })
                
                this.resultMessages.push(
                    {
                        role: "user",
                        type: "text",
                        content: this.user_message,
                        project_id: project_id,
                        project_name: `${this.selectedProject}`,
                        version: this.selectedVersion,
                        title : titleChat
                    }
                )
               
                await this.scrollToBottom()
                
                
                await this.getAnswer()
                this.isSumbit = false
            }
        },
        async scrollToBottom() {
            const containerMsg = document.querySelector(".messages-history")
            setTimeout(() => {
                containerMsg.childNodes[containerMsg.childNodes.length - 1].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }, 500)
        },
        showProjectHistory() {
            console.log(this.selectedProject)
            const containerMsg = document.querySelector(".messages-history")
            if (this.postedMessages.length > 0) {
                this.isProjectHistory = true
                if (this.postedMessages.length >= 5) {
                    containerMsg.classList.add("is-overview-messages")
                } else {
                    containerMsg.classList.remove("is-overview-messages")
                }
            } else {
                containerMsg.classList.remove("is-overview-messages")
                this.isProjectHistory = false
            }

        },
        async getProject() {
            this.loadingProject = true;
            const url = `${process.env.VUE_APP_API_URL_PREFIX}/projects/v1`;
            try {
                axios.defaults.validateStatus = (status) => {
                    // return status >= 200 && status < 401; // to catch 400 error
                    return status === 400 || (status >= 200 && status < 500);
                };
                const res = await axios.get(url);
                if ((res.data && res.data.status == "success") || res.data.status == 200) {
                    this.resultProject = Object.values(res.data.response);
                    this.resultProject.push({name: "All",projectId:"0ll"})
                    this.selectedProject = "All"
                    this.selectedProjectId = "0ll"
                } else {
                    this.resultProject = [];
                }
            } catch (error) {
                console.log("error", error)
            } finally {
                this.loadingProject = false;
            }
        },
        async getSessionsChat() {
            this.loadingSessionChats = true
            const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?user_id=${this.userId.system_id}`;
            try {
                axios.defaults.validateStatus = (status) => {
                    // return status >= 200 && status < 401; // to catch 400 error
                    return status === 400 || (status >= 200 && status < 500);
                };
                const res = await axios.get(url,{ headers: { 'Cache-Control': 'no-cache' } });
                if (res.data.length !== 0) {
                    this.sessionsChats = res.data;
                    this.parsingSessionsChat()
                } else {
                    this.sessionsChats = [];
                }
            } catch (error) {
                console.log("error", error)
            } finally {
                this.loadingSessionChats = false
            }
            if (this.updateSessions) {
                this.showSessions()
            }
        },
        parsingSessionsChat() {
            this.sessionsChats.data.forEach((item) => {
                try {
                    item.message = item.message.toString()
                } catch (error) {
                    console.log(error)
                }

            })
            let tempList = []
            this.sessionsChats.data.forEach((item) => {
                Array.from(this.resultProject).forEach((app) => {
                    if (app.projectId === item.app_id) {
                        tempList.push(item.app_id)
                    }
                })
            })
            let tempSet = new Set(tempList)
            this.listProject = []
            tempSet.forEach((item) => {
                this.resultProject.map((app) => {
                    if (app.projectId === item) {
                        this.listProject.push(app.name)
                    }
                })
            })
        },
        getFilterDate() {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const yesterday = new Date()
            yesterday.setDate(today.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0)
            const twoDays = new Date()
            twoDays.setDate(today.getDate() - 2)
            twoDays.setHours(0, 0, 0, 0)
            const threeDays = new Date()
            threeDays.setDate(today.getDate() - 3)
            threeDays.setHours(0, 0, 0, 0)
            const fourDays = new Date()
            fourDays.setDate(today.getDate() - 4)
            fourDays.setHours(0, 0, 0, 0)
            const fiveDays = new Date()
            fiveDays.setDate(today.getDate() - 5)
            fiveDays.setHours(0, 0, 0, 0)
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
            lastWeek.setHours(0, 0, 0, 0);
            const lastMonth = new Date()
            lastMonth.setDate(today.getMonth() - 1)
            lastMonth.setHours(0, 0, 0, 0);
            const currentYear = new Date()
            currentYear.getFullYear()
            let tempList = [{
                time: "today",
                content: []
            },
            {
                time: 'yesterday',
                content: []
            },
            {
                time: "2 days ago",
                content: []
            },
            {
                time: "3 days ago",
                content: []
            },
            {
                time: "4 days ago",
                content: []
            },
            {
                time: "5 days ago",
                content: []
            },
            {
                time: "lastWeek",
                content: []
            },
            {
                time: "lastMonth",
                content: []
            },
            {
                time: "noTime",
                content: []
            }
            ]
            this.showSelectedSessions.map((item) => {
                tempList.map((content) => {
                    let tempTime = new Date(item.chat_time)
                    if (tempTime >= today) {
                        item.latest_time = "Today"
                        if (content.time === "today") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= yesterday) {
                        item.last_time = "Yesterday"
                        if (content.time === "yesterday") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= twoDays) {
                        item.last_time = "2 Days Ago"
                        if (content.time === "2 days ago") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= threeDays) {
                        item.last_time = "3 Days Ago"
                        if (content.time === "3 days ago") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= fourDays) {
                        item.last_time = "4 Days Ago"
                        if (content.time === "4 days ago") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= fiveDays) {
                        item.last_time = "5 Days Ago"
                        if (content.time === "5 days ago") {
                            content.content.push(item)
                        }
                    } else if (tempTime >= lastWeek) {
                        item.last_time = "Last Week"
                        if (content.time === "lastWeek") {
                            content.content.push(item)
                        }
                    } else if (item.chat_time >= lastMonth) {
                        item.last_time = "Last Month"
                        if (content.time === "lastMonth") {
                            content.content.push(item)
                        }
                    }
                })
            })
        
            this.showSelectedSessions = tempList
            
            console.log("lates time", tempList)
        },
        handleNodeClick(data) {
            console.log(data);
        },
        checkHistoryMessage() {
            if (this.isProjectHistory) {
                this.postedMessages.map((item) => {
                    this.allPostedMessages.push(item)
                })
                this.allPostedMessages.push((item) => {
                    item.role
                })
            }
        },
        initPieChart(first_elements,seriesData){
            this.overviewPieChart[this.postedMessages.length] = {
  title: {
    left:70,
    text: "API",
    textStyle: {
      color: '#1F213E'
    },
    // subtext: `API`,
    // textAlign: "left"

  },
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  legend: {
    orient: "vertical",
    itemGap: 20,
    right: "0",
    data: first_elements, 
    textStyle: {
      color: "#1F213E"
    }
  },
  series: [
    {
      name: "API",
      type: "pie",
      radius: "55%",
      center: ["50%", "45%"],
      data: seriesData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "#000000",
        },
      },
    },
  ],
};          
            
            
        
        },
        async fetchingDataMessages(project_id,polling){
            try{
                let res_messages = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?user_id=${this.userId.system_id}&app_id=${project_id}&session_name=${this.sessionId.toString()}`)
            let message = JSON.parse(res_messages.data.data[0])
            if(message[message.length - 1].role == "assistant"){
                console.log(message[message.length - 1].content.toString())
                clearInterval(polling)
                this.postedMessages.push({
                        role: "assistant",
                        content: message[message.length - 1].content.toString(),
                    })
                    this.loadingMessage = false
                    this.scrollToBottom()

            }
            }catch(error){
                console.log("Error in long polling",error)
            }
            
        },

        async getAnswer() {
            this.showVideo = false
            let titleChat = ''
            this.loadingMessage = true
            var sessionId = null
            let project_id = null
            var type_of_message = 'text'
            Array.from(this.resultProject).forEach((item) => {
                if (item.name === this.selectedProject)
                {
                    project_id = item.projectId
                    this.projectIdForConvert = project_id
                }
                

                    
            })
            this.checkHistoryMessage()
            const dataSending = {
                "chat_version": this.selectedVersion,
                "project_id": project_id,
                "messages": this.postedMessages,
                "user_id": this.userId.system_id,
                "session_name": this.sessionId.toString(),
            }
            
            axios.defaults.validateStatus = (status) => {
                return (status === 400) || (status >= 200 && status < 500);
            }
            
            try {
                let res = ''
                // res = await axios.get(
                // process.env.VUE_APP_API_URL_PREFIX +
                // `/pageviews/appmap?project_id=${project_id}&date_from=2023-09-01`
                // );
                // if(res.data.appmaps != undefined){
                //     this.appmap_id = res.data.appmaps[0]['appmap_id']
                //     dataSending['appmap_id'] = this.appmap_id
                // }
                const json = JSON.stringify(dataSending)
                if(this.chat_version == "WringChat"){
                     res = await axios
                    .post(process.env.VUE_APP_API_URL_PREFIX + "/rl/v1/appmap_chat", json, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    console.log("RES")
                    console.log(res)
                    
                    var text_string = JSON.parse(res.data.response.content).text

                    if(text_string.includes("<Kr>")){
                    text_string = text_string.replace(/bobr/g, "'")
                    }

                    type_of_message = JSON.parse(res.data.response.content).representation_type

                    if(type_of_message == "text_video"){
                        sessionId = JSON.parse(res.data.response.content).data_from_text[0]
                        
                    }else if(type_of_message == "text_and_button"){
                        this.apiCallsForConvert[this.postedMessages.length] = JSON.parse(res.data.response.content).data_from_text



                    }else if(type_of_message == "circlechart"){
                        
                    var data = JSON.parse(res.data.response.content).data_from_text
                    
                        
                    var first_elements = [];
                    var value_elements = [];

                    data.forEach(subArray => {
                    if (subArray.length >= 2) { 
                    first_elements.push(subArray[0]); 
                    value_elements.push(subArray[1]); 
                    }
                    });

                    const seriesData = first_elements.map((element, index) => {
                    return {
                        value: value_elements[index], 
                        name: element 
                    };
                    });

                    first_elements = first_elements.map(element =>{
                        return element.toString()
                    })
                    this.initPieChart(first_elements,seriesData)
                    }
                    
                }else if(this.chat_version == "WringGPT"){
                    res = await axios.post(`/interceptor/pageviews/mixtralchat/v2?engine=openai`,dataSending)
                    // var text_string = res.data.response.answer 
                    // titleChat = 'chat'
                    // if(this.postedMessages.length <= 1){
                    //     var dataSendingTitle = dataSending
                    //     dataSendingTitle['type'] = "title"
                    //     res = await axios.post(`/interceptor/pageviews/mixtralchat/v2`,dataSendingTitle)
                    //     titleChat = res.data.response.title
                    // }

                    //long polling for getting answer from a worker
                    let polling = ''
                    polling = setInterval(() => {
                        this.fetchingDataMessages(project_id,polling);
                        }, 2000);
                    
                    
                    
                }
                else{
                     res = await axios.post(`/interceptor/pageviews/gptchat/v2`,dataSending)
                    var text_string = res.data.response.answer
                }
                
                if (res.data?.response !== undefined) {
                    
                    
                    // this.loadingMessage = false
                    this.postedMessages.push({
                        role: "assistant",
                        content: text_string,
                    })
                    
                    this.resultMessages.push(
                        {
                            role: "assistant",
                            type: "text",
                            content: text_string,
                            project_id: project_id,
                            project_name: `${this.selectedProject}`,
                            version: this.chat_version,
                            title : titleChat
                        }
                        
                    )
                } else {
                    // this.loadingMessage = false
                    // this.postedMessages.push({
                    //     role: "assistant",
                    //     content: "The server is too busy to answer your question now, it will answer it in 5 minutes"
                    // })
                    // this.resultMessages.push(
                    //     {
                    //         role: "assistant",
                    //         type: "text",
                    //         content: "The server is too busy to answer your question now, it will answer it in 5 minutes",
                    //         project_id: project_id,
                    //         project_name: `${this.selectedProject}`,
                    //         version: this.selectedVersion,
                    //     }
                    // )
                }
            } catch (e) {
            console.log(e)
                // this.loadingMessage = false
                // this.postedMessages.push({
                //     role: "assistant",
                //     content: "The server is too busy to answer your question now, it will answer it in 5 minutes"
                // })
                // this.resultMessages.push(
                //     {
                //         role: "assistant",
                //         type: "text",
                //         content: "The server is too busy to answer your question now, it will answer it in 5 minutes",
                //         project_id: project_id,
                //         project_name: `${this.selectedProject}`,
                //         version: this.selectedVersion,
                //     }
                // )
            } finally {
                if(sessionId != null){
                    this.loadingVideo = true
                }
                
            }
            await this.postHistorySession(project_id)
            await this.scrollToBottom()


                if(type_of_message == "text_video" && sessionId != null){
                var id_message = this.postedMessages.length -1 
                this.$nextTick(()=>{
                    var pieChart = document.getElementById(`chart_${id_message}`)
                    pieChart.remove()
                })
                await this.putVideoInAnswer(sessionId,project_id,id_message)
                }
                else if(type_of_message == "text_and_button"){
                    var id_message = this.postedMessages.length -1
                    this.$nextTick(()=>{
                    var pieChart = document.getElementById(`chart_${id_message}`)
                    pieChart.remove()
                    console.log(`convert_button_${id_message}`)
                    var convertButton = document.getElementById(`convert_button_${id_message}`)
                    convertButton.style.display = 'block'
                    })
                    
                }
                // else if(type_of_message == "text"){
                //     this.$nextTick(()=>{
                //         var options = document.getElementById(`options-message_${this.postedMessages.length - 1}`)
                //         options.remove()
                //     })
                // }
                
            
           
            
                    
            
           
           
            
            
        },
        
        async postHistorySession(project_id) {
            let historyMessages = JSON.stringify(this.resultMessages)
            console.log("historyMessage", this.resultMessages)
            const dataSending = {
                "user_id": this.userId.system_id,
                "app_id": project_id,
                "session_name": this.sessionId.toString(),
                "message": historyMessages
            }
            const json = JSON.stringify(dataSending)
            const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages?message=${historyMessages}&user_id=${this.userId.system_id}&app_id=${project_id}&session_name=${this.sessionId.toString()}`;
            try {
                axios.defaults.validateStatus = (status) => {
                    // return status >= 200 && status < 401; // to catch 400 error
                    return status === 400 || (status >= 200 && status < 500);
                };
                const res = await axios.post(`${process.env.VUE_APP_API_URL_PREFIX}/pageviews/all_messages`, json, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (res.data.status == "success") {
                    this.updateSessions = true
                    if(this.postedMessages.length <= 2)
                        await this.getSessionsChat()
                } else {
                    var options = document.getElementById(`options-message_${this.postedMessages.length}`)
                        options.remove()
                }
            } catch (error) {
                console.log("error", error)
            }
        }
    },
}
</script>

<style lang="scss">
@import "../../../assets/sass/custom/_variablesCustom";

.left-chat--side {
    width: 20%;
    height: 100%;
    transition: 0.3s;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

// .left-chat--side:hover {
//     box-shadow: $card-shadow;
// }

.cstm-container {
    height: 84%;
}
.page-name-chat{
    color: var(--main-blue-color);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}
.select-app--history-custom input::placeholder{
    color: var(--main-blue-color) !important;
    
}
.select-app--history-custom input{
    max-width: 320px !important;
    width: 100% !important;
}

.chat-history {
    overflow: hidden;
    background-color: #E6E6E6;
    width: 100%;
    height: 100%;
    border-left: 1px solid #C7C7C7;
}

.custom-tree {
    background-color: #E6E6E6;
    color: white;
}
.message-input{
    margin-left: 10px;
}
.btns-of-choose{
    display: flex;
    gap:40px;
}
.el-tree-node__label {
    font-size: 20px;
}
.choose-chat-version-cont{
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.choose-chat-version-cont span{
    color: #6E6E6E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.el-tree-node:focus>.el-tree-node__content {
    background-color: #320067;
}

.el-tree-node__content:hover {
    background-color: #320067;
}

.hstr {
    color: var(--main-blue-color);
    font-size: 1rem;
    font-weight: 700;
}

.project--name {
    color: var(--main-blue-color);
    cursor: pointer;
}

.container-chat {
    padding-top: 0 !important;
    width: 100%;
    height: 100vh;
    display: flex;
}
.el-card.is-hover-shadow:hover{
    box-shadow:0 2px 12px 0 #E6E6E6
}
.messages-history {
    overflow-x: hidden;
    height: 75%;
    overflow-y: scroll;
}

.messages-history::-webkit-scrollbar-track-piece{
    background-color: #E6E6E6 !important;
}


.right-chat--side {
    padding-left: 20px;
    padding-right: 10px;
    width: 80%;
    height: 100%;
    overflow-y: hidden;
}

.el-tree-node__content {
    border-radius: 15px;
}

.history-header {
    padding-top: 15px;
    display: flex;
    align-items: center;
}

.project--name {
    font-weight: 300;
}

.project--time {
    text-align: left;
    color: var(--main-blue-color);
    font-size: 14px;
}

.btn-new--chat {
    background: inherit;
    border: none;
    width: 100%;
    border-radius: 10px;
    padding: 4px 8px;
    margin-top: 10px;

    span {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .img-edit {
            filter: invert(1);
        }

        .new--chat {
            display: flex;
            align-items: center;

            h2 {
                margin-bottom: 0px;
            }
        }
    }
}

.btn-new--chat:hover {
    // background: #E6E6E6;
    background-color: #E6E6E6;
    border: none;
}

.btn-new--chat:focus {
    background: #E6E6E6 !important;
    border: none !important;
}

@media (max-width: 535px) {
    .btn-new--chat {
        .new--chat {
            display: none !important;
        }
    }

    .select--chat {
        background-color: rgb(168, 166, 166) !important;
    }

    .select--app {
        background-color: rgb(168, 166, 166) !important;
    }
}

.is-overview-messages::after {
    content: '';
    position: absolute;
    bottom: 21.5%;
    left: 20%;
    width: 100%;
    height: 40px;
    // background: -webkit-gradient(linear, left top, right top, from(transparent), to(#222));
    background: linear-gradient(180deg, transparent, #e5e5e5);
    pointer-events: none;
}

// .messages-history::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 40px;
//     height: 100%;
//     background: linear-gradient(90deg, transparent, #222);
//     pointer-events: none;
// }


.message-place {
    margin: 0 auto;
    display: flex;
    position: relative;
    // margin-top: 55%;
}


.last--message {
    color: white;
}

.message-input {
    height: 60px !important;

    input {
        border-radius: 15px;
    }
}
.message-input input{
    padding-right: 50px;
    padding-left: 10px;
}
.message-input input::placeholder{
    opacity: 0.5;
}
.btn-send--message:disabled{
    background-color: var(--input-default-bg);
    border-color: var(--input-default-bg);
}
.btn-send--message {
    background-color: var(--input-default-bg);
    max-width: 40px;
    margin-left: 5px;
    right: 0;
    top: 6px;
    position: absolute;
    border-radius: 10px;
    background-color: inherit;
    border: none;
    span{
        background-color: var(--input-default-bg);
    }
    img {
        margin-right: 50%;
        transition: 0.3s;
    }

    img:hover {
        filter: brightness(1.5);
    }
}
.chat-header{
    padding-left: 20px;
}
.message-input input{
    width: 100% !important;
}
.hello-text{
    margin-top: 15px;
}
.btn-send--message:hover {
    background-color: inherit;
}

.btn-send--message:focus {
    background-color: inherit;
}

.container-project--history {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px 0 5px;
    display: flex;
    gap:5px;
    height: 50px;
    justify-content: space-between;
    transition: 0.6s;
    align-items: center;

    h2 {
        white-space: nowrap;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 30px;
    }
}

.history-messages-name{
    overflow-y: scroll;
    height: 80%;
    transition: 0.3s;
}
.history-messages-name::-webkit-scrollbar-track-piece{
    background-color: #E6E6E6;
}

.history-messages-name::-webkit-scrollbar-thumb{
    width: 1px !important;
    height: 10px !important;
    background-color: #CCC !important;
}
.chat-header{
    width: 100%;
}

.history--del {
    display: none;
    position: relative;
    background-color: inherit;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
}

.cstm-btn--del {
    position: absolute;
    top: 0;
    right: 12%;
}

// .container-project--history:hover {
//     background-color: $hover-text-color-indigo;
//     border-radius: 20px;

//     .history--del {
//         display: block;
//         border: none;
//     }
// }

.history--del:hover {
    background-color: inherit;
    border: none;
}

.history--del {
    span:hover {
        border: none;
    }
}

.cstm-btns {
    background-color: $bg-btn-indigo;
    border: none;

    li {
        color: white;
    }

    li:hover {
        background-color: $hover-text-color-indigo !important;
    }
}

.cstm--alert {
    width: 30%;
}

.avatar--message {
    height: 30px;
    width: 30px;
}

.header--message {
    display: flex;

    img {
        margin-right: 10px;
    }
}
.cstm-el--col{
    width: 90%;
}
.container--greeting {
    display: grid;
    justify-content: center;
    justify-items: center;
    margin-top: 150px;
}
.el-message-box__btns{
    display: flex;
    gap: 5px;
}
.custom-messagebox{
    width: 300px;
}

.cstm-el--col {
    margin-top: 10px;
    float: none;

    div {
        position: relative;
        // padding: 0.55rem 0.75rem;
        border-radius: 10px;
    }
}
.container--message{
    margin-left: 10%;
    margin-bottom: 15px;
}
.cstm-el--col .el-card{
    border:1px solid #E6E6E6;
}
.cstm-el--col .el-card__body{
    background-color: #E6E6E6;
}

.body--message {
    position: relative;
    padding: 0.75rem 1rem;
    word-break: break-word;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.select--chat {
    margin-left: 10px;
    width: 13.5% !important;
    border-radius: 20px;
    transition: 0.3s;

    div {
        input {
            background-color: inherit;
            border: none;
            font-size: 20px !important;
            color: black !important;
            font-weight: 600;
        }

        input::placeholder {
            color: black !important;
        }

        input:focus {
            border: none !important;
        }
    }
}

.select--chat:hover {
    background-color: rgb(168, 166, 166);
}

.select-app--history {
    width: 100% !important;
    transition: 0.3s;

    div {
        input {
            background-color: inherit;
            border: none;
            font-size: 20px !important;
            color: var(--main-blue-color) !important;
            font-weight: 600;
        }

        input::placeholder {
            color: var(--main-blue-color) !important;
        }

        input:focus {
            border: none !important;
        }
    }
}

.selecters-chat>.el-select {
    width: 10%;
}

.select--app {
    width: 35%;
    border-radius: 20px;
    transition: 0.3s;

    div {
        input {
            background-color: inherit;
            border: none;
            font-size: 20px !important;
            color: var(--main-blue-color) !important;
            font-weight: 600;
            width: 110% !important;
        }

        input::placeholder {
            color: var(--main-blue-color) !important;
        }

        input:focus {
            border: none !important;
        }
    }
}

@media (max-width: 1465px) {
    .select--app {
        div {
            input {
                font-size: 19px !important;
            }
        }
    }

    .select--chat {
        div {
            input {
                font-size: 19px !important;
            }
        }
    }
}

@media (max-width: 1420px) {
    .select--app {
        div {
            input {
                font-size: 17.5px !important;
            }
        }
    }

    .select--chat {
        div {
            input {
                font-size: 17.5px !important;
            }
        }
    }
}

@media (max-width: 1350px) {
    .select--app {
        div {
            input {
                font-size: 15.5px !important;
            }
        }
    }

    .select--chat {
        div {
            input {
                font-size: 15.5px !important;
            }
        }
    }
}

@media (max-width: 1180px) {
    .select--app {
        div {
            input {
                font-size: 13.5px !important;
            }
        }
    }

    .select--chat {
        div {
            input {
                font-size: 13.5px !important;
            }
        }
    }

    .new--chat {
        img {
            width: 30px;
        }

        .hstr {
            font-size: 0.75rem;
        }
    }

    .img-edit {
        width: 15px;
    }

    .select-app--history {
        div {
            input {
                font-size: 13.5px !important;
            }
        }
    }
}

// .select-app--history:hover {
//     background-color: $hover-text-color-indigo;
// }


// .select--app:hover {
//     background-color: rgb(168, 166, 166);
// }

.selecters-chat {
    z-index: 1;
    margin-top: 75px;
    display: flex;
    width: 100%;
    margin-right: 20px;
    justify-content: end;
}

.btns-option-message{
    display: flex;
    gap: 5px;
}

.cstm--option {
    height: 70px;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
}

.select-label {
    float: left;
}

.select-decs {
    float: left;
    color: #8492a6;
    font-size: 13px;
}

.name-chat {
    margin-left: 20px;
}
.video-chat .rr-player{
    background-color:#E6E6E6 ;
    box-shadow: 0 24px 48px #E6E6E6 !important;
    border-color: #E6E6E6;
    margin-right: 100px;
}


.typing-indicator {
    opacity: 1;
    width: auto;
    border-radius: 50px;
    padding: 20px;
    display: table;
    margin: 0 auto;
    position: absolute;
    bottom: 15%;
    z-index: 999;
    left: 40%;
    -webkit-animation: 2s bulge infinite ease-out;
    animation: 2s bulge infinite ease-out;
}

// .typing-indicator:before,
// .typing-indicator:after {
//     content: '';
//     position: absolute;
//     bottom: -2px;
//     left: -2px;
//     height: 20px;
//     width: 20px;
//     border-radius: 50%;
//     background-color: #E6E7ED;
// }

// .typing-indicator:after {
//     height: 10px;
//     width: 10px;
//     left: -10px;
//     bottom: -10px;
// }

.typing-indicator span {
    height: 15px;
    width: 15px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
    animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
    animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
    animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes bulge {
    50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

@keyframes bulge {
    50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}
</style>
<style scoped>
.trash-icon{
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}
.container-project--history:hover .trash-icon{
    opacity: 1;
}
</style>