<template>
  <div class="simItemBB" :class="{crossed: mode == 'similar-disabled', hoverable: mode != 'main'}" :style="cssVars" @click="$emit('click',$event)">
  </div>
</template>

<script>

const ItemColors = {
  MAIN: "#6C00FF",
  SIMILAR: "#1F8A70",
  SIMILAR_DISABLED: "#FC7300",
  HOVER: "#BFDB38"
}

export default {
  name: 'sim-item',
  props: {
    rect: Object,
    canvasDim: Object,
    originalDim: {
      type: Object,
      default: () => ({
        width:1919, 
        height:1006,
        yOffset:0
      }) 
    },
    mode: {
      type: String,
      validator: (value) => ['main', 'similar', 'similar-disabled'].includes(value),
      default: () => "similar"      
    }
  },
  computed: {
    cssVars(){
      let newX = this.rect.x / this.originalDim.width * this.canvasDim.width;
      let newY = (this.rect.y - this.originalDim.yOffset) / this.originalDim.height * this.canvasDim.height;
      let newWidth = this.rect.width / this.originalDim.width * this.canvasDim.width;
      let newHeight = this.rect.height / this.originalDim.height * this.canvasDim.height;
      let borderWidth = 4 / this.originalDim.width * this.canvasDim.width;

      let display = "inherit";
      if (newX > this.canvasDim.width || newY > this.canvasDim.height ){
        display = "none";
      }

      if (newX + newWidth > this.canvasDim.width){        
        newWidth = this.canvasDim.width - newX
      }
      if (newY + newHeight> this.canvasDim.height ){        
        newHeight = this.canvasDim.height - newY
      }

      let color = ItemColors.SIMILAR
      if (this.mode == "main") {
        color = ItemColors.MAIN
      }
      else if (this.mode == "similar-disabled") {
        color = ItemColors.SIMILAR_DISABLED
      }

      let diagAngle = Math.atan(newWidth / newHeight) * 180 / Math.PI

      return {
          '--x':`${newX}px`,
          '--y': `${newY}px`,
          '--width':`${newWidth}px`,
          '--height':`${newHeight}px`,
          '--color': color,
          '--hover-color': ItemColors.HOVER,
          '--border': `${borderWidth}px`,
          '--diag-angle': `${diagAngle}deg`,
          'display': display
      }
    }
  }

}
</script>
<style>
  .simItemBB {
    border-width: var(--border);
    border-style: solid;
    border-color: var(--color);
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: var(--width);
    height: var(--height);
    z-index: 3;    
  }
  .simItemBB.hoverable:hover {
    border-color: var(--hover-color);
  }

  .simItemBB.crossed:before, .simItemBB.crossed:after{
    position: absolute;
    content: '';
    background: var(--color);
    display: block;
    width: var(--border);
    height: var(--width);
    -webkit-transform: calc(var(--diag-angle) * -1);
    transform: rotate(calc(var(--diag-angle) * -1));    
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.simItemBB.crossed:after {
    -webkit-transform: rotate(var(--diag-angle));    
    transform: rotate(var(--diag-angle));
}

</style>
